<template>
  <div class="widget">
    <div class="weather">
      <a class="weatherwidget-io" href="https://forecast7.com/ja/35d71139d73/tokyo/?unit=us" data-label_1="TOKYO" data-label_2="WEATHER" data-font="ヒラギノ角ゴ Pro W3" data-icons="Climacons Animated" data-mode="Forecast" data-days="5" data-theme="sky" >TOKYO WEATHER</a>
    </div>
    <div class="side-menu">
      <h4>ひろば</h4>
      <h5>▼ 広場</h5>
      <p v-for="(hashtag, index) in square && square.hot_hashtags" :key="index">
        <a href="#" @click.prevent="toSquare(hashtag.hashtag_id)">{{hashtag.hashtag_name}}</a><span v-show="hashtag.count">{{hashtag.count}}</span>
      </p>
    </div>
    <div class="side-menu">
      <h4>サークル</h4>
      <h5>▼ 最近更新</h5>
      <p v-for="(circle, index) in circle && circle.hot_circles" :key="index">
        <a href="#" @click.prevent="toCircleTimeline(circle.circle_id)">{{circle.circle_name}}</a>
        <span v-show="circle.count">{{circle.count}}</span>
      </p>
      <h5><router-link :to = "{ name:'side/circle', params:{ circlesDivisionId: 1} }">▶ すべて</router-link><span v-if="circle && circle.all_count" v-text="circle && circle.all_count" /></h5>
      <h5><router-link :to = "{ name:'side/circle', params:{ circlesDivisionId: 2} }">▶ 非公開サークル</router-link><span v-if="circle && circle.private_count" v-text="circle && circle.private_count" /></h5>
      <h5 v-if="account != null "><router-link :to = "{ name:'side/circle', params:{ circlesDivisionId: 3} }">▶ あなたが管理人</router-link><span v-if="circle && circle.admin_count" v-text="circle && circle.admin_count" /></h5>
    </div>
    <div class="side-menu" v-if="account">
      <h4>マイスペース</h4>
      <h5><router-link :to = "{ name:'side/myspace', params:{ accountId: account.id, myspaceDivisionId: 2} }">▶ 自分の発言</router-link></h5>
      <h5><router-link :to = "{ name:'side/myspace', params:{ accountId: account.id, myspaceDivisionId: 3} }">▶ あなたへの返信</router-link></h5>
      <h5><router-link :to = "{ name:'side/myspace', params:{ accountId: account.id, myspaceDivisionId: 4} }">▶ しるし</router-link></h5>
    </div>
    <div class="side-menu" v-if="account">
      <h4>サイドバーに固定</h4>
      <h5 v-for="(circle, index) in referCircles" :key="index">
        <router-link :to = "{ name:'circle/time-line', params:{ circleMode: 1, circleId: circle.id} }">▶ {{circle.name}}</router-link>
      </h5>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
import entries from '../../../js/api/entries'

!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');

export default {
  data () {
    return {
      square : null,
      circle : null,
      myspace: null,
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
      'referCircles',
    ]),
  },
  watch : {
    account: function (val) {
      this.getSideMenuInfo(val)
    },
  },
  mounted : function () {
    this.getSideMenuInfo(this.account)
  },
  methods : {
    ...mapMutations([
      'setCircleMode',
      'setCircleHashtagDivisionId',
      'setCircleHashtagId',
      'setHashtagId',
      'setFollowerCount',
      'setClientErrors'
    ]),
    getSideMenuInfo: function(account) {
      let ret = entries.sideMenu(account)
      ret.then (res => {
        if (res.data.status == this.Global.api_status.success) {
          this.square = res.data.side_menus.square
          this.circle = res.data.side_menus.circle
          this.myspace = res.data.side_menus.myspace
        }
      })
      .catch (error => { console.log(error) })
    },
    toSquare: function(hashtagId) {
        this.setHashtagId(hashtagId)
        this.$router.push( { path : '/' }, () => {} )
    },
    toCircleTimeline: function(circleId) {
        this.setCircleMode(this.Global.circle_mode.public)
        this.setCircleHashtagDivisionId(this.Global.hashtag_division_id.home)
        this.setCircleHashtagId(null)
        this.$router.push({ path : '/circle/time-line/1/' + circleId })
    },
  }
}
</script>