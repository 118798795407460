<template>
  <div id="settings">
    <div id="container" v-if="latestAccount != null" >
      <div class="tabs">
        <ul>
          <li @click="setTabId(1)" :class="[{ current : tabId == basic}]">基本情報</li>
          <li @click="setTabId(2)" :class="[{ current : tabId == mail}]">メール通知</li>
          <li @click="setTabId(3)" :class="[{ current : tabId == leaveOut}]">退会</li>
        </ul>
      </div>
      <!-- アカウント基本設定 -->
      <c-account-setting-basic v-show="tabId == basic" :account="latestAccount" />
      <!-- メール設定 -->
      <c-account-setting-mail v-show="tabId == mail" :account="latestAccount" />
      <!-- 退会 -->
      <c-leave-out v-show="tabId == leaveOut" />
    </div>
  </div>

</template>
<script>
import { mapState, mapMutations } from 'vuex'
import AccountBasic from './account-basic'
import AccountMail from './account-mail'
import LeaveOut from './leave-out'

export default {
  data () {
      return {
        tabId : this.Global.settings_tab_id.basic,
        latestAccount:null
      }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
    ]),
    basic : function () {
      return this.Global.settings_tab_id.basic
    },
    mail : function () {
      return this.Global.settings_tab_id.mail
    },
    leaveOut : function () {
      return this.Global.settings_tab_id.leave_out
    },
  },
  mounted:function () {
    if (!this.loginFlg) this.$router.push({ path : '/' }, () => {})
    this.getAccount()
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    setTabId : function(tabId) {
      this.tabId = tabId
    },
    getAccount : function () {
      this.axios.get(this.Global.url.api_accounts_show + this.account.id).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.latestAccount = res.data.account
          return
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"settings/index.vue::getAccount", params: {}, errorMessage:error})
      })
    }
  },
  components : {
    'c-account-setting-basic' : AccountBasic,
    'c-account-setting-mail' : AccountMail,
    'c-leave-out' : LeaveOut
  }
}
</script>