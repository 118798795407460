<template>
  <div class="notification">
    <section>
      <h2>メール通知</h2>
      <p>{{account.mail}}宛に送られる通知の設定を行えます。</p>
    </section>
    <section>
      <h4>あなた宛の返信</h4>
      <div class="group">
        <div class="item">
          <input type="radio" name="notification-me" value="1" id="allow1" v-model="replyMail">
          <label class="radio" for="allow1">受け取る</label>
        </div>
        <div class="item">
          <input type="radio" name="notification-me" value="0" id="disallow1" v-model="replyMail">
          <label class="radio" for="disallow1">受け取らない</label>
        </div>
      </div>
      <h4>「しるし」や「いいね」がついたとき</h4>
      <div class="group">
        <div class="item">
          <input type="radio" name="notification-like" value="1" id="allow2" v-model="markMail">
          <label class="radio" for="allow2">受け取る</label>
        </div>
        <div class="item">
          <input type="radio" name="notification-like" value="0" id="disallow2" v-model="markMail">
          <label class="radio" for="disallow2">受け取らない                    </label>
        </div>
      </div>
      <div class="item">
        <input class="button-blue" type="submit" value="設定を保存" @click.prevent="save()">
      </div>
    </section>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  props:{
    account:{
      type:Object,
      required:true
    }
  },
  data () {
      return {
        id:null,
        replyMail:1,
        markMail:0,
        img:null,
        lastName:null,
        firstName:null,
        nickname:null,
        mail:null,
        profile:null,
        homepage:null,
        errors:{}
      }
  },
  watch:{},
  mounted : function () {    
    this.replyMail = this.account.reply_mail == true ? 1 : 0
    this.markMail = this.account.mark_mail == true ? 1 : 0
    this.id        = this.account.id
    this.lastName  = this.account.last_name
    this.firstName = this.account.first_name
    this.nickname  = this.account.nickname
    this.mail      = this.account.mail
    this.profile   = this.account.profile
    this.homepage  = this.account.homepage
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    save : function () {
      var formData = new FormData()   
      formData.append("id", this.id)
      formData.append("last_name", this.lastName)
      formData.append("first_name", this.firstName)
      formData.append("nickname", this.nickname)
      formData.append("mail", this.mail)
      formData.append("profile", this.profile)
      formData.append("homepage", this.homepage)
      formData.append("reply_mail", this.replyMail)
      formData.append("mark_mail", this.markMail)

      this.axios.post(this.Global.url.api_accounts_update, formData)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$router.go({path: this.$router.currentRoute.path, force: true})
          this.$toasted.info("completed")
          return
        } 
        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        } 
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"settings/accout-mail.vue::save", params: formData, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"settings/accout-mail.vue::save", params: formData, errorMessage:error})
      })

    },
  }
}
</script>