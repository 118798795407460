// サーバーからのメッセージキーを画面側のメッセージに変換する
var labels = {
    // validation error 系エラー => xxxxx_CONSTRAINT_FAILED
	'last_name' : '姓',
    'first_name' : '名',
    'nickname' : 'ニックネーム',
    'mail' : 'メールアドレス',
    'account_id' : 'アカウントID',
    'subject' : '件名',
    'text' : 'メール本文',
    'id' : 'ID',
    'founder_id' : 'サークル作成者',
    'circle_id' : 'サークルID',
    'circle_name' : 'サークル名',
    'activities' : '活動内容',
    'publish' : '公開・非公開',
    'circle_mail' : 'メール',
    'name' : '名称',
    'password' : 'パスワード',
    'mst_mark_id' : 'しるしID'

}

export default labels