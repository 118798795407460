<template>
  <div class="basic">
    <section>
      <h2>基本情報</h2>
      <p>プロフィールなどの基本設定を行えます。<br>写真は正方形のもので700KB以下を使用可能<br>(推奨サイズ:160px x 160px)</p>
    </section>
    <section class="detail">
      <c-validation-errors :errors-hash="errors" />
      <div class="upload">
        <input type="file" :model="img" @change="attachImg">
        
        <c-account-image v-if="accountImageDisplay" :has-img = "account.has_img" :account-id = "account.id" :account-name = "account.last_name + account.first_name" />
        <img  v-show="!accountImageDisplay" :src="resizedImg" class="resize-img__preview__img">
      </div>
      <div class="group">
        <div class="item">
          <input type="text" placeholder="姓" v-model="lastName" @change="changeNameAndMail"><span class="focusborder"></span>
        </div>
        <div class="item">
          <input type="text" placeholder="名" v-model="firstName" @change="changeNameAndMail"><span class="focusborder"></span>
        </div>
      </div>
      <div class="item">
        <input type="text" placeholder="ニックネーム" v-model="nickname"><span class="focusborder"></span>
      </div>
      <div class="item">
        <input type="text" placeholder="メールアドレス" v-model="mail" @change="changeNameAndMail"><span class="focusborder"></span>
      </div>
      <div class="item">
        <input type="password" placeholder="新しいパスワード" v-model="password"><span class="focusborder"></span>
      </div>
      <div class="item">
        <input type="password" placeholder="確認用パスワード" v-model="confirmPassword"><span class="focusborder"></span>
      </div>
      <div class="item">
        <input type="textarea" placeholder="自己紹介" v-model="profile"><span class="focusborder"></span>
      </div>
      <div class="item">
        <input type="text" placeholder="ホームページアドレス" v-model="homepage"><span class="focusborder"></span>
      </div>
      <div class="setting-rss">
        <h4>RSS設定（ブログやnoteの更新情報を自動投稿）</h4>
        <div>
          <div class="item">
            <input type="text" placeholder="URLを入力してください" v-model="rssUrl" @change="preview(rssUrl, null)" /><span class="focusborder"></span>
          </div>              
          <div class="item">
            <input type="text" placeholder="タイトルを入力してください" v-model="rssTitle" /><span class="focusborder"></span>
          </div>
          <div class="item">
            <textarea placeholder="自動投稿時のメッセージ（例：note更新しました、など）" v-model="rssMessage"></textarea><span class="focusborder"></span>
          </div>
          <div>
            <p>公式な話題</p>
            <select v-model="publicHashtagId" @change="changePublicHashtags(null, publicHashtagId)">
              <option value="" style="display:none;">投稿時にタグ付けする話題</option>
              <option v-for="(hashtag, index) in publicHashTags" :key="index" :value="hashtag.id">{{hashtag.name}}</option>
            </select>
          </div>
          <div>
            <p>自由な話題</p>
            <select v-model="freeHashtagId" @change="changeFreeHashtags(null, freeHashtagId)">
              <option value="" style="display:none;">投稿時にタグ付けする話題</option>
              <option v-for="(hashtag, index) in freeHashTags" :key="index" :value="hashtag.id">{{hashtag.name}}</option>
            </select>
          </div>
        </div>
        <button @click="toTempRsses" v-show="rsses.length < 5">+ RSSを追加</button>
      </div>
      <div v-for="(rss, index) in rsses" :key="index"  :class="rss._deleteFlg ? 'setting-rss-delete' : 'setting-rss'">
        <h5 v-show="rss._deleteFlg">※設定を保存ボタンを押すことで反映されます</h5>
        <div>
          <div v-show="!rss._deleteFlg" @click="deletefromRsses(rss.id, rss._index)">×</div>
          <div class="item">
            <input type="text" placeholder="URLを入力してください" v-model="rss.url" :disabled="rss._deleteFlg" @change="preview(rss.url, rss._index)" /><span class="focusborder"></span>
          </div>              
          <div class="item">
            <input type="text" placeholder="タイトルを入力してください" v-model="rss.title" :disabled="rss._deleteFlg" /><span class="focusborder"></span>
          </div>
          <div class="item">
            <textarea placeholder="自動投稿時のメッセージ（例：note更新しました、など）" v-model="rss.message" :disabled="rss._deleteFlg"></textarea><span class="focusborder"></span>
          </div>
          <div>
            <p>公式な話題</p>
            <select v-model="rss.mst_topic_id" @change="changePublicHashtags(rss._index, rss.mst_topic_id)" :disabled="rss._deleteFlg">
              <option value="" style="display:none;">投稿時にタグ付けする話題</option>
              <option v-for="(hashtag, index) in publicHashTags" :key="index" :value="hashtag.id">{{hashtag.name}}</option>
            </select>
          </div>
          <div>
            <p>自由な話題</p>
            <select v-model="rss.mst_topic_id" @change="changeFreeHashtags(rss._index, rss.mst_topic_id)" :disabled="rss._deleteFlg">
              <option value="" style="display:none;">投稿時にタグ付けする話題</option>
              <option v-for="(hashtag, index) in freeHashTags" :key="index" :value="hashtag.id">{{hashtag.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="action-overlay" v-show="overlay">
        <div class="container">
          <div class="close-button-2" for="trigger-3" @click="toggleOverlay">✖️</div>
          <div class="rss">
            <p><span>{{rssTitle}}</span></p>
            <p v-for="(article, index) in articles" v-html="article" :key="index"></p>
          </div>
        </div>
      </div>
      <div class="item">
        <input class="button-blue" type="submit" value="設定を保存" @click.prevent="save()">
      </div>
    </section>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import loadImage from 'blueimp-load-image'
import base64ToBlob from 'b64-to-blob'
import _ from 'lodash'

export default {
  props:{
    account:{
      type:Object,
      required:true
    }
  },
  data () {
      return {
        id:null,
        img:null,
        resizedImg:null,
        lastName:null,
        firstName:null,
        nickname:null,
        mail:null,
        password:null,
        confirmPassword:null,
        profile:null,
        homepage:null,
        accountImageDisplay:false,
        rssUrl: null,
        rssTitle: null,
        rssMessage: null,
        freeHashtagId: null,
        publicHashtagId: null,
        hashtagId: null,
        hashtags: null,
        rsses: [],
        overlay: false,
        articles: null,
        errors:{}
      }
  },
  computed: {
    freeHashTags : function () {
      return _.filter(this.hashtags, r => r.circle_id === null && r.official === false)
    },
    publicHashTags: function () {
      return _.filter(this.hashtags, r => r.circle_id === null && r.official === true)
    },
  },
  created: function () {
    this.getHashtags()
  },
  mounted : function () {
    this.id = this.account.id
    this.lastName = this.account.last_name
    this.firstName = this.account.first_name
    this.nickname = this.account.nickname
    this.mail = this.account.mail
    this.profile = this.account.profile
    this.homepage = this.account.homepage
    this.accountImageDisplay = this.account.has_img

    this.getAccountRss()
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    getHashtags : function (divisionId) {
      this.axios.post(this.Global.url.api_mst_topics_search, {})
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.hashtags = res.data.hashtags
          return
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"admin/hashtag/index.vue::get", params: {division_id:divisionId}, errorMessage:error})
      })
    },
    getAccountRss: function() {
      this.axios.post(this.Global.url.api_account_rsss_search, {
        account_id: this.account.id
      })
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          // console.log("getAccountRss res")
          // console.dir(res.data.account_rsss)
          let i = 0
          this.rsses = _.forEach(_.reverse(res.data.account_rsss), row => {
            row._defaultUrl = row.url
            row._publicHashtagId = row.mst_topic_official == true ? row.mst_topic_id : null
            row._freeHashtagId = row.mst_topic_official == false ? row.mst_topic_id : null
            row._index = i
            row._invalidUrlFlg = false
            row._deleteFlg = false
            i++
          })
          return
        }
      })
    },
    changePublicHashtags: function(index, hashtagId) {
      if (index != null) {
        this.rsses = _.map(this.rsses, (rss) => {
          if (rss._index == index) {
            rss.mst_topic_id = hashtagId
            rss._publicHashtagId = hashtagId
            rss._freeHashtagId = null
          }
          return rss
        })
        return
      }

      this.freeHashtagId = null
      this.hashtagId = hashtagId
    },
    changeFreeHashtags: function(index, hashtagId) {
      if (index != null) {
        this.rsses = _.map(this.rsses, (rss) => {
          if (rss._index == index) {
            rss.mst_topic_id = hashtagId
            rss._publicHashtagId = null
            rss._freeHashtagId = hashtagId
          }
          return rss
        })
        return
      }

      this.publicHashtagId = null
      this.hashtagId = hashtagId
    },
    toTempRsses: function() {
      if (!this.rssTitle) return
      if (this.rsses.length == 5) {
        this.$toasted.error("これ以上登録できません")
        return
      }

      this.rsses.unshift({
        id : null,
        account_id: this.account.id,
        url : this.rssUrl,
        title : this.rssTitle,
        message : this.rssMessage,
        mst_topic_id : this.hashtagId,
        _defaultUrl: this.rssUrl,
        _publicHashtagId : this.publicHashtagId,
        _freeHashtagId : this.freeHashtagId,
        _index : this.rsses.length,
        _deleteFlg:false,
        _invalidUrlFlg:false,
      })

      this.rssUrl = null
      this.rssTitle = null
      this.rssMessage = null
      this.mstHashtagId = null
      this.publicHashtagId = null
      this.freeHashtagId = null
    },
    deletefromRsses: function (id, index) {

      if (id === null) {
        this.rsses.splice(index, 1)
        return
      }
      if (!confirm("削除します。よろしいですか？")) {
        return
      }
      let rsses = _.map(this.rsses, elm => {
        // if (id == null) return
        if (elm.id === id) {
          elm._deleteFlg = true
        }
        return elm
      })

      this.rsses = rsses
    },
    preview : function (url, index) {
      if (!url) return
      if (_.filter(this.rsses, row => (index == null || row._index != index) && row.url === url).length > 0) {
        this.$toasted.error("既に登録されてるURLです")
        if (index == null) {
          this.rssUrl = null
          return
        }
        _.forEach(this.rsses, row => {
          if (row._index == index) {
            row.url = row._defaultUrl
          }
        })
        return
      }
      let params = { url : this.rssUrl }
      this.axios.post(this.Global.url.api_rss_preview, params)
      .then( res => {
        if (res.data.status === this.Global.api_status.success) {
          if (res.data.articles.length > 0) {
            this.articles = _.slice(res.data.articles, 0, 1)
            this.toggleOverlay()
          }
          if (index != null) {
            // invalidUrlFlgがtrueの場合は登録しない
            _.forEach(this.rsses, row => {
              if (row._index == index) {
                row._invalidUrlFlg = res.data.articles.length > 0 ? false : true
              }
            })
          }
          return
        }

        if (index == null) {
          this.$toasted.error("urlは正しいですか？？")
          this.rssUrl = null
        }

        if (index != null) {
          this.$toasted.error("urlは正しいですか？？urlが不正のまま保存すると削除されます。")
          // invalidUrlFlgがtrueの場合は登録しない
          _.forEach(this.rsses, row => {
            if (row._index == index) {
              row._invalidUrlFlg = true
            }
          })
        }

      })
      .catch( error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::preview", params: params, errorMessage:error})
      })
    },
    save : function () {
      if (!this.checkPassword()) {
        this.$toasted.info("パスワード、確認用パスワードが一致しません")
        return
      }

      var formData = new FormData()   
      formData.append("id", this.id)
      if (this.img != null) formData.append("img", this.img)
      if (this.lastName) formData.append("last_name", this.lastName)
      if (this.firstName) formData.append("first_name", this.firstName)
      if (this.nickname) formData.append("nickname", this.nickname)
      if (this.mail) formData.append("mail", this.mail)
      if (this.password) formData.append("password", this.password)
      formData.append("profile", this.profile || '')
      formData.append("homepage", this.homepage || '')

      this.axios.post(this.Global.url.api_accounts_update, formData)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.saveRss()
          return
        } 
        if (res.data.status == this.Global.api_status.validation_error) {
          this.errors = res.data.errors
          return
        } 
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"settings/accout-basic.vue::save", params: formData, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"settings/accout-basic.vue::save", params: formData, errorMessage:error})
      })
    },
    attachImg: function (e) {
      const file = e.target.files[0];
      if (!file) {
        return
      }
      loadImage.parseMetaData(file, (data) => {
        const options = {
          maxHeight: this.Global.display_image_max_size,
          maxWidth: this.Global.display_image_max_size,
          canvas: true
        }
        if (data.exif) {
          options.orientation = data.exif.get('Orientation');
        }
        this.displayImage(file, options)
      })
      const reader = new FileReader()
      reader.onload = (event) => {
        const image = new Image()
        image.crossOrigin = 'Anonymous'

        image.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const drawImageArgs = this.drawImageArgs(image)
          canvas.width = drawImageArgs[7]
          canvas.height = drawImageArgs[8]
          ctx.drawImage(...drawImageArgs)
          const base64 = canvas.toDataURL()
          this.img = base64ToBlob(base64.replace(/^.*,/, ''))
        }
        image.src = event.target.result
      }

      reader.readAsDataURL(file)
    },
    drawImageArgs (image) {
      const maxSize = this.Global.updaload_account_image_max_size
      let sx = 0
      let sy = 0
      let dx = 0
      let dy = 0

      let imageWidth = image.width
      let imageHeight = image.height

      if (imageWidth > imageHeight) {
        sx = (imageWidth - imageHeight) / 2
        imageWidth = imageHeight
      }

      if (imageHeight > imageWidth) {
        sy = (imageHeight - imageWidth) / 6
        imageHeight = imageWidth
      }

      const dstWidth = imageWidth > maxSize ? imageWidth * maxSize / imageWidth : imageWidth
      const dstHeight = imageHeight > maxSize ? imageHeight * maxSize / imageHeight: imageHeight

      return [image, sx, sy, imageWidth, imageHeight, dx, dy, dstWidth, dstHeight]
    },
    displayImage(file, options) {
      loadImage(
        file,
        async (canvas) => {
          const data = canvas.toDataURL(file.type)
          // data_url形式をblob objectに変換
          const blob = this.base64ToBlob(data, file.type)
          // objectのURLを生成
          const url = window.URL.createObjectURL(blob)
          this.resizedImg = url;
          this.accountImageDisplay=false
          // alert(this.accountImageDisplay)
        },
        options
      )
    },
    base64ToBlob(base64, fileType) {
      const bin = atob(base64.replace(/^.*,/, ''));
      const buffer = new Uint8Array(bin.length);
      for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      return new Blob([buffer.buffer], {
        type: fileType ? fileType : 'image/png'
      });
    },
    checkPassword : function () {
      if (!this.password) return true
      if (this.password != this.confirmPassword) return false
      return true
    },
    saveRss : function () {
      _.forEach(this.rsses, (row) => {
        if (row._deleteFlg || row._invalidUrlFlg) {
          if(row.id != null) this.deleteRss(row)
        } else if (row.id == null) {
          this.insertRss(row)
        } else {
          this.updateRss(row)
        }
      })
      this.rsses = _.filter(this.rsses, row => (!row._deleteFlg && !row._invalidUrlFlg))
      
      this.$toasted.info("completed")
    },
    insertRss : function (row) {
      this.axios.post( this.Global.url.api_account_rsss_create, row )
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          row.id = res.data.account_rss.id
          return
        }
        this.setClientErrors({func:"settings/account-basic.vue::insert", params: row, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"settings/account-basic.vue::insert", params: row, errorMessage:error})
      })
    },
    updateRss : function (row) {
      this.axios.post( this.Global.url.api_account_rsss_update, row )
      .then(res => {
        if (res.data.status === this.Global.api_status.success) return

        this.setClientErrors({func:"settings/account-basic.vue::update", params: row, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"settings/account-basic.vue::update", params: row, errorMessage:error})
      })
    },
    deleteRss : function (row) {
      this.axios.delete( this.Global.url.api_account_rsss_delete + "/" + row.id )
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.rsses = _.filter(this.rsss, (rss) => { return rss.id != row.id })
          return
        }
        // this.$toasted.error('client error')
        // this.setClientErrors({func:"settings/account-basic.vue::delete", params: row, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"settings/account-basic.vue::delete", params: row, errorMessage:error})
      })
    },
    toggleOverlay : function () {
      this.overlay = !this.overlay
    },
    changeNameAndMail : function () {
      if (!this.lastName || !this.firstName || !this.mail) {
        this.$toasted.info('空が存在するので存在チェックはしない')
        return
      }
      if (this.lastName != this.account.last_name || this.firstName != this.account.first_name || this.mail != this.account.mail) {
        this.existsCheck()
      }
    },
    existsCheck : function () {
      let params = {
        last_name  : this.lastName,
        first_name : this.firstName,
        mail       : this.mail
        }
      this.axios.post(this.Global.url.api_accounts_exists, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.validation_error) {
          this.errors = res.data.errors
          return
        }
        if (res.data.status == this.Global.api_status.success) {
          if (res.data.temp_account_exist || res.data.account_exist) {
            this.lastName = null
            this.firstName = null
            this.$toasted.error('入力された姓名はすでに使用されています')
            return
          }
          return
        }

        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"registration/temporary-sign-in.vue::existsCheckAndSave", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"registration/temporary-sign-in.vue::existsCheckAndSave", params: params, errorMessage:error})
      })
    },
  },
  components : {}
}
</script>