<template>
  <div class="member">
    <ul>
      <li v-for="(member, index) in representativeMembers" :key="index">
        <c-account-image :has-img = "member.has_img" :account-id = "member.account_id" :account-name = "member.account_name" v-tooltip="member.account_name"/>
      </li>
    </ul>
    <span>{{memberCount}}人のメンバー</span>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  props:{
    circleId:{
      type: Number,
      required:true
    }
  },
  data () {
      return {
        circleMembers:[]
      }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
    ]),
    representativeMembers : function () {
      return _.take(this.circleMembers, this.Global.limited_circle_members_count)
    },

    memberCount : function () {
      return this.circleMembers.length
    }
  },
  mounted: function() {
    this.getCircleMember()
  },
  methods : {
    ...mapMutations([
      'setClientErrors'
    ]),
    getCircleMember : function () {
      let params = {circle_id : this.circleId}
      this.axios.post(this.Global.url.api_circle_members_search, params)
      .then(res => {
        if ( res.data.status == this.Global.api_status.success ) {
          this.circleMembers = res.data.circle_members
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/circle_members.vue::getCircleMember", params: params, errorMessage:error})
      })
    },
  }
}
</script>