<template>
  <div id="openspace">
    <div id="container">
      <div class="wrapper">
        <aside>
          <div class="widget">
            <div class="weather"><img src="/assets/widget-1@2x.png" alt=""></div>
            <div class="weather"><img src="/assets/widget-2@2x.png" alt=""></div>
            <div class="weather"><img src="/assets/widget-3@2x.png" alt=""></div>
          </div>
        </aside>
        <main class="timeline">
          <div class="detail-search"><a href="#" @click.prevent="back">戻る</a>
            <input type="text" placeholder="キーワードを入力" v-model="keywords" readonly>
            <button><img src="/assets/icon-settings.svg" alt="詳細検索"></button>
          </div>
          <c-entry v-for="(entry, index) in keywordSearchEntries" :key="index" :entry-division-id="entry.entry_division_id" :entry="entry" :keywords="keywords" :mode="entry_display_mode" />
        </main>
        <c-cotolike-widget />
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import entry from '../common/entry'
import CotolikeWidget from '../common/cotolike-widget.vue'

export default {
  data () {
    return {
      entry_display_mode: this.Global.entry_display_mode.time_line,
    }
  },
  computed:{
    ...mapState(['keywords','keywordSearchEntries'])
  },
  mounted : function () {

    // alert(this.keywords)
  },
  methods:{
    back : function () {
      this.$router.back()
    }
  },
  components : {
    'c-entry' : entry,
    'c-cotolike-widget' : CotolikeWidget,
  }
}
</script>