<template>
  <div class="action-stamp">
    <div class="action" @mousedown="toggleMarkDialog()">
      <svg version="1.1" id="icon-stamp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" xml:space="preserve">
        <path class="icon-stamp" d="M20.7,0H5.3C2.4,0,0,2.4,0,5.3c0,0,0,0,0,0v15.3C0,23.6,2.4,26,5.3,26h15.3c2.9,0,5.3-2.4,5.3-5.3V5.3                        C26,2.4,23.6,0,20.7,0C20.7,0,20.7,0,20.7,0z M24,20.7c0,1.8-1.5,3.3-3.3,3.3c0,0,0,0,0,0H5.3C3.5,24,2,22.5,2,20.7c0,0,0,0,0,0V5.3                        C2,3.5,3.5,2,5.3,2c0,0,0,0,0,0h15.3C22.5,2,24,3.5,24,5.3c0,0,0,0,0,0V20.7z M17.9,15.4c-0.5-0.2-1.1,0-1.3,0.5                        c-1,2-3.3,2.8-5.3,1.8c-0.8-0.4-1.4-1-1.8-1.8c-0.2-0.5-0.8-0.7-1.3-0.5s-0.7,0.8-0.5,1.3c1.5,3,5,4.2,8,2.7                        c1.2-0.6,2.1-1.5,2.7-2.7C18.6,16.3,18.4,15.7,17.9,15.4L17.9,15.4z M10.3,9.7c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8                        s0.8-1.8,1.8-1.8c0,0,0,0,0,0C9.5,7.9,10.3,8.7,10.3,9.7C10.3,9.7,10.3,9.7,10.3,9.7 M19.3,9.7c0,1-0.8,1.8-1.8,1.8                        c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8C18.5,7.9,19.3,8.7,19.3,9.7L19.3,9.7"></path>
      </svg><span>しるし</span>
    </div>
    <div class="stamp-icon" v-show="Boolean(markDialog)" @mouseleave="mouseleave()">
      <ul>
        <li><img src="/assets/icon-like.svg" alt="いいね!" @click="mark(1)"><span>いいね!</span></li>
        <li><img src="/assets/icon-star.svg" alt="お気に入り" @click="mark(2)"><span>お気に入り</span></li>
        <li><img src="/assets/icon-music.svg" alt="面白い" @click="mark(3)"><span>面白い</span></li>
        <li><img src="/assets/icon-bikkuri.svg" alt="へぇ" @click="mark(4)"><span>へぇ</span></li>
        <li><img src="/assets/icon-todo.svg" alt="ToDo" @click="mark(5)"><span>ToDo</span></li>
        <li><img src="/assets/icon-ok.svg" alt="確認OK!" @click="mark(6)"><span>確認OK!</span></li>
        <li><img src="/assets/icon-cheers.svg" alt="お疲れ様" @click="mark(7)"><span>お疲れ様</span></li>
        <li><img src="/assets/icon-love.svg" alt="ありがとう" @click="mark(8)"><span>ありがとう</span></li>
        <li><img src="/assets/icon-sorry.svg" alt="残念" @click="mark(9)"><span>残念</span></li>
        <li><img src="/assets/icon-through.svg" alt="スルー" @click="mark(10)"><span>スルー</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props : {
    entryId : {
      type : Number,
      required : true
    }
  },
  data () {
    return {
      markDialog : false,
      markIds : {}
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account'
    ])
  },
  methods : {
    ...mapMutations([
      'setClientErrors'
    ]),
    // el-popoverを使用した方が良さそう
    toggleMarkDialog : function () {
      if (this.loginFlg) {
        // ログインユーザのみしるしダイアログ表示
        this.markDialog = this.markDialog ? false : true
        return
      }
      this.$toasted.error("please log in")
    },
    mouseleave : function () {
      this.markDialog = false
    },
    mark : function (markId) {
      let params = {
        account_id : this.account.id,
        entry_id : this.entryId,
        mst_mark_id : markId,
      }

      this.axios.post(this.Global.url.api_marks_create, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          // 表示変更
          this.$emit('on-marked')
          return
        }
        // 重複エラー：追加しないのでスルー
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/mark.vue::mark", params: params, errorMessage:error})
      })
    },
  }
}
</script>