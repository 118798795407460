<template>
  <div>
    <div class="action-overlay" v-show="dialogDisplay">
      <div class="container">
        <p>話題を作成</p>
        <div class="item">
          <input type="text" placeholder="話題名" v-model="hashtagName"><span class="focusborder"></span>
        </div>
        <div class="action">
          <button class="cancel" @click="toggleDialog">キャンセル</button>
          <button class="confirm" @click="create">作成</button>
        </div>
      </div>
    </div>
    <a href="" @click.prevent="toggleDialog"><span>話題をつくる +</span></a>
  </div>
  
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import ErrorHandler from '../../js/error-handler'
import mst_topics from '../../js/api/mst-topics'

export default {
  props : {
    circleId :{
      type:Number,
      required:false
    },
  },
  data () {
    return {
      hashtagName:null,
      dialogDisplay:false,
      saveButtonDisplay:false
    }
  },
  computed:{
    ...mapState([
      'circleMode',
    ]),
  },
  mounted : function () {

  },
  methods:{
    ...mapMutations([
      'setSectionHashtagsReloadFlg',
      'setClientErrors'
    ]),
    toggleDialog : function () {
      this.dialogDisplay = !this.dialogDisplay
    },
     create : async function () {
      if (!this.hashtagName) return
      this.mstTopicsCreate()

    },
    mstTopicsCreate : function () {
      let ret = mst_topics.create(this.hashtagName, false, this.circleId, this.circleMode===this.Global.circle_mode.public? true : false)
      ret.then(res => {
        if (res.data.status === this.Global.api_status.validation_error) {
          this.$toasted.error(ErrorHandler.errorsToArray(res.data.errors))
          return
        }
        if (res.data.status === this.Global.api_status.success) {
          this.setSectionHashtagsReloadFlg(true)
          this.toggleDialog()
          this.hashtagName=null
          this.saveButtonDisplay=false
          return
        }
        this.$toasted.error("error occured!")
        this.setClientErrors({func:"common/new-hashtag.vue::create", params: {}, errorMessage:JSON.stringify(res.data)})
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/new-hashtag.vue::create", params: {}, errorMessage:error})
      })
    },
  }
}
</script>