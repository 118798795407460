<template>
  <div class="exit">
    <section>
      <h2>退会</h2>
      <p>退会と同時に、自分一人しかメンバーがいないサークル、<br>ユーザ情報やこれまでの投稿などは削除されます。<br>（サークル内での発言など、一部の投稿は削除されずに残ります。）</p>
    </section>
    <section>
      <div class="item" v-if="Boolean(loginFlg)">
        <input class="withdraw" type="submit" value="退会する" @click.prevent="onLeave">
      </div>
      <p class="warning">
        ニックネームなどを変更のために、
        退会は必要はありません。<br>退会すると、数分以内に「ともつくネット」から退会処理がなされます。<br>Googleなど検索エンジンによってインデックスされたコンテンツを<br>制御することはできません。
      </p>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data () {
      return {}
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
    ]),
  },
  mounted : function () {    
  },
  methods:{
    ...mapMutations([
      'initState',
      'setClientErrors',
    ]),
    ...mapActions([
      'save',
      'storageClear',
    ]),
    onLeave : function () {
      if (this.account && this.account.id) {
        this.logout()
        this.leaveOut()
      }
    },
    logout : function (accountId) {
      let params = {account_id : accountId}
      this.axios.post(this.Global.url.api_auto_logins_delete, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.initState()
          this.save()
          return
        }
        this.setClientErrors({func:"settings/leave-out.vue::logout", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"settings/leave-out.vue::logout", params: params, errorMessage:error})
      })
    },

    leaveOut : function () {
      let params = {
        id : this.account.id
      }
      this.axios.post(this.Global.url.api_accounts_leave_out, params)
      .then(res => {
        console.dir(res.data)
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.info("completed")
          return
        } 
        this.$toasted.error("unexpected")
        console.dir(res.data)
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"settings/leave-out.vue::leaveOut", params: params, errorMessage:error})
      })
    },
  },
}
</script>
