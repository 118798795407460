<template>
  <nav class="stamp" v-show="isFocus">
    <ul>
      <li><a href="" @click.prevent="setId(1)"  :class="[ {current : markId == like }    ]" v-tooltip="'いいね!'"><img src="/assets/icon-like.svg" alt="いいね!"><span></span></a></li>
      <li><a href="" @click.prevent="setId(2)"  :class="[ {current : markId == star }    ]" v-tooltip="'お気に入り'"><img src="/assets/icon-star.svg" alt="お気に入り"><span></span></a></li>
      <li><a href="" @click.prevent="setId(3)"  :class="[ {current : markId == music }   ]" v-tooltip="'面白い'"><img src="/assets/icon-music.svg" alt="面白い"><span></span></a></li>
      <li><a href="" @click.prevent="setId(4)"  :class="[ {current : markId == bikkuri } ]" v-tooltip="'へぇ'"><img src="/assets/icon-bikkuri.svg" alt="へぇ"><span></span></a></li>
      <li><a href="" @click.prevent="setId(5)"  :class="[ {current : markId == cheers }  ]" v-tooltip="'お疲れ様'"><img src="/assets/icon-cheers.svg" alt="お疲れ様"><span></span></a></li>
      <li><a href="" @click.prevent="setId(6)"  :class="[ {current : markId == todo }    ]" v-tooltip="'ToDo'"><img src="/assets/icon-todo.svg" alt="ToDo"><span></span></a></li>
      <li><a href="" @click.prevent="setId(7)"  :class="[ {current : markId == ok }      ]" v-tooltip="'確認OK!'"><img src="/assets/icon-ok.svg" alt="確認OK!"><span></span></a></li>
      <li><a href="" @click.prevent="setId(8)"  :class="[ {current : markId == love }    ]" v-tooltip="'ありがとう'"><img src="/assets/icon-love.svg" alt="ありがとう"><span></span></a></li>
      <li><a href="" @click.prevent="setId(9)"  :class="[ {current : markId == sorry }   ]" v-tooltip="'残念'"><img src="/assets/icon-sorry.svg" alt="残念"><span></span></a></li>
      <li><a href="" @click.prevent="setId(10)" :class="[ {current : markId == through } ]" v-tooltip="'スルー'"><img src="/assets/icon-through.svg" alt="スルー"><span></span></a></li>
    </ul>
  </nav>

</template>
<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState([
      'myspaceDivisionId',
      'markId'
    ]),
    isFocus : function(){
      return this.myspaceDivisionId == this.Global.myspace_division_id.mark
    },
    like : function () {
      return this.markId == 1
    },
    star : function () {
      return this.markId == 2
    },
    music : function () {
      return this.markId == 3
    },
    bikkuri : function () {
      return this.markId == 4
    },
    cheers : function () {
      return this.markId == 5
    },
    todo : function () {
      return this.markId == 6
    },
    ok : function () {
      return this.markId == 7
    },
    love : function () {
      return this.markId == 8
    },
    sorry : function () {
      return this.markId == 9
    },
    through : function () {
      return this.markId == 10
    },
  },
  methods: {
    ...mapMutations([
      'setMarkId'
    ]),
    setId : function (markId) {
      this.setMarkId(markId)
    }
  }
}
</script>