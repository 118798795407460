<template>
  <div class="post">
    <div class="wrap">
      <div class="user">
        <c-account-image :has-img = "entry.has_img" :account-id = "entry.account_id" :account-name = "entry.account_name" />
      </div>
      <p v-html="entryText"></p>
      <c-comment-marks v-if="parseInt(markCount) > 0" :entry-id="entry.id" :refresh-flg="commentMarksReload" @on-reloaded="toggleCommentMarksReload" />
    </div>
    <div class="comment" v-if="account != null">
      <div v-show="isEditable()" @click="commentEditTooltip = !commentEditTooltip">...</div>
      <ul v-show="commentEditTooltip" @mouseleave="onMouseleave">
        <li @click="openOverlay"><span>編集</span></li>
        <li @click="deleteEntry"><span>削除</span></li>
      </ul>
    </div>
    <div class="action" v-show="loginFlg">
      <c-mark-dialog :entry-id="entry.id" @on-marked="toggleCommentMarksReload"/>
      <div class="action-comment">
        <div class="action" @click="changeCommentDisplay">
          <svg version="1.1" id="icon-comment" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 27.9 23.6" style="enable-background:new 0 0 27.9 23.6;" xml:space="preserve">
            <path class="icon-comment" d="M19.3,1c-0.1,0-0.2,0-0.3,0c-1.5,0-3.1,0-4.6,0c-0.4,0-2.5,0-5,0C8.7,1,8.3,1.1,8.1,1.1C3.4,2,0.3,6.7,1.2,11.4                                c0.8,4.1,4.4,7.1,8.6,7.1c1.2,0,2.4,0,3.6,0c0.3,0,0.6,0.1,0.8,0.3c1.2,1,2.4,2,3.7,2.9c0.4,0.3,0.9,0.6,1.4,0.8                                c0.7,0.3,1.6,0,1.9-0.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.2-0.7,0.2-1.1c0-0.7,0-1.4,0-2.1c0-0.3,0.1-0.6,0.4-0.8                                c3.3-1.6,5.3-5.1,4.9-8.8C26.5,4.8,23.3,1.5,19.3,1L19.3,1z"></path>
          </svg><span>コメント</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Mark from './mark-dialog'
import CommentMarks from './comment-marks'
import emojis from '../../../js/emoji'
import Common from '../../../js/common'
import _ from 'lodash'

export default {
  props : {
    parentEntryId : {
      type : Number,
      required : true
    },
    entry : {
      type : Object,
      required : true
    }
  },
  data : function () {
    return {
      commentMarksReload:false,
      markCount:0,
      commentEditTooltip: false,
    }
  },
  computed:{
    ...mapState([
      'loginFlg',
      'account',
    ]),
    entryText : function () {
      let text = this.entry.text
      
      if (this.account != null) _.forEach(this.entry.destinations, (row)=>{
        text = text.replace('@'+row.account_name + '(' + row.account_nickname + ')', Common.createMentionWithLinkTag(row.account_id, row.account_name, row.account_nickname))
      })
      _.forEach(emojis, (row)=>{
        text = text.replace(row.pattern, '<img src="'+row.src+'" width="20px" height="20px" style="margin-bottom:-5px">')
      })
      return this.entry.account_nickname + '<br>' + text.replace(/\r?\n/g, '<br>')
    }
  },
  created:function() {
    this.markCount=this.entry.mark_count
  },
  mounted:function() {
    _.forEach(document.getElementsByClassName("mentionable"), (row)=>{row.removeAttribute('style')})
  },
  methods : {
    ...mapMutations([
      'setCommentReloadEntryId',
      'setCommentEditContainerDisplay',
      'setCommentEditContainerContext',
      'setClientErrors'
    ]),
    toggleCommentMarksReload : function () {
      this.commentMarksReload = !this.commentMarksReload
      this.markCount++
    },
    changeCommentDisplay : function () {
      this.$emit('change-comment-display', {
          id: this.entry.account_id,
          last_name: this.entry.account_last_name,
          first_name: this.entry.account_first_name,
          nickname: this.entry.account_nickname,
        })
    },
    deleteEntry: function() {
      this.axios.delete(this.Global.url.api_entries_delete + this.entry.id)
      .then(res=>{
        if ( res.data.status === this.Global.api_status.success ) {
          this.$toasted.success("success")
          this.setCommentReloadEntryId(this.parentEntryId)
          return
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/comment-entries.vue::deleteEntry", params: { entry_id : this.entry.id}, errorMessage:error})
      })
    },
    openOverlay: function() {
      this.setCommentEditContainerContext(
        {
          parentEntryId: this.parentEntryId,
          entry: this.entry,
        }
      );
      this.setCommentEditContainerDisplay(true);
    },
    onMouseleave: function() {
      this.commentEditTooltip = false
    },
    isEditable : function () {
      if (this.account === null) return false
      if (this.account.id === this.entry.account_id) return true 
      return false
    },
  },
  components : {
    'c-mark-dialog' : Mark,
    'c-comment-marks':CommentMarks,
  }
}
</script>