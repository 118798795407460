import Global from '../constant'
import axios from 'axios'
var mst_topics = {

  show: async function() {
    alert(Global.url.api_mst_topics_show)
    alert(axios)
  },

  search: async function() {
    alert(Global.url.api_mst_topics_search)
    alert(axios)
  },

  searchByDivision: async function() {
    alert(Global.url.api_topics_search_by_division)
  },

  delete: async function() {
    alert(Global.url.api_mst_topics_delete)
  },

  create: async function(hashtagName, official, circleId, official_circle) {
    let params = {
      name : hashtagName,
      official : official,
    }
    if (circleId > 0) params['circle_id'] = circleId
    if (circleId > 0) params['official_circle'] = official_circle
    return await axios.post(Global.url.api_mst_topics_create, params)
  },

  update: async function() {
    alert(Global.url.api_mst_topics_update)
  },

}

export default mst_topics