<template>
  <div class="dialog">
    <section><router-link to="/circle">サークル一覧へ戻る</router-link>
      <h2>サークルを作成</h2>
      <p>発言やコメントなど、すべての機能をお楽しみいただけます。いつでも退会は可能。ともつくネットを楽しみましょう!</p>
      <p>エラー：<c-validation-errors :errors-hash="errors" /></p>
      <div class="item">
        <input type="text" placeholder="サークル名 必須" v-model="circleName"><span class="focusborder"></span>
      </div>
      <div class="item">
        <textarea rows="8" placeholder="活動内容 必須" v-model="activities"></textarea><span class="focusborder"></span>
      </div>
      <div class="item">
        <input type="text" placeholder="メールアドレス 必須" v-model="circleMail"><span class="focusborder"></span>
      </div>
      <div class="item">
        <!-- <input type="text" placeholder="アイコンまたは画像"><span class="focusborder"></span> -->
        
        <!-- <div class="photo" @click="photoImageClick()">
          <svg version="1.1" id="icon-photo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28.3 28.3" style="enable-background:new 0 0 28.3 28.3;" xml:space="preserve">
            <path class="st0" id="np_photo_2067966_000000" d="M25.7,7.3h-3.6l-0.6-3.6c-0.1-0.6-0.4-1.2-1-1.5c-0.5-0.4-1.1-0.5-1.7-0.4                    L2.3,4.7C1,4.9,0.2,6.1,0.4,7.4c0,0,0,0,0,0l2.5,14.3c0.1,0.6,0.5,1.1,1,1.5c0.4,0.3,0.9,0.4,1.3,0.4c0.1,0,0.3,0,0.4,0l1-0.2v0.8                    c0,1.3,1,2.3,2.3,2.3h16.7c1.3,0,2.3-1,2.3-2.3V9.7C28,8.4,27,7.4,25.7,7.3C25.7,7.3,25.7,7.3,25.7,7.3L25.7,7.3z M24.3,12.4                    c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5C23.7,10.9,24.3,11.6,24.3,12.4L24.3,12.4z M5.2,21.7                    c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.3L2.3,7.1c0-0.2,0.1-0.4,0.3-0.5c0,0,0,0,0,0L19,3.7h0.1c0.1,0,0.2,0,0.2,0.1                    c0.1,0.1,0.2,0.2,0.2,0.3l0.6,3.3H9c-1.3,0-2.3,1-2.3,2.3v11.8L5.2,21.7z M25.7,24.6H9c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0v-4L14,14                    c0.1-0.2,0.3-0.2,0.5-0.1c0,0,0,0,0.1,0.1l3.4,3.8C18,17.9,18,18,18,18.1l-0.4,2c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0,0.1,0l3.4-3.7                    c0.1-0.1,0.2-0.1,0.4,0c0,0,0,0,0,0l4.5,4.9v2.8C26.1,24.4,25.9,24.6,25.7,24.6C25.7,24.6,25.7,24.6,25.7,24.6L25.7,24.6z"></path>
          </svg>
        </div> -->
        <input @change="attachImg" type="file" class="hide__-photo" :model="img" ref="hide_photo" />
        <!-- <div v-show="resizedImg" class="resize-img__preview">
          <div class="resize-img__preview__circle" @click="clearAttachImg">
            <img :src="resizedImg" class="resize-img__preview__img">
          </div>
        </div> -->
        <c-circle-image v-show="img===null" :has-img="hasImg" :circle-id="circleId" :circle-name="circleName" />
      </div>
      
      <div class="item">
        <h3>サークルの公開設定</h3>
        <p>公開サークルで、広場にも公開される話題で話したり、非公開サークルで仲間内で話ができます。完全非公開のサークルも作ることができ、用途に合わせて様々な使い方ができます。</p>
        <div class="choice">
          <input type="radio" checked name="choice" id="choice-1" value="true" v-model="publish" @change="changeCirclePublishFlg">
          <label for="choice-1">
            <svg id="icon-park" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
              <path class="st0" d="M18.3,28.8c3.8,0,6.8,3,6.8,6.7v0.1c0,0.4,0,0.8-0.1,1.2c3.8,1.5,5.8,5.8,4.2,9.7c-1.5,3.9-5.8,5.8-9.7,4.2                                    c-0.1,0-0.1-0.1-0.1-0.1v14.7h20.1V60h-1.8c-1,0-1.7-0.8-1.7-1.7v-3.5c0-1,0.8-1.7,1.7-1.7c1,0,1.7,0.7,1.8,1.7c0,0,0,0,0,0.1v1.7                                    H43v-3.4h-1.8c-1,0-1.7-0.8-1.7-1.7c0,0,0,0,0-0.1v-3.5c0-1,0.8-1.7,1.7-1.7h25.9c1,0,1.7,0.8,1.7,1.7v3.5c0,1-0.7,1.7-1.7,1.8                                    c0,0,0,0-0.1,0h-1.8v3.4h3.5v-1.7c0-1,0.8-1.7,1.7-1.7c0,0,0,0,0.1,0c1,0,1.7,0.8,1.7,1.7v3.5c0,1-0.8,1.7-1.7,1.7h-1.8v5.4                                    c1-0.1,1.8,0.7,1.9,1.6c0.1,1-0.7,1.8-1.6,1.9c-0.1,0-0.1,0-0.2,0H9.1c-1,0.1-1.8-0.7-1.9-1.6c-0.1-1,0.7-1.8,1.6-1.9                                    c0.1,0,0.1,0,0.2,0h8.3V49.6c-3.2,1.9-7.4,0.9-9.3-2.3s-0.8-7.4,2.4-9.3c0.4-0.3,0.9-0.5,1.3-0.6c-0.1-0.6-0.3-1.2-0.3-1.8                                    C11.4,31.8,14.5,28.8,18.3,28.8C18.3,28.8,18.3,28.8,18.3,28.8L18.3,28.8z M43,65.4h22.3V60H43V65.4z M60.1,13.3                                    c-1.4-1.9-3.5-3-5.9-3c-4-0.1-7.3,3.2-7.4,7.2c0,0.9,0.1,1.7,0.4,2.6c-2.6-0.3-4.9,1.5-5.3,4.1c0,0.2-0.1,0.4,0,0.7                                    c0,2.7,2.2,4.9,4.9,4.9l0,0h3.5c-0.3-0.8-0.6-1.6-0.6-2.5c0-2.6,1.9-4.9,4.4-5.3C53.6,17.9,56.2,14.2,60.1,13.3L60.1,13.3z M68.1,23                                    c1.2-3.4-0.6-7.2-4-8.4c-3.4-1.3-7.2,0.6-8.4,4c-0.5,1.5-0.5,3,0,4.4c-2.4-0.3-4.5,1.5-4.7,3.8c-0.3,2.4,1.5,4.5,3.8,4.7                                    c0.1,0,0.3,0,0.5,0h13.2c2.4,0,4.3-1.9,4.4-4.3c0-2.4-1.9-4.3-4.3-4.4C68.4,22.9,68.3,22.9,68.1,23L68.1,23z M61.8,53H46.5v3.4h15.4                                    V53z"></path>
            </svg>
            <h4>公開サークル</h4>
            <ul>
              <li>誰でも見える</li>
              <li>広場に発言を公開</li>
              <li>非公開サークルも同時作成</li>
              <li>後から完全非公開にも可</li>
            </ul>
          </label>
          <input type="radio" name="choice" id="choice-2" value="false" v-model="publish" @change="changeCirclePublishFlg">
          <label for="choice-2">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
              <path class="st0" d="M56,34.9H24.4c-2.8,0-5.1,2.3-5.1,5.1l0,0v22c0,2.8,2.3,5.1,5.1,5.1l0,0h31.2c2.8,0,5.1-2.3,5.1-5.1l0,0l0-22                                    C60.7,37.3,58.6,35.1,56,34.9L56,34.9z M41.4,51.9v6.3h-2.3v-6.3c-1.7-0.6-2.5-2.5-1.9-4.2c0.6-1.7,2.5-2.5,4.2-1.9                                    c1.7,0.6,2.5,2.5,1.9,4.2C43,50.8,42.3,51.5,41.4,51.9z M28.8,32.8c1,0,1.9-0.9,1.9-1.9l0,0v-3.2c-0.1-5.1,4-9.3,9.1-9.4                                    c5.1-0.1,9.3,4,9.4,9.1c0,0.1,0,0.2,0,0.3v3.2c0,1,0.9,1.9,1.9,1.9h1.5c1,0,1.9-0.9,1.9-1.9l0,0v-3.2C54.7,19.5,48,12.9,40,12.9                                    s-14.6,6.5-14.6,14.7v3.2c0,1,0.9,1.9,1.9,1.9H28.8z"></path>
            </svg>
            <h4>非公開サークル</h4>
            <ul>
              <li>登録ユーザーのみの完全な非公開サークル</li>
              <li>参加ユーザーのみ発言/閲覧可能</li>
            </ul>
          </label>
        </div>
      </div>
      <button class="publish" @click.prevent="save()">{{circleId > 0 ? '保存する' : '保存して次へ'}}</button>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import loadImage from 'blueimp-load-image'
import base64ToBlob from 'b64-to-blob'

export default {
  props:{
    circleId : {
      type : Number,
      required : true
    },
  },
  data () {
    return {
      circleName : null,
      founderId : null,
      activities : null,
      img : null,
      publish : true,
      circleMail : null,
      resizedImg: [],
      hasImg: false,
      errors : {}
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
    ]),
  },
  created () {
    // this.circleId = Number(this.$route.params.circleId)
    if (this.account) this.founderId = this.account.id
    if (this.circleId > 0) {
      this.getCircle()
    }
    if (this.circleId == 0) this.publish = true
  },
  watch : {
    account : function(newValue) {
      this.founderId = newValue.id
    },
  },
  mounted () {},
  methods : {
    ...mapMutations([
      'setClientErrors',
      'setCircleEditCircleId',
      'setCircleEditPublish',
      'setCircleEditTabId',
      'setCircleEditCircleMembers',
    ]),
    getCircle : function () {
      this.axios.get(this.Global.url.api_circles_show + this.circleId)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.circleName = res.data.circle.circle_name
          this.founderId = res.data.circle.founder_id
          this.activities = res.data.circle.activities
          this.publish = res.data.circle.publish
          this.circleMail = res.data.circle.circle_mail
          this.hasImg = res.data.circle.has_img
          this.setCircleEditCircleId(res.data.circle.id)
          this.setCircleEditPublish(res.data.circle.publish)
          return
        }
        this.$toasted.error("error occured!")
        this.$router.push( { path : '/circle' }, () => {} )
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/main.vue::getCircle", params: {}, errorMessage:error})
      })
    },
    // changeFile : function (e) {
    //   const files = e.target.files || e.dataTransfer.files
    //   this.img = files[0]
    //   // console.dir(this.img)
    // },
    url : function () {
      return this.circleId > 0 ? this.Global.url.api_circles_update : this.Global.url.api_circles_create
    },
    save : function () {
      var formData = new FormData()
      if (this.circleId > 0) formData.append("circle_id",this.circleId)
      if (this.circleName) formData.append("circle_name",this.circleName)
      if (this.founderId) formData.append("founder_id",this.founderId )
      if (this.activities) formData.append("activities",this.activities )
      if (this.img) formData.append("img", this.img)
      formData.append("publish", this.publish)
      if (this.circleMail) formData.append("circle_mail", this.circleMail)

      this.axios.post(this.url(), formData)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          if (this.circleId == 0) {
            this.saveFinderToCircleMember(res.data.circle.id)
            return
          } else {
            this.saveFlg = true
            this.$toasted.success("completed!")
            this.errors = {}
            this.$router.go({path: this.$router.currentRoute.path, force: true})
            return
          }
        }
        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        }
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"circle/edit/main.vue::save", params: formData, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/main.vue::save", params: formData, errorMessage:error})
      })
      .finally(()=>{
        this.saveFlg = false
      })
    },
    saveFinderToCircleMember : function (circleId) {
      let params = {
        circle_id : circleId,
        account_id : this.founderId,
        admin_flg : 1
      }
      this.axios.post(this.Global.url.api_circle_members_create, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("completed!")
          this.errors = {}
          this.setCircleEditCircleId(circleId)
          this.setCircleEditTabId(this.Global.settings_tab_id.members)
          this.$router.push( { path : '/circle/edit/' + circleId }, () => {} )
          return
        }

        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        } 
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"circle/edit/main.vue::saveFinderToCircleMember", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/main.vue::saveFinderToCircleMember", params: params, errorMessage:error})
      })
    },

    attachImg: function (e) {
      const file = e.target.files[0];
      if (!file) {
        return
      }

      loadImage.parseMetaData(file, (data) => {
        const options = {
          // maxHeight: 500,
          maxHeight: this.Global.display_image_max_size,
          // maxWidth: 500,
          maxWidth: this.Global.display_image_max_size,
          canvas: true
        }
        if (data.exif) {
          options.orientation = data.exif.get('Orientation');
        }
        this.displayImage(file, options)

      })
      const reader = new FileReader()
      reader.onload = (event) => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.crossOrigin = 'Anonymous'

        image.onload = () => {
          const drawImageArgs = this.drawImageArgs(image)

          if (drawImageArgs.length === 9) {
            canvas.width = drawImageArgs[7]
            canvas.height = drawImageArgs[8]
          }

          ctx.drawImage(...drawImageArgs)

          const base64 = canvas.toDataURL()
          // this.$emit('resized', { base64, blob: base64ToBlob(base64.replace(/^.*,/, '')) })
          this.img = base64ToBlob(base64.replace(/^.*,/, ''))
        }

        image.src = event.target.result
      }

      reader.readAsDataURL(file)
    },
    drawImageArgs (image) {
      const maxSize = this.Global.updaload_image_max_size
      let sx = 0
      let sy = 0
      let imageWidth = image.width
      let imageHeight = image.height

      if (imageWidth > imageHeight) {
        sx = (imageWidth - imageHeight) / 2
        imageWidth = imageHeight
      }

      if (imageHeight > imageWidth) {
        sy = (imageHeight - imageWidth) / 6
        imageHeight = imageWidth
      }

      const dstWidth = imageWidth > maxSize ? imageWidth * maxSize / imageWidth : imageWidth
      const dstHeight = imageHeight > maxSize ? imageHeight * maxSize / imageHeight: imageHeight

      return [image, sx, sy, imageWidth, imageHeight, 0, 0, dstWidth, dstHeight]
    },
    displayImage(file, options) {
      loadImage(
        file,
        async (canvas) => {
          const data = canvas.toDataURL(file.type)
          // data_url形式をblob objectに変換
          const blob = this.base64ToBlob(data, file.type)
          // objectのURLを生成
          const url = window.URL.createObjectURL(blob)
          this.resizedImg = url;
        },
        options
      )
    },
    photoImageClick : function () {
      this.$refs.hide_photo.click()
    },
    clearAttachImg() {
      this.resizedImg = null;
      if (this.$refs.fileInput && this.$refs.fileInput.value !== undefined) {
        this.$refs.fileInput.value = '';
        window.URL.revokeObjectURL(this.resizedImg);
      }
    },
    base64ToBlob(base64, fileType) {
      const bin = atob(base64.replace(/^.*,/, ''));
      const buffer = new Uint8Array(bin.length);
      for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      return new Blob([buffer.buffer], {
        type: fileType ? fileType : 'image/png'
      });
    },
    changeCirclePublishFlg() {
      this.setCircleEditPublish(this.publish)
    },
  },
}
</script>