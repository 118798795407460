<template>
  <div>
    <div class="edit-member">
      <h4>メンバーを追加・編集する</h4>
      <div class="item">
        <div>
          <button class="member-select" @click="toggleMembersOverlay">+ メンバーを選ぶ</button>
        </div>
        <div>
          <h5>管理人</h5>
          <div v-for="(adminMember, index) in adminMembers" :key="index">
            <p>{{adminMember.account_last_name + adminMember.account_first_name}}</p>
            <button v-show="adminMembers.length > 1" @click="toGeneralMember(adminMember)">管理者からはずす</button>
          </div>
          <!-- <div>
            <p>吉岡里帆</p>
            <button>管理者からはずす</button>
            <button class="dont">退会させる</button>
          </div> -->
        </div>
        <div>
          <h5>メンバー</h5>
          <div v-for="(generalMember, index) in generalMembers" :key="index">
            <p>{{generalMember.account_last_name + generalMember.account_first_name}}</p>
            <button v-show="!generalMember.withdrawFlg" @click="toAdminMember(generalMember)">管理者にする</button>
            <button class="dont" @click="withdraw(generalMember)" v-show="circleMembers.length > 1 && !generalMember.withdrawFlg">退会させる</button>
            <button class="dont" @click="revival(generalMember)"  v-show="Boolean(generalMember.withdrawFlg)">復活させる</button>
          </div>
          <!-- <div>
            <p>吉岡里帆</p>
            <button>管理者からはずす</button>
            <button class="dont">退会させる</button>
          </div> -->
          <!-- <div>
            <p>小芝風花</p>
            <button>管理者からはずす</button>
            <button class="dont">退会させる</button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="action-overlay" v-show="membersOverlay">
      <div class="container">
        <div class="item">
          <mentionable
            :keys="keys"
            :items="items"
            :limit="mentionLimit"
            insert-space>
            <textarea placeholder="@名前を入力してください" v-model="text"></textarea>
            <template #no-result>
              <div class="dim">
                No result
              </div>
            </template>
            <template #item-@="{ item }">
              <div class="destination">
                {{ item.lastName + item.firstName }}
                <span class="dim">
                  {{ item.nickname }}
                </span>
              </div>
            </template>
          </mentionable>
        </div>
        <div class="action">
          <button class="cancel" @click="toggleMembersOverlay">キャンセル</button>
          <button class="confirm" @click="set">追加</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import _ from 'lodash'
import mentionUtils from '../../../../js/common/entry/MentionUtils'

export default {
  props:{
    circleId : {
      type: Number,
      required:true
    },
    saveFlg : {
      type: Boolean,
      required:true
    }
  },
  data : function(){
    return {
      circleMembers  : null,
      membersOverlay : false,
      text           : null,
      keys           : ['@'],
      mentionLimit   : this.Global.vue_mention_limit,
      accounts       : null,
      items          : [],
    }
  },
  computed: {
    ...mapState([
      'circleEditCircleMembers',
    ]),
    adminMembers : function () {
      return _.filter(this.circleMembers, (row)=>{ return row.admin_flg })
    },
    generalMembers : function () {
      return _.filter(this.circleMembers, (row)=>{  return !row.admin_flg })
    }
  },
  watch : {
    saveFlg : function () {
      if (!this.saveFlg) return
      // console.log("------------------> 73")
      // console.dir(this.circleMembers)

      for (let i in this.circleMembers) {
        // alert("i : " + i + " length : " + this.circleMembers.length)
        // console.log("------------------>")
        // console.log("here :"+this.circleMembers[i].account_last_name+this.circleMembers[i].account_first_name)

        if (this.circleMembers[i].id == null && !this.circleMembers[i].withdrawFlg) {
          // console.log("insert")
          let params = {
            circle_id  : this.circleId,
            account_id : this.circleMembers[i].account_id,
            admin_flg  : this.circleMembers[i].admin_flg
          }
          this.saveCircleMemeber(this.Global.url.api_circle_members_create, params)
          continue
        }
        if (this.circleMembers[i].id == null && this.circleMembers[i].withdrawFlg) {
          // メンバー追加 or リクエスト承認 => 退会させる ケース
          continue
        }

        if (this.circleMembers[i].withdrawFlg) {
          // console.log("delete")
          let params = {
            id : this.circleMembers[i].id
          }
          this.saveCircleMemeber(this.Global.url.api_circle_members_delete, params)
          continue
        }
        // console.log("update")
        // console.log(this.circleMembers[i].admin_flg)
        let params = {
          id : this.circleMembers[i].id,
          admin_flg : this.circleMembers[i].admin_flg
        }
        this.saveCircleMemeber(this.Global.url.api_circle_members_update, params)
      }
      this.$emit('toggle-save-flg')
    },
    circleEditCircleMembers (newValue) {
      this.circleMembers = newValue
      this.items = _.map(this.filterAccounts(), (row) => { return mentionUtils.getMentionStates(row) })
    }
  },
  mounted : function () {
    this.getAccounts()
    this.circleMembers = this.circleEditCircleMembers
  },
  methods : {
    ...mapMutations([
      'setClientErrors',
      'setCircleEditCircleMembers',
      'concatCircleEditCircleMembers',
      // 'setCircleEditCircleMembersReloadFlg'
    ]),
    toggleMembersOverlay : function () {
      this.membersOverlay = !this.membersOverlay
    },

    getAccounts : function () {
      let params = {delete_flg : false}
      this.axios.post(this.Global.url.api_accounts_all, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.accounts = this.setDispFlg(res.data.accounts)
          this.items = _.map(this.filterAccounts(), (row) => { return mentionUtils.getMentionStates(row) })
          return
        }
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/members.vue::getAccounts", params: params, errorMessage:error})
      })
    },
    setDispFlg : function (accounts) {
      let selectedAccountIds = {}
      _.map(this.circleMembers, (row) => {
        return selectedAccountIds[row.account_id] = row.account_id
      })
      return _.map(accounts, (row) => {
        row.selectedFlg = selectedAccountIds[row.id] ? true : false
        return row
      })
    },
    filterAccounts: function () {
      return _.filter(this.accounts, (row) => { return !row.selectedFlg})
    },
    set : function () {
      this.concatCircleEditCircleMembers(this.getAccountsFromText(this.text, this.accounts))
      this.text = null
      this.toggleMembersOverlay()
    },
    getAccountsFromText : function (text, accounts) {
      let _new_members = []
      let _index = this.circleEditCircleMembers.length
      _.map(accounts, (account) => {
        let key = '@' + account.last_name+account.first_name
        if ( text.indexOf(key) != -1 ) {
          account.selectedFlg = true

          // let _filterdCircleInvite = _.filter(this.circleInvites, (row) => { return row.account_id == account.id  })

          // if (_filterdCircleInvite.length == 0) {
          _new_members.push({
            id                 : null,
            circle_id          : this.circleId,
            account_id         : account.id,
            account_last_name  : account.last_name,
            account_first_name : account.first_name,
            account_nickname   : account.nickname,
            admin_flg          : false,
            newMemberFlg       : true,
            _index             : _index
          })
          // } else {
          //   this.circleInvites = _.map(this.circleInvites, (row) => {
          //     if (row.account_id = account.id) {
          //       row.deleteFlg = false
          //     }
          //     return row
          //   })
          // }
          _index++
        }
      })
      return _new_members
    },
    saveCircleMemeber : function (url, params) {
      this.axios.post(url, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success('メンバー登録成功')
          return
        } 
        this.$toasted.error('メンバー登録に失敗')
        this.setClientErrors({func:"circle/edit/members.vue::saveCircleMemeber", params: params, errorMessage:'メンバー登録に失敗:' + JSON.stringify(res.data.errors)})
      })
      .catch(error => {
        console.log(error)
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/members.vue::saveCircleMemeber", params: params, errorMessage:error})

      })
      .finally(()=>{})
    },
    toGeneralMember : function (member) {
      member.admin_flg = false
    },
    toAdminMember : function (member) {
      member.admin_flg = true
    },
    withdraw : function (member) {
      this.circleMembers = _.map(this.circleMembers, row=>{
        if (member._index == row._index) row.withdrawFlg = true
        return row
      })
      // member.withdrawFlg = true
      // this.$forceUpdate()
      this.setCircleEditCircleMembers(this.circleMembers)
    },
    revival : function (member) {
      this.circleMembers = _.map(this.circleMembers, row=>{
        if (member._index == row._index) row.withdrawFlg = false
        return row
      })
      // member.withdrawFlg = false
      // this.$forceUpdate()
      this.setCircleEditCircleMembers(this.circleMembers)
    },
  }

}
</script>