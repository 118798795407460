import VueRouter from 'vue-router'
import Index from '../components/index.vue'
import KeywordSearchResult from '../components/keyword-search/result.vue'
import Square from '../components/square'
import Detail from '../components/detail'
import EntryEdit from '../components/detail/edit'
import TemporarySignInDone from '../components/registration/temporary-sign-in-done.vue'
import SignIn from '../components/registration/sign-in.vue'
import ReissuePassword from '../components/password/reissue-password.vue'
import ReissuePasswordDone from '../components/password/reissue-password-done.vue'
import Settings from '../components/settings'
import Myspace from '../components/myspace'
import Notifications from '../components/notifications'
import CircleTop from '../components/circle/top'
import CircleTimeLine from '../components/circle/time-line'
import CircleApproval from '../components/circle/approval'
import CircleEdit from '../components/circle/edit'
import AdminMenu from '../components/admin'
import AdminAccountIndex from '../components/admin/account'
import AdminAccountEdit from '../components/admin/account/edit.vue'
import AdminMailIndex from '../components/admin/mail'
import AdminHashtagIndex from '../components/admin/hashtag'
import AdminHashtagEdit from '../components/admin/hashtag/edit.vue'
import AboutCotolike from '../components/support/about-cotolike'
import Question from '../components/support/question'
import PrivacyPolicy from '../components/support/privacy-policy'
import TermsService from '../components/support/terms-service'
import Introduction from '../components/support/introduction'
import ContactUs from '../components/support/contact-us'
import CaseStudy from '../components/support/case-study'
import Help from '../components/support/help'

const routes = [
  { path: '/', component: Index },
  { path: '/keyword-search', component: KeywordSearchResult },
  // 広場コンポーネント
  { path: '/square', component: Square },
  { path: '/square/hashtag/:hashtagId', name: 'square/hashtag', component: Square },
  { path: '/entry/:entryId?', name: 'entry', component: Detail },
  { path: '/entry/edit/:entryId?', name: 'entry/edit', component: EntryEdit },

  // 仮登録完了コンポーネント
  { path: '/temporary-sign-in-done/:mail?', component: TemporarySignInDone },
  
  // 本登録コンポーネント
  { path: '/sign-in/:id', component: SignIn },
  
  // パスワード再発行コンポーネント
  { path: '/reissue-password', component: ReissuePassword },
  
  // パスワード再発行完了コンポーネント
  { path: '/reissue-password-done/:mail?', component: ReissuePasswordDone },
  
  // 設置コンポーネント
  { path: '/settings', component: Settings },
  
  // マイスペース
  { path: '/myspace/:accountId?', name: 'myspace', component: Myspace },
  { path: '/myspace/:accountId/:myspaceDivisionId?', name: 'side/myspace', component: Myspace },

  // お知らせ一覧
  { path: '/notifications', component: Notifications },
  
  // サークルトップ
  { path: '/circle', component: CircleTop },
  { path: '/circle/:circlesDivisionId?', name: 'side/circle', component: CircleTop },

  // サークルtime-line
  { path: '/circle/time-line/:circleMode/:circleId?', name: 'circle/time-line', component: CircleTimeLine },
  { path: '/circle/time-line/:circleMode/:circleId/:hashtagId?', name: 'circle/time-line/hashtagId', component: CircleTimeLine },

  // サークル招待承認
  { path: '/circle/approval/:inviteId?', name: 'circle/approval', component: CircleApproval },
  // サークル編集
  { path: '/circle/edit/:circleId?', name: 'circle/edit', component: CircleEdit },
  
  // フッター
  { path: '/about-cotolike', component: AboutCotolike },
  { path: '/question', component: Question },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/terms-service', component: TermsService },
  { path: '/introduction', component: Introduction },
  { path: '/contact-us', component: ContactUs },
  { path: '/case-study', component: CaseStudy },
  { path: '/help', component: Help },

  // 管理者メニュー
  { path: '/admin/menu', component: AdminMenu },
  { path: '/admin/accounts', component: AdminAccountIndex },
  { path: '/admin/account/edit/:accountId', component: AdminAccountEdit },
  { path: '/admin/hashtags', component: AdminHashtagIndex },
  { path: '/admin/hashtag/edit/:hashtagId', component: AdminHashtagEdit },
  { path: '/admin/mail', component: AdminMailIndex },
  
]

const router = new VueRouter({
  routes, // `routes: routes` の短縮表記
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
       return savedPosition
    } else {
       return { x: 0, y: 0 }
    }
  }  
})
export default router