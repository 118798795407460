<template>
  <div class="stamp">
    <ul v-for="mark in marks" :key="mark.id">
      <li>
        <img :src="'/assets/' + mark.mst_mark_path" :alt="mark.mst_mark_name" />
      </li>
    </ul>
    <span>
      <label v-if="marks.length > 0" class="open-button" :for="'trigger-3-' + entryId">{{strMarksRepresentative}}</label>
    </span>
    <div class="modal-wrap">
      <input :id="'trigger-3-' + entryId" type="checkbox" />
      <div class="modal-overlay">
        <label class="modal-trigger" :for="'trigger-3-' + entryId"></label>
        <div class="modal-content">
          <label class="close-button" :for="'trigger-3-' + entryId">&#x2716;&#xFE0F;</label>
          <h3>リアクション {{marks.length}}</h3>
          <ul class="stamp-who">
            <li v-for="(mark, index) in marks" :key="index" >
              <a href="">
                <div class="image">
                  <c-account-anchor-image :has-img = "mark.account_has_img" :account-id = "mark.account_id" :account-name = "mark.account_name" />
                </div>
                <span>{{mark.account_name}}({{mark.nickname}})</span>
              </a>
              <div class="icon-action"
                @click="deleteMark(mark.id)"
                v-tooltip="{
                  content: 'クリックすると削除します',
                  autoHide:false,
                  loadingContent: 'Please wait...',
                  loadingClass: 'content-is-loading',
                }">
                <img :src="'/assets/' + mark.mst_mark_path" :alt="mark.mst_mark_name" />
              </div>
            </li>
          </ul>
        </div>
        <span class="balloon">hogehoge<br>hogehoge<br>hogehoge<br>hogehoge</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
import {mapState, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  props:{
    markCount: {
      type: Number,
      required: true,
    },
    entryId : {
      type : Number,
      required : true
    },
    refreshFlg : {
      type: Boolean,
      required : false
    }
  },
  data () {
    return {
      marks:[],
      accountsHash:null,
    }
  },
  computed : {
    ...mapState([
      'account'
    ]),
    strMarksRepresentative : function () {
      if (!this.marks.length === 0) return '' 

      let nicknames = _.uniq(_.map(this.marks, 'nickname'))
      let allCount = nicknames.length

      if (allCount > this.Global.mark_link_representative) {
        nicknames = _.take(nicknames, this.Global.mark_link_representative)
        let otherCount = allCount - this.Global.mark_link_representative
        return nicknames.join(',') + "他" + otherCount + "名"
      }
      return nicknames.join(',')
    },

    strMarks : function () {
      return _.map(this.marks, 'nickname').join('<br/>')
    },

  },
  watch:{
    refreshFlg : function(val) {
      if (val==true) {
        this.marks = []
        this.get()
      }
    }
  },
  created : function () {
    if (this.entryId != null) this.get()
    // console.log(this.entryId)
  },
  methods : {
    ...mapMutations([
      'setClientErrors'
    ]),
    get : function () {
      let params = { entry_id : this.entryId }
      this.axios.post(this.Global.url.api_marks_search, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.marks = res.data.marks
          this.$emit('on-reloaded')
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/marks-link.vue::get", params: params, errorMessage:error})
      })

    },
    createTooltipContents : function (mstMarkId, accountId) {
      if (accountId === this.account.id) return 'クリックすると削除します'
      if (!this.accountsHash) this.accountsHash = this.createHash()
      let accounts = this.accountsHash[mstMarkId]
      return _.map(accounts, 'account_name').join('<br />')
    },
    createHash : function () {
      let res = {}
      _.forEach(this.marks, row => {
        if (!res[row.mst_mark_id]) res[row.mst_mark_id] = []
        res[row.mst_mark_id].push({
          account_id: row.account_id,
          account_name: row.account_name,
          account_nickname: row.nickname,
        })
      })
      return res
    },
    deleteMark : function (id) {
      this.axios.delete(this.Global.url.api_marks_delete + id)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.marks = _.filter(this.marks, r => r.id != id)
          if(this.marks.length == 0) {
            document.getElementById('trigger-3-' + this.entryId).checked = false
          }
          return
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/marks-link.vue::deleteMark", params: {id: id}, errorMessage:error})
      })
    },
  }
}
</script>