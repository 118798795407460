<template>
  <div id="myspace">
    <div id="container">
      <main>
        <c-profile :target-account="targetAccount" />
        <c-followers :target-account="targetAccount" />
        <c-follows :target-account="targetAccount" />
      </main>
      <aside>
        <c-myspace-division v-show="isLoginAccount"></c-myspace-division>
        <c-marks-condition v-show="isLoginAccount" />
        <main class="timeline">
          <c-tweet v-show="isLoginAccount" :entry-division-id="this.entryDivisionId" v-if="loginFlg"/>
          <c-entry v-for="(entry, index) in entries" :key="index" :entry-division-id="entryDivisionId" :entry="entry" :mode="entry_display_mode"></c-entry>
          <div v-show="entries.length == 0 ">投稿されていません</div>
          <button @click="()=>{ this.getEntries(this.targetAccountId) }">さらに見る</button>
        </main>
      </aside>
      <aside>
        <c-notifications v-show="isLoginAccount" :account="targetAccount" />
      </aside>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import Profile from './profile.vue'
import MyspaceDivision from './myspace-division.vue'
import Tweet from '../common/entry/tweet.vue'
import MarksCondition from './marks-condition.vue'
import Entry from '../common/entry'
import Followers from '../common/followers.vue'
import Follows from './follows.vue'
import Notifications from '../common/notifications'

export default {
  data () {
    return {
      entryDivisionId : this.Global.entry_division_id.myspace,
      targetAccountId : null,
      targetAccount : {},
      entries: [],
      limit: this.Global.entry_limit_per_page,
      offset: 0,
      entry_display_mode: this.Global.entry_display_mode.time_line,
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'entryReloadFlg',
      'account',
      'myspaceDivisionId',
      'markId',
      'followCount',
    ]),
    isLoginAccount : function () {
      if (this.account == null) return false
      return this.targetAccountId == this.account.id
    },
  },
  created : function () {
    if(this.$route.params.accountId && this.$route.params.myspaceDivisionId) this.setMyspaceDivisionId(this.$route.params.myspaceDivisionId)

    this.getAccount(this.$route.params.accountId)
    this.targetAccountId = this.$route.params.accountId

  },
  watch : {
    entryReloadFlg (newValue) {
      if ( !newValue ) return
      this.entries = []
      this.offset = 0
      this.getEntries(this.account.id)
      this.setEntryReloadFlg(false)      
    },
    myspaceDivisionId () {
      this.entries = []
      this.offset = 0
      this.getEntries(this.account.id)
    },
    markId () {
      this.entries = []
      this.offset = 0
      this.getEntries(this.account.id)
    },
    followCount () {
      if (this.myspaceDivisionId == this.Global.myspace_division_id.home) {
        this.getEntries(this.targetAccountId, this.myspaceDivisionId)
      }
    },
  },

  methods : {
    ...mapMutations([
      'setMyspaceDivisionId',
      'setMarkId',
      'setEntryReloadFlg',
      'setClientErrors',
    ]),
    // unused
    // init : function () {
    //   this.setMyspaceDivisionId(this.Global.myspace_division_id.home)
    //   this.setMarkId(null)
    // },
    getAccount : function (accountId) {
      this.axios.get(this.Global.url.api_accounts_show + accountId)
      .then(res=>{
        if (res.data.status == this.Global.api_status.success) {
          this.targetAccount = res.data.account
          return
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"myspace/index.vue::getAccount", params: {}, errorMessage:error})
      }).finally(()=>{
        this.getEntries(accountId)
      })
    },
    getEntries : function(accountId) {
      this.get(accountId, this.myspaceDivisionId)
    },
    get : function(accountId, myspaceDivisionId) {
      // if (!this.account) return
      let params = {
        entry_division_id   : this.entryDivisionId,
        account_id          : accountId,
        myspace_division_id : this.account != null && accountId == this.account.id ? myspaceDivisionId : this.Global.myspace_division_id.my,
        mark_id             : this.markId,
        limit               : this.limit,
        offset              : this.offset
      }

      if (this.account) params.login_account_id = this.account.id 
      this.axios.post(this.Global.url.api_entries_search, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          if (this.entries.length > 0 && res.data.entries.length == 0) {
            this.$toasted.info("これ以上取得できません")
            return
          } 
          this.entries = this.entries.concat(res.data.entries)
          this.offset += this.limit
          return
        }
        this.$toasted.info("failed")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"myspace/index.vue::getEntries", params: params, errorMessage:error})
      })
    },
  },
  components : {
    'c-profile' : Profile,
    'c-myspace-division' : MyspaceDivision,
    'c-marks-condition' : MarksCondition,
    'c-tweet' : Tweet,
    'c-entry' : Entry,
    'c-followers' : Followers,
    'c-follows' : Follows,
    'c-notifications' : Notifications,
  }
}
</script>