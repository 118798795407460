<template>
  <div class="launcher-menu">
    <ul>
      <li
        v-tooltip="{
          content: '編集',
          autoHide:false,
          loadingContent: 'Please wait...',
          loadingClass: 'content-is-loading',
        }"
      ><font-awesome-icon icon="edit" style="cursor:pointer; color: #24a7ff;" @click="editEntry"/></li>
      <li
        v-tooltip="{
          content: '削除',
          autoHide:false,
          loadingContent: 'Please wait...',
          loadingClass: 'content-is-loading',
        }"
      ><font-awesome-icon icon="trash-alt" style="cursor:pointer; color: #24a7ff;" @click="deleteEntry" /></li>
    </ul>
  </div>  
</template>
<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props:{
    entry: {
      type: Object,
      required: true,
    },
  },
  data : function () {
    return {
      isEditable: false
    }
  },
  computed : {
    ...mapState([
      'account',
    ]),
  },
  mounted : function () {
    if (this.account.id === this.entry.account_id) {
      this.isEditable = true
    }
  },
  methods : {
    ...mapMutations([
      'setEntryReloadFlg',
      'setEntryEditContainerDisplay',
      'setEntryEditContainerContext',
      'setClientErrors'
    ]),
    editEntry: function() {
      this.setEntryEditContainerDisplay(true);
      this.setEntryEditContainerContext({
        entry: this.entry,
      });
    },
    deleteEntry: function() {
      if (!window.confirm('削除しますか？')) {
        return
      }
      this.axios.delete(this.Global.url.api_entries_delete + this.entry.id)
      .then(res=>{
        if ( res.data.status === this.Global.api_status.success ) {
          this.$toasted.success("success")
          this.setEntryReloadFlg(true)
          return
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/launcher-menu.vue::deleteEntry", params: { entry_id : this.entry.id}, errorMessage:error})
      })
    }
  },
}
</script>