<template>
  <nav class="sub">
    <ul>
      <div class="add-topic" v-show="circleHashtagDivisionId == free">
        <c-new-hashtag :circle-id="circle.id" v-show="memberFlg" />
      </div>
      <li v-for="hashtag in hashtags" :key="hashtag.mst_topic_id" v-bind:class="[ {current : hashtag.id == circleHashtagId } ]">
        <!-- <a href="#" @click.prevent="setCircleHashtagId(hashtag.id)"><div>{{hashtag.name}}</div></a> -->
        <router-link :to = "{ name:'circle/time-line/hashtagId', params:{ circleMode: circleMode, circleId: circle.id, hashtagId: hashtag.id} }"><div>{{hashtag.name}}</div></router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import NewHashtag from '../../common/new-hashtag.vue'

export default {
  props : {
    circle : {
      type : Object,
      required : true
    },
    memberFlg: {
      type : Boolean,
      required : true
    }
  },
  data () {
    return {
      hashtags : [],
    }
  },
  computed: {
    ...mapState([
      'circleHashtagDivisionId',
      'circleHashtagId',
      'circleMode',
      'sectionHashtagsReloadFlg'
    ]),
    free : function() {
      return this.Global.hashtag_division_id.free
    }
  },
  created () {
    this.getHashtags()
  },
  watch : {
    circleMode () {
      this.hashtags=[]
      this.getHashtags()
    },
    circleHashtagDivisionId () {
      this.hashtags=[]
      this.getHashtags()
    },
    sectionHashtagsReloadFlg (newVal) {
      if (!newVal) return
      this.hashtags=[]
      this.getHashtags()
      this.setSectionHashtagsReloadFlg(false)
    },
  },
  mounted () {},
  methods : {
    ...mapMutations([
      'setCircleHashtagId',
      'setSectionHashtagsReloadFlg',
      'setClientErrors',
    ]),
    getHashtags : function () {
      let params = {
        division_id : this.circleHashtagDivisionId,
        circle_id : this.circle.id,
        official_circle : this.circleMode === this.Global.circle_mode.public ? true : false
      }
      this.axios.post(this.Global.url.api_topics_search_by_division, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.hashtags = res.data.hashtags
          return
        }
        this.$toasted.error("error occured")
        this.setClientErrors({func:"circle/time-line/section-hashtag.vue::getHashtags", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/time-line/section-hashtag.vue::getHashtags", params: params, errorMessage:error})
      })
    },
  },
  components : {
    'c-new-hashtag' : NewHashtag
  }
}
</script>