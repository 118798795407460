<template>
  <div id="support">
    <div class="header">
      <h1>アカウント管理</h1>
    </div>
    <div class="container">
      <main>
        <section>
          <button @click="prev">戻る</button>
          <br />
          <table>
            <thead>
              <tr>
                <td>姓</td>
                <td>名</td>
                <td>ニックネーム</td>
                <td>mail</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account,index) in accounts" :key="index">
                <td>{{account.last_name}}</td>
                <td>{{account.first_name}}</td>
                <td>{{account.nickname}}</td>
                <td>{{account.mail}}</td>
                <td><a href="#" @click.prevent="edit(account.id)">edit</a></td>
              </tr>
            </tbody>
          </table>
          <br />
          <button @click="prev">戻る</button>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'

export default {
  data(){
    return {
      accounts:null
    }
  },
  computed:{
    ...mapState(['loginFlg', 'account'])
  },
  mounted:function() {    
    if(!this.loginFlg) {
      this.$toasted.info("no login! please log in.")
      this.$router.push( { path : '/' }, () => {} )
      return
    }
    this.get()
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    get : function () {
      this.axios.post(this.Global.url.api_accounts_all, {}).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.accounts = res.data.accounts
          return
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"admin/account/index.vue::get", params: {}, errorMessage:error})
      })
    },
    edit : function (accountId) {
      this.$router.push( { path : '/admin/account/edit/'+ accountId}, () => {} )
    },
    prev : function(){
      this.$router.push( { path : '/admin/menu' }, () => {} )
    }
  }
}
</script>