import _ from 'lodash'
import Messages from './message'
import Labels from './label'

var errorHandler = {

	errorsToArray:function(errors){
    let res = []
    _.forEach(errors, (value, key) => {
        res.push( (Labels[key] ? Labels[key] : key ) + " : " + Messages[value] )
    })
    return res;
  }
}

export default errorHandler