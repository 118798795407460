<template>
  <div id="support">
    <div class="header">
      <h1>アカウント更新</h1>
    </div>
    <div class="container">
      <main>
        <section>
          <c-validation-errors :errors-hash="errors" />
          <table>
            <tbody>
              <tr>
                <td>姓</td>
                <td><input type="text" placeholder="please input" maxlength="100" v-model="account.last_name" size="100" disabled/></td>
              </tr>
              <tr>
                <td>名</td>
                <td><input type="text" placeholder="please input" maxlength="100" v-model="account.first_name" size="100" disabled /></td>
              </tr>
              <tr>
                <td>ニックネーム</td>
                <td><input type="text" placeholder="please input" maxlength="100" v-model="account.nickname" size="100" disabled /></td>
              </tr>
              <tr>
                <td>mail</td>
                <td><input type="text" placeholder="please input" maxlength="100" v-model="account.mail" size="100" /></td>
              </tr>
            </tbody>
          </table>
          <button @click="update">更新</button>
          <button @click="prev">戻る</button>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'

export default {
  data(){
    return {
      accountId:null,
      account:{},
      errors:{}
    }
  },
  computed:{
    ...mapState(['loginFlg']),
  },
  mounted:function(){
    if(!this.loginFlg) {
      this.$toasted.info("no login! please log in.")
      this.$router.push( { path : '/' }, () => {} )
      return
    }
    this.accountId = Number(this.$route.params.accountId)
    this.get()
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
      ]),
    get : function () {
      this.axios.get(this.Global.url.api_accounts_show + this.accountId).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.account = res.data.account
          return
        }
        
      }).catch(error=>{ 
        this.$toasted.error('client error')
        this.setClientErrors({func:"edit.vue::get", params: {}, errorMessage:error})
      })
    },
    prev : function (){
      this.$router.push( { path : '/admin/accounts' }, () => {} )
    },
    update : function () {
      let params = {
        id:this.account.id,
        mail:this.account.mail
      }
      this.axios.post(this.Global.url.api_accounts_update, params)
      .then(res=>{
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success('success')
          this.$router.push( { path : '/admin/accounts' }, () => {} )
          return
        }

        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        } 
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"settings/accout-basic.vue::save", params: params, errorMessage:JSON.stringify(res.data)})

      }).catch(error=>{ 
        this.$toasted.error('client error')
        this.setClientErrors({func:"edit.vue::update", params: params, errorMessage:error})
      })
    }
  }
}
</script>