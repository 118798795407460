import Url from 'url'

let new_context_root = ""
let packs_static = "" 
let api_version = ""
var url = Url.parse(location.href)

var global = {
	entry_limit_per_page : 10,
	port_java      : "8080",             // javaポート
	port_rails     : "3000",             // railsポート
	host           : "tomotsuku.cotolike.jp",      // ホスト名(amadane.net or tomotsuku.cotolike.jp)
	ip_addr_local  : "localhost",        // ローカル環境IPアドレス
	ip_addr_test   : "153.127.193.154",  // テスト環境IPアドレス
	ip_addr_prod   : "59.106.222.126",   // 本番環境IPアドレス(amadane : 59.106.211.148 , tomotsuku ... : 59.106.222.126)
	ex_path        : "cotolike",          // テスト環境でドメインの後ろにつくプロジェクトを識別するパス(amadane or tomotsuku)
	context_root   : new_context_root,
	static_path    : packs_static + "/static",
	url            : {
		app_entries_feed          : "/app/entries/feed?limit=10&offset=0",
		//API フィード一欄取得
		api_feed_entries          : new_context_root + "/api" + api_version + "/entries/feeds",
		api_entries_search : new_context_root + "/api" + api_version + "/entries/search",
		api_entries_replies : new_context_root + "/api" + api_version + "/entries/replies",
		api_entries_tweet : new_context_root + "/api" + api_version + '/entries/tweet',
		api_entries_its_new : new_context_root + "/api" + api_version + "/entries/its_news",
		api_entries_notifications : new_context_root + "/api" + api_version + "/entries/notifications",
		api_entries_email : new_context_root + "/api" + api_version + '/entries/email',
		api_entries_reply_email : new_context_root + "/api" + api_version + '/entries/reply/email',
		api_entries_delete : new_context_root + "/api" + api_version + '/entries/',
		api_entries_side_menu : new_context_root + "/api" + api_version + '/entries/side_menu',
		api_keyword_search : new_context_root + "/api" + api_version + "/entries/keyword/search",

		//API アカウント情報取得
		api_accounts_all         : new_context_root + "/api" + api_version + "/accounts/all",
		api_accounts_show         : new_context_root + "/api" + api_version + "/accounts/",
		api_accounts_update         : new_context_root + "/api" + api_version + "/accounts/update",
		api_accounts_exists : new_context_root + "/api" + api_version + "/accounts/exists",
		//API 認証
		api_accounts_authenticate : new_context_root + "/api" + api_version + "/accounts/authenticate",
		api_accounts_sign_in : new_context_root + "/api" + api_version + "/accounts/sign_in",
		api_accounts_leave_out : new_context_root + "/api" + api_version + "/accounts/leave_out",
		api_accounts_reissue_password : new_context_root + "/api" + api_version + "/accounts/reissue/password",

		// api_accounts_temporary_create : new_context_root + "/api" + api_version + "/accounts/temporary/create",
		api_accounts_temporary_mail : new_context_root + "/api" + api_version + "/accounts/temporary/mail",
		api_temp_accounts_create : new_context_root + "/api" + api_version + "/accounts/temporary/create",
		api_temp_accounts_show : new_context_root + "/api" + api_version + "/temp_accounts/",
		//API オートログイン情報取得
		api_auto_logins_search    : new_context_root + "/api" + api_version + "/auto_logins/search",
		//API オートログイン情報登録
		api_auto_logins_create    : new_context_root + "/api" + api_version + "/auto_logins/create",
		//API オートログイン情報削除
		api_auto_logins_delete    : new_context_root + "/api" + api_version + "/auto_logins/delete",


		api_mst_topics_show : new_context_root + "/api" + api_version + "/mst_topics/",
		api_mst_topics_search : new_context_root + "/api" + api_version + "/mst_topics/search",
		api_topics_search_by_division : new_context_root + "/api" + api_version + "/mst_topics/search_by_division",
		api_mst_topics_delete : new_context_root + "/api" + api_version + "/mst_topics/delete",
		api_mst_topics_create : new_context_root + "/api" + api_version + "/mst_topics/create",
		api_mst_topics_update : new_context_root + "/api" + api_version + "/mst_topics/update",

		api_marks_search : new_context_root + "/api" + api_version + "/marks/search",
		api_marks_create : new_context_root + "/api" + api_version + "/marks/create",
		api_marks_delete : new_context_root + "/api" + api_version + "/marks/",

		api_account_follows_show : new_context_root + "/api" + api_version + "/account_follows/show",
		api_account_follows_search : new_context_root + "/api" + api_version + "/account_follows/search",
		api_account_follows_create : new_context_root + "/api" + api_version + "/account_follows/create",
		api_account_follows_update : new_context_root + "/api" + api_version + "/account_follows/update",
		api_account_follows_delete : new_context_root + "/api" + api_version + "/account_follows/delete",
		api_account_followers_search : new_context_root + "/api" + api_version + "/account_followers/search",

		api_account_rsss_search : new_context_root + "/api" + api_version + "/account_rsss/search",
		api_account_rsss_create : new_context_root + "/api" + api_version + "/account_rsss/create",
		api_account_rsss_update : new_context_root + "/api" + api_version + "/account_rsss/update",
		api_account_rsss_delete : new_context_root + "/api" + api_version + "/account_rsss/delete",
		// API サークル情報取得
		api_feed_circles          : new_context_root + "/api" + api_version + "/circles/",
		api_circles_show : new_context_root + "/api" + api_version + "/circles/",
		api_circles_create : new_context_root + "/api" + api_version + "/circles/create",
		api_circles_update : new_context_root + "/api" + api_version + "/circles/update",
		api_all_circles : new_context_root + "/api" + api_version + "/circles/search_all_circles",
		api_private_circles : new_context_root + "/api" + api_version + "/circles/search_private_circles",
		api_manage_circles : new_context_root + "/api" + api_version + "/circles/search_admin_circles",

		api_circle_topics_search : new_context_root + "/api" + api_version + "/circle_topics/search",
		api_circle_topics_create : new_context_root + "/api" + api_version + "/circle_topics/create",
		api_circle_topics_update : new_context_root + "/api" + api_version + "/circle_topics/update",
		api_circle_topics_delete : new_context_root + "/api" + api_version + "/circle_topics/delete",

		api_circle_follows_show : new_context_root + "/api" + api_version + "/circle_follows/show",
		api_circle_follows_search : new_context_root + "/api" + api_version + "/circle_follows/search",
		api_circle_follows_create : new_context_root + "/api" + api_version + "/circle_follows/create",
		api_circle_follows_update : new_context_root + "/api" + api_version + "/circle_follows/update",
		api_circle_follows_delete : new_context_root + "/api" + api_version + "/circle_follows/delete",
		api_circle_followers_search : new_context_root + "/api" + api_version + "/circle_followers/search",
	
		api_circle_invites_show : new_context_root + "/api" + api_version + "/circle_invites/show",
		api_circle_invites_search : new_context_root + "/api" + api_version + "/circle_invites/search",
		api_circle_invites_search_by_account : new_context_root + "/api" + api_version + "/circle_invites/search_by_account",
		api_circle_invites_update : new_context_root + "/api" + api_version + "/circle_invites/update",
		api_circle_invites_create : new_context_root + "/api" + api_version + "/circle_invites/create",
		api_circle_invites_delete : new_context_root + "/api" + api_version + "/circle_invites/delete",

		api_already_reads_bulk_insert : new_context_root + "/api" + api_version + "/already_reads/bulk_insert",
		api_already_marks_bulk_insert : new_context_root + "/api" + api_version + "/already_marks/bulk_insert",
		// API サークルメンバー一欄取得
		api_circle_members_search : new_context_root + "/api" + api_version + "/circle_members/search",
		api_circle_members_search_by_account_id : new_context_root + "/api" + api_version + "/circle_members/search_by_account_id",
		api_circle_members_create : new_context_root + "/api" + api_version + "/circle_members/create",
		api_circle_members_update : new_context_root + "/api" + api_version + "/circle_members/update",
		api_circle_members_delete : new_context_root + "/api" + api_version + "/circle_members/delete",
		// API サークル申請
		api_circle_requests_create : new_context_root + "/api" + api_version + "/circle_requests/create",
		api_circle_requests_show : new_context_root + "/api" + api_version + "/circle_requests/show",
		api_circle_requests_search_by_pending : new_context_root + "/api" + api_version + "/circle_requests/search_by_pending",
		api_circle_requests_search_by_disapprove : new_context_root + "/api" + api_version + "/circle_requests/search_by_disapprove",
		api_circle_requests_update : new_context_root + "/api" + api_version + "/circle_requests/update",

		api_circle_rsss_search : new_context_root + "/api" + api_version + "/circle_rsss/search",
		api_circle_rsss_create : new_context_root + "/api" + api_version + "/circle_rsss/create",
		api_circle_rsss_update : new_context_root + "/api" + api_version + "/circle_rsss/update",
		api_circle_rsss_delete : new_context_root + "/api" + api_version + "/circle_rsss/delete",
		
		// API RSS情報(プレビュー)取得
		api_rss_preview : new_context_root + "/api" + api_version + "/rss/preview",
		api_admin_mail : new_context_root + "/api" + api_version + "/admin/email",
		api_mst_groups_search : new_context_root + "/api" + api_version + "/mst_groups/search",
		api_mst_groups_create : new_context_root + "/api" + api_version + "/mst_groups/create",
		api_mst_groups_update : new_context_root + "/api" + api_version + "/mst_groups/update",
		api_mst_groups_delete : new_context_root + "/api" + api_version + "/mst_groups/delete",

		api_group_members_create_circle_group : new_context_root + "/api" + api_version + "/group_members/create_circle_group",
		api_group_members_update_circle_group : new_context_root + "/api" + api_version + "/group_members/update_circle_group",
		api_group_members_insert : new_context_root + "/api" + api_version + "/group_members/insert",
		api_group_members_update : new_context_root + "/api" + api_version + "/group_members/update",
		api_group_members_delete : new_context_root + "/api" + api_version + "/group_members/delete",
		api_group_members_delete_all : new_context_root + "/api" + api_version + "/group_members/delete_all",
	
		api_refer_circles_search : new_context_root + "/api" + api_version + "/refer_circles/search",
		api_refer_circles_create : new_context_root + "/api" + api_version + "/refer_circles/create",
		api_refer_circles_delete : new_context_root + "/api" + api_version + "/refer_circles/delete",

		api_client_errors_modify : new_context_root + "/api" + api_version + "/client_errors/modify",
	},
	session_key : "cotolike_session_key_" + url.host,
	
	api_status : {
		success : 1,
		validation_error : 2,
		system_error : 99,
	},
	
	entry_division_id : {
		square : 1,
		circle : 2,
		private_circle : 3,
		myspace : 4,
		keyword_search : 99
	},
	
	ON : 1,
	
	OFF : 0,
	
	hashtag_division_id : {
		home : 1,
		official : 2,
		free : 3,
		active : 4,
	},
	
	myspace_division_id : {
		home : 1,
		my : 2,
		reply : 3,
		mark : 4
	},
	
	header_navi_interval : 300000,
	
	limited_follows_followers_count : 7,

	notification_division_id : {
		entry : 1,
		mark : 2,
	},

	circle_mode : {
		public : 1,
		private : 2
	},

	mark_link_representative : 2,

	keyword_search_division_id : {
		entry : 1,
		account_name : 2,
		circle_name : 3,
		hashtag_name : 4,
		comment : 5,
		private_entry : 6,
		private_account_name : 7,
		private_circle_name : 8,
		private_hashtag_name : 9,
		private_comment : 10,
	},

	device_division_id : {
		pc : 1,
		mobile : 2
	},

	circle_invite_status : {
		pending : 0,
		ok : 1,
		ng : 2,
	},
	circle_request_approval_status : {
		pending : 0,
		disapprove : 1,
		approve : 2,
	},
	notification_limit_per_page : 5,
	circles_division_id : {
		all:1,
		private_circle:2,
		manage:3
	},
	limited_circle_members_count : 5,
	settings_tab_id : {
		basic : 1,
		mail : 2,
		leave_out : 3,
		circle : 4,
		members : 5,
		hashtags : 6
	},
	vue_mention_limit : 30,

	updaload_thumbnail_max_size : 250,
	updaload_image_max_size : 500,
	updaload_account_image_max_size : 150,
	display_image_max_size : 300,
	limited_comment_count : 3,
	entry_display_mode : {
		time_line: 1,
		detail: 2,
	}
}

export default global
	


