<template>
  <div>
    <div class="setting-rss">
      <h4>RSS設定(ブログやnoteの更新情報を自動投稿)</h4>
      <div>
        <div class="item">
          <input type="text" placeholder="https://xxx/xxx" v-model="url" @change="preview(url, null)"><span class="focusborder"></span>
        </div>
        <div class="item">
          <input type="text" placeholder="タイトル" v-model="title"><span class="focusborder"></span>
        </div>      
        <div class="item">
          <textarea placeholder="自動投稿時のメッセージ（例：note更新しました、など）" v-model="message"></textarea><span class="focusborder"></span>
        </div>
        <div>
          <p>公式な話題</p>
          <select name="b" v-model="publicHashtagId" @change="changePublicHashtag(null, publicHashtagId)">
            <option value=""></option>
            <option v-for="(hashtag, index) in publicHashtags" :key="index" :value="hashtag.id">{{hashtag.name}}</option>
          </select>
        </div>
        <div>
          <p>自由な話題</p>
          <select name="a" v-model="freeHashtagId" @change="changeFreeHashtag(null, freeHashtagId)">
            <option value=""></option>
            <option v-for="(hashtag, index) in freeHashtags" :key="index" :value="hashtag.id">{{hashtag.name}}</option>
          </select>
        </div>
      </div>

      <button @click="create">+ RSSを追加</button>
    </div>
    <div v-for="(rss, index) in rsss" :key="index" :class="rss._deleteFlg ? 'setting-rss-delete' : 'setting-rss'">
      <h5 v-show="rss._deleteFlg">※保存ボタンを押すと反映されます</h5>
      <div>
        <div v-show="!rss._deleteFlg" @click="deletefromRsses(rss.id, index)">×</div>
        <div class="item">
          <input type="text" placeholder="https://xxx/xxx" v-model="rss.url" @change="preview(rss.url, rss._index)"><span class="focusborder"></span>
        </div>
        <div class="item">
          <input type="text" placeholder="タイトル" v-model="rss.title"><span class="focusborder"></span>
        </div>

        <div class="item">
          <textarea placeholder="自動投稿時のメッセージ（例：note更新しました、など）" v-model="rss.message"></textarea><span class="focusborder"></span>
        </div>
        <div>
          <p>公式な話題</p>
          <select name="b" v-model="rss._publicHashtagId" @change="changePublicHashtag(rss._index, rss._publicHashtagId)">
            <option value=""></option>
            <option v-for="(publicHashtag, index) in publicHashtags" :key="index" :value="publicHashtag.id">{{publicHashtag.name}}</option>
          </select>
        </div>
        <div>
          <p>自由な話題</p>
          <select name="a"  v-model="rss._freeHashtagId" @change="changeFreeHashtag(rss._index, rss._freeHashtagId)">
            <option value=""></option>
            <option v-for="(freeHashtag, index) in freeHashtags" :key="index" :value="freeHashtag.id">{{freeHashtag.name}}</option>
          </select>
        </div>
      </div>

    </div>
    <div class="setting-rss">
      <div class="action-overlay" v-show="overlay">
        <div class="container">
          <div class="item">
            <p>{{title}}</p>
            <p>{{message}}</p>
            <ul>
              <li v-for="(article, index) in articles" :key="index" v-html="article"></li>
            </ul>
          </div>
          <div class="action">
            <button class="cancel" @click="toggleOverlay">閉じる</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  props:{
    circleId : {
      type: Number,
      required:true
    },
    saveFlg : {
      type: Boolean,
      required:true
    }
  },
  data : function(){
    return {
      publicHashtags  : [],
      freeHashtags    : [],
      url             : null,
      title           : null,
      message         : null,
      mstHashtagId    : null,
      publicHashtagId : null,
      freeHashtagId   : null,
      rsss            : null,
      overlay         : false,
      articles        : null
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
      'circleEditCircleMembers',
    ]),
  },
  watch:{
    circleId : function () {
      this.get()
    },
    saveFlg : function (newValue) {
      if(newValue) {
        this.save()
        this.$emit('toggle-save-flg')
      }
    }
  },
  created: function () {
    this.getPublicHashtags()
    this.getFreeHashtags()
  },
  mounted: function () {
    if (this.circleId != null) this.get()
  },
  methods : {
    ...mapMutations([
      'setClientErrors',
      // 'setCircleEditCircleMembers',
      // 'concatCircleEditCircleMembers',
      // 'setCircleEditCircleMembersReloadFlg'
    ]),
    get : function () {
      let params = {
        circle_id : this.circleId
      }
      this.axios.post(this.Global.url.api_circle_rsss_search, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          let i = 0
          this.rsss = _.reverse(_.forEach(res.data.circle_rsss, (row) => {
            row._defaultUrl = row.url
            row._publicHashtagId = row.mst_topic_official == true ? row.mst_topic_id : null
            row._freeHashtagId = row.mst_topic_official == false ? row.mst_topic_id : null
            row._index = i
            row._invalidUrlFlg = false
            row._deleteFlg = false
            i++
          }))
          return
        }
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::get", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::get", params: params, errorMessage:error})
      })
    },
    getPublicHashtags : function () {
      let params = {
        official:true
      }
      this.axios.post(this.Global.url.api_mst_topics_search, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.publicHashtags = res.data.hashtags
          return
        }
        this.setClientErrors({func:"circle/edit/rss.vue::getPublicHashtags", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::getPublicHashtags", params: params, errorMessage:error})
      })
    },
    getFreeHashtags : function () {
      let params = {
        official:false
      }
      this.axios.post(this.Global.url.api_mst_topics_search, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.freeHashtags = res.data.hashtags
          return
        }
        this.setClientErrors({func:"circle/edit/rss.vue::getFreeHashtags", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::getFreeHashtags", params: params, errorMessage:error})
      })
    },
    changePublicHashtag : function (index, hashtagId) {
      if (index != null) {
        this.rsss = _.map(this.rsss, (rss) => {
          if (rss._index == index) {
            rss.mst_topic_id = hashtagId
            rss._publicHashtagId = hashtagId
            rss._freeHashtagId = null
          }
          return rss
        })
        console.dir(this.rsss)
        return
      }
      this.mstHashtagId = hashtagId
      this.freeTopicId = null
    },
    changeFreeHashtag : function (index, hashtagId) {
      if (index != null) {
        this.rsss = _.map(this.rsss, (rss) => {
          if (rss._index == index) {
            rss.mst_topic_id = hashtagId
            rss._publicHashtagId = null
            rss._freeHashtagId = hashtagId
          }
          return rss
        })
        console.dir(this.rsss)
        return
      }

      this.mstHashtagId = hashtagId
      this.publicHashtagId = null
    },
    create : function () {
      if (this.rsss.length == 5) {
        this.$toasted.error("これ以上登録できません")
        return
      }
      this.rsss.unshift({
        id : null,
        circle_id : this.circleId,
        url : this.url,
        title : this.title,
        message : this.message,
        mst_topic_id : this.mstHashtagId,
        _defaultUrl: this.url,
        _publicHashtagId : this.publicHashtagId,
        _freeHashtagId : this.freeHashtagId,
        _index : this.rsss.length,
        _invalidUrlFlg:false,
        _deleteFlg:false
      })
      this.url             = null
      this.title           = null
      this.message         = null
      this.mstHashtagId    = null
      this.publicHashtagId = null
      this.freeHashtagId   = null
    },
    deletefromRsses: function (id, index) {

      if (id === null) {
        this.rsss.splice(index, 1)
        return
      }
      if (!confirm("削除します。よろしいですか？")) {
        return
      }
      let rsss = _.map(this.rsss, elm => {
        // if (id == null) return
        if (elm.id === id) {
          elm._deleteFlg = true
        }
        return elm
      })

      this.rsss = rsss
    },
    preview : function (url, index) {
      if (!url) return
      if (_.filter(this.rsss, row => (index == null || row._index != index) && row.url === url).length > 0) {
        this.$toasted.error("既に登録されてるURLです")

        if (index == null) {
          this.url = null
          return
        }

        _.forEach(this.rsss, row => {
          if (row._index == index) {
            row.url = row._defaultUrl
          }
        })
        return
      }

      let params = { url : url }
      this.axios.post(this.Global.url.api_rss_preview, params)
      .then( res => {
        if (res.data.status === this.Global.api_status.success) {
          if (res.data.articles.length > 0) {
            this.articles = _.slice(res.data.articles, 0, 1)
            this.toggleOverlay()
          } else {
            if (index == null) this.$toasted.error("データを取得できませんでした。")
            if (index != null) this.$toasted.error("データを取得できませんでした。urlが不正のまま保存すると削除されます。")
          }
          if (index != null) {
            // invalidUrlFlgがtrueの場合は登録しない
            _.forEach(this.rsss, row => {
              if (row._index == index) {
                row._invalidUrlFlg = res.data.articles.length > 0 ? false : true
              }
            })
          }
          return
        }
        if (index == null) {
          this.$toasted.error("urlは正しいですか？？")
          this.url = null
        }
        if (index != null) {
          this.$toasted.error("urlは正しいですか？？urlが不正のまま保存すると削除されます。")
          // invalidUrlFlgがtrueの場合は登録しない
          _.forEach(this.rsss, row => {
            if (row._index == index) {
              row._invalidUrlFlg = true
            }
          })
        }
        return
      })
      .catch( error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::preview", params: params, errorMessage:error})
      })
    },
    save : function () {
      _.forEach(this.rsss, (row) => {
        if (row._deleteFlg || row._invalidUrlFlg) {
          if (row.id != null) this.delete(row)
        } else if (row.id == null) {
          this.insert(row)
        } else {
          this.update(row)
        }
      })
    },
    insert : function (row) {
      this.axios.post( this.Global.url.api_circle_rsss_create, row )
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          row.id = res.data.circle_rss.id
          return
        }
        this.setClientErrors({func:"circle/edit/rss.vue::insert", params: row, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::insert", params: row, errorMessage:error})
      })
    },
    update : function (row) {
      this.axios.post( this.Global.url.api_circle_rsss_update, row )
      .then(res => {
        if (res.data.status === this.Global.api_status.success) return

        this.setClientErrors({func:"circle/edit/rss.vue::update", params: row, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::update", params: row, errorMessage:error})
      })
    },
    delete : function (row) {
      this.axios.delete( this.Global.url.api_circle_rsss_delete + "/" + row.id )
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.rsss = _.filter(this.rsss, (rss) => { return rss.id != row.id })
          return
        }
        this.setClientErrors({func:"circle/edit/rss.vue::delete", params: row, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/rss.vue::delete", params: row, errorMessage:error})
      })
    },
    toggleOverlay : function () {
      this.overlay = !this.overlay
    } 
  }
}
</script>