<template>
  <div v-if="!Boolean(followFlg)" @click="follow" class="action">フォロー</div>
  <div v-else-if="Boolean(loginFlg)" class="unfollow"><span @click="unFollow">フォロー解除</span><span>フォロー中</span></div>
  <div v-else />
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import account_follows from '../../js/api/account-follows'

export default {
  props : {
    followAccountId : {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      followFlg : false,
      followId : null,
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
      'accountFollows',
    ])
  },
  watch: {
    accountFollows (newVals) {
      this.initState(newVals)
    }
  },
  created : function () {
    this.initState(this.accountFollows)
  },
  methods : {
    ...mapMutations([
      'setAccountFollows',
      'setClientErrors',
    ]),
    initState : function (accountFollows) {
      let target = _.filter(accountFollows, r => r.follow_account_id === this.followAccountId)
      this.followFlg = target.length > 0
      if (target.length > 0) this.followId = target[0].id
    },
    follow : function () {
      let ret = account_follows.create(this.account.id, this.followAccountId)
      ret.then(res => {
        if(res.data.status == this.Global.api_status.success){
          this.followId = res.data.account_follow.id
          this.followFlg = true
          this.$toasted.success("success")
          let newFollows = this.accountFollows.concat([res.data.account_follow])
          this.setAccountFollows(newFollows)
          return
        }
        // console.dir(res.data.errors)
        // this.$toasted.error("failed")
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({
          func:"common/label-account-follow.vue::follow",
          params: {
            account_id : this.account.id,
            follow_account_id : this.followAccountId,
          },
          errorMessage:error
        })
      })
    },

    unFollow : function () {
      let ret = account_follows.delete(this.followId)
      ret.then(res => {
        if (res.data.status === this.Global.api_status.success) {
          let newFollows = _.filter(this.accountFollows, r => r.id !== this.followId)
          this.setAccountFollows(newFollows)
          this.followId = null
          this.followFlg = false
          this.$toasted.success("success")
          return
        }
        // console.dir(res.data.errors)
        // this.$toasted.error("failed")
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/label-account-follow.vue::unFollow", params: {id:this.followId}, errorMessage:error})
      })
    }
  }
}
</script>