<template>
  <div class="send-list">
    <h4>宛先リストの管理</h4>
    <div>
      <div>
        <h5>新規作成</h5>
        <div class="item">
          <input type="text" placeholder="宛先リスト名を入力してください" v-model="groupName"><span class="focusborder"></span>
        </div>
        <ul class="member-tag-ul">
          <li class="member-tag" v-for="(account, index) in selectedAccounts" :key="index" @mousedown="deleteFromNewGroup(account)">{{account.last_name + account.first_name}}</li>
        </ul>
        <button class="add-left" @click="toggleMembersOverlay">+ メンバーを選ぶ</button>
        <button class="add-list" @click="create" v-show=" groupName != null && selectedAccounts.length > 0">新規作成する</button>
      </div>
      <div>
        <h5>既存リスト</h5>
        <div v-for="(mstGroup, index) in filterGroups" :key="index">
          <div class="item">
            <input type="text" placeholder="リストA西粟倉" v-model="mstGroup.name"><span class="focusborder"></span>
            <ul class="member-tag-ul">
              <li class="member-tag" v-for="(account, accountIndex) in mstGroup.group_members" :key="accountIndex" @mousedown="deleteFromGroup(index, account.id)">{{account.last_name + account.first_name}}</li>
            </ul>
          </div>
          <button @click="editMemeber(mstGroup, index)">+ メンバーを編集</button>
        </div>
      </div>
      <div class="action-overlay" v-show="membersOverlay">
        <div class="container">
          <div class="item">
            <!-- <input type="text" placeholder="名前"><span class="focusborder"></span> -->
            <mentionable
              :keys="keys"
              :items="items"
              :limit="mentionLimit"
              insert-space>
              <textarea placeholder="@名前を入力してください" v-model="text"></textarea>
              <template #no-result>
                <div class="dim">
                  No result
                </div>
              </template>
              <template #item-@="{ item }">
                <div class="destination">
                  {{ item.lastName + item.firstName }}
                  <span class="dim">
                    {{ item.nickname }}
                  </span>
                </div>
              </template>
            </mentionable>
          </div>
          <div class="action">
            <button class="cancel" @click="cancel">キャンセル</button>
            <button class="confirm" @click="set">選択</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import _ from 'lodash'
import mentionUtils from '../../../../js/common/entry/MentionUtils'

export default {
  props:{
    circleId : {
      type: Number,
      required:true
    },
    saveFlg : {
      type: Boolean,
      required:true
    }
  },
  data : function(){
    return {
      groupName : null,
      mstGroups   : [],
      membersOverlay : false,
      // 以降オーバーレイのstate
      mstGroupIndex     : null,
      text           : null,
      keys           : ['@'],
      mentionLimit   : this.Global.vue_mention_limit,
      accounts       : null,
      selectedAccounts : [],
      items          : [],
    }
  },
  computed : {
    ...mapState([
      'circleEditCircleMembers',
    ]),
    filterGroups : function () {
      return _.filter(this.mstGroups, row => { return !row._deleteFlg })
    }
  },
  watch:{
    saveFlg : function (newValue) {
      if (newValue) {
        this.save()
        this.$emit('toggle-save-flg')
      }
    },
    selectedAccounts : function () {
      let _filteredAccounts = this.filterAccounts()
      this.items = _.map(_filteredAccounts, (row) => { return mentionUtils.getMentionStates(row) })
    },
    circleEditCircleMembers (newValue) {

      //メンバーが削除された場合に選択中のアカウント配列からも除去
      this.selectedAccounts = _.filter(this.selectedAccounts, (row) => {
        let _match = false
        _.forEach(newValue, (circleMember) => {
          if(row.id == circleMember.account_id && !circleMember.withdrawFlg) {
            _match = true
          }
        })
        return _match
      })

      // 既存グループ内も除去
      this.mstGroups = _.map(this.mstGroups, (mstGroup) => {
        mstGroup.group_members = _.filter(mstGroup.group_members, (groupMember) => {
          let _match = false

          _.forEach(newValue, (circleMember) => {

            if(groupMember.id == circleMember.account_id && !circleMember.withdrawFlg) {
              _match = true
            }
          })
          return _match
        })
        if (mstGroup.group_members.length == 0) mstGroup._deleteFlg = true
        return mstGroup
      })

      this.createMentionAccounts()
    }
  },
  created: function () {
    this.getMstGroups()
  },
  mounted : function () {
    _.forEach(document.getElementsByClassName("mentionable"), (row)=>{row.removeAttribute('style')})
  },
  methods : {
    ...mapMutations([
      'setClientErrors',
      'setCircleEditCircleMembers',
    ]),
    deleteFromNewGroup : function (account) {
      this.selectedAccounts = _.filter(this.selectedAccounts, (row) => {  return row.id != account.id })

      this.accounts = _.forEach(this.accounts, (row) => { if (row.id == account.id) row.selectedFlg = false })
    },
    deleteFromGroup : function (index, deleteAccountId) {
      let i = 0
      this.mstGroups = _.map(this.mstGroups, (mstGroup)=>{
        if (i == index) {
            if (mstGroup.group_members.length == 1) {
              this.$toasted.error("メンバーが最後の１人のためグループを削除します")
              mstGroup._deleteFlg = true
            }
            mstGroup.group_members = _.filter(mstGroup.group_members, (account) => { return account.id != deleteAccountId })
        }
        i++
        return mstGroup
      })

      this.accounts = _.forEach(this.accounts, (row) => { if (row.id == deleteAccountId) row.selectedFlg = false })
    },
    toggleMembersOverlay : function () {
      this.membersOverlay = !this.membersOverlay
    },
    editMemeber : function (mstGroup, index) {
      // this.mstGroupIndex = mstGroup.id
      this.mstGroupIndex = index
      mstGroup.index = index
      
      this.selectedAccounts = mstGroup.group_members

      let _ids = {}
      _.forEach(this.selectedAccounts, (row) => { _ids[row.id] = row.id })

      this.accounts = _.forEach(this.accounts, (row) => {
        if (_ids[row.id]) row.selectedFlg = true
      })

      this.toggleMembersOverlay()
    },
    getMstGroups : function () {
      let params = { circle_id : this.circleId }

      this.axios.post(this.Global.url.api_mst_groups_search, params).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.mstGroups = _.reverse(res.data.mst_groups)
          return
        }
        this.$toasted.error("error occured!")
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/groups.vue::getMstGroups", params: params, errorMessage:error})
      })
    },
    createMentionAccounts : function () {
      let _accounts = _.map(_.filter(this.circleEditCircleMembers, member=>{return !member.withdrawFlg}), (row) => {
        return {
          id:row.account_id,
          last_name:row.account_last_name,
          first_name:row.account_first_name,
          nickname:row.account_nickname,
        }
      })
      this.accounts = this.setDispFlg(_accounts)
      this.items = _.map(this.accounts, (row) => { return mentionUtils.getMentionStates(row) })
    },
    setDispFlg : function (accounts) {
      let _selectedAccountIds = {}
      _.map(this.selectedAccounts, (row) => {
        return _selectedAccountIds[row.account_id] = row.account_id
      })
      return _.map(accounts, (row) => {
        row.selectedFlg = _selectedAccountIds[row.id] ? true : false
        return row
      })
    },
    filterAccounts: function () {
      return _.filter(this.accounts, (row) => { return !row.selectedFlg})
    },
    cancel : function () {
      this.toggleMembersOverlay()
      this.text          = null
      this.mstGroupIndex = null
    },
    set : function () {
      if (this.mstGroupIndex != null) {
        this.mstGroups = _.map(this.mstGroups, (row) => {
          if (row.index == this.mstGroupIndex) {
            row.group_members = row.group_members.concat(this.getAccountsFromText(this.text, this.accounts))
            row.index = null
          }
          return row
        })
      } else {
        this.selectedAccounts = this.selectedAccounts.concat(this.getAccountsFromText(this.text, this.accounts))
      }
      this.text = null
      this.mstGroupIndex = null
      this.toggleMembersOverlay()
    },
    getAccountsFromText : function (text, accounts) {
      let _selectedAccounts = []
      _.map(accounts, (account) => {
        let key = '@' + account.last_name + account.first_name
        if ( text.indexOf(key) != -1 ) {
          account.selectedFlg = true

          _selectedAccounts.push({
            id : account.id,
            last_name : account.last_name,
            first_name : account.first_name,
            nickname : account.nickname
          })
        }
      })
      return _selectedAccounts
    },
    create : function () {

      let duplicateNames = _.filter(this.mstGroups, (row) => { return row.name == this.groupName })

      if (duplicateNames.length > 0) {
        this.$toasted.info("グループ名称重複します")
        return
      }

      this.mstGroups.unshift({
        id            : null,
        name          : this.groupName,
        group_members : this.selectedAccounts
      })
      
      this.groupName = null
      this.accounts = _.forEach(this.accounts, (row) => { if (row.selectedFlg) row.selectedFlg = false})
      this.selectedAccounts = []
    },
     save : function () {
      _.filter(this.mstGroups, (row) => {
        if (row._deleteFlg) {
          // console.log("delete")
          if (row.id == null) return
          this.deleteGroupMembers(row.id)
          this.deleteMstGroup(row.id)
          return
        }
        if (row.id == null) {
          // console.log("insert")
          //group_member
          //mst_group
          this.insertGroupMembers(row)
          return
        }
        // console.log("update")
        //group_member
        //mst_group
        this.updateGroupMembers(row)
      })
    },

    deleteGroupMembers : function (mst_group_id) {
      this.axios.delete(this.Global.url.api_group_members_delete_all+"/"+mst_group_id)
      .then(res=>{
        if (res.data.status === this.Global.api_status.success) {
          // this.$toasted.info('group delete')
          return
        }
        this.setClientErrors({func:"circle/edit/groups.vue::deleteGroupMembers", params: {}, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/groups.vue::deleteGroupMembers", params: {}, errorMessage:error})
      })
    },

    deleteMstGroup : function (id) {
      let params = {}
      this.axios.delete(this.Global.url.api_mst_groups_delete+"/"+id)
      .then(res => {
        if (res.data.status != this.Global.api_status.success) {
          console.dir(res.data.errors)
          this.setClientErrors({func:"circle/edit/groups.vue::deleteMstGroup", params: params, errorMessage:"mst_group削除失敗 : " + JSON.stringify(res.data.errors)})
          return
        }
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/groups.vue::delete", params: params, errorMessage:error})
      })
    },
    insertGroupMembers : function (row) {
      let params = {
        name : row.name,
        circle_id : this.circleId,
        account_ids : JSON.stringify(_.map(row.group_members, (row) => { return row.id})),
      }
      this.axios.post(this.Global.url.api_group_members_create_circle_group, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          row.id=res.data.mst_group.id
          return
        }
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/groups.vue::insertGroupMembers", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/groups.vue::insertGroupMembers", params: params, errorMessage:error})
      })

    },

    // insertGroupMember : function (mst_group_id, account_id) {
    //   let params = {
    //     mst_group_id : mst_group_id,
    //     account_id : account_id
    //   }
    //   this.axios.post(this.Global.url.api_group_members_insert, params)
    //   .then(res=>{
    //     if (res.data.status === this.Global.api_status.success) {
    //       this.$toasted.info('group_members insert')
    //       return
    //     }
    //     this.setClientErrors({func:"circle/edit/groups.vue::insertGroupMember", params: params, errorMessage:JSON.stringify(res.data)})
    //   })
    //   .catch(error=>{
    //     this.$toasted.error('client error')
    //     this.setClientErrors({func:"circle/edit/groups.vue::insertGroupMember", params: params, errorMessage:error})
    //   })
    // },
    updateGroupMembers : function (row) {
      let params = {
        mst_group_id : row.id,
        name : row.name,
        circle_id : this.circleId,
        account_ids : JSON.stringify(_.map(row.group_members, (row) => { return row.id})),
      }
      this.axios.post(this.Global.url.api_group_members_update_circle_group, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          return
        }
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/groups.vue::updateGroupMembers", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/groups.vue::updateGroupMembers", params: params, errorMessage:error})
      })    
    },
   

  },
}
</script>