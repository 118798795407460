<template>
  <ul>
    <li><router-link to="/circle/approval/4">サークル招待  承認</router-link></li>
  </ul>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed:{
    ...mapState([
      'loginFlg',
      'account'
    ])
  },
  watch : {
    account : function (newValue) {
      if (newValue) {
        this.$router.push({ path : '/myspace/' + newValue.id }, () => {})
        return
      }
    },
    loginFlg : function (val) {
      if (val) {
        this.$router.push({ path : '/myspace/' + this.account.id }, () => {})
      }
    }
  },
  mounted:function () {
    this.$router.push({ path : '/square' }, () => {})
  }
}
</script>
