<template>
  <div class="setting-group">
    <h4>{{circleType=='public'?'公開' : '非公開'}}サークルに追加する話題</h4>
    <div class="setting-topic">
      <div class="topic">
        <div>
          <div class="item">
            <input type="text" placeholder="話題を入力" v-model="hashtagName"><span class="focusborder"></span>
          </div>
        </div>
        <div class="detail">
          <div>
            <toggle-button 
              v-model="hashtagType"
              :sync="true"
              :width="90"
              :labels="{checked: '公式な話題', unchecked: '自由な話題'}"></toggle-button>
            <!-- <input type="radio" :name="circleType + '-new-topic-setting-open'" :id="circleType + '-new-topic-open-1'" value=true v-model="hashtagType">
            <label class="radio" :for="circleType + '-new-topic-open-1'">公式な話題</label>
            <input type="radio" :name="circleType + '-new-topic-setting-open'" :id="circleType + '-new-topic-open-2'" value=false v-model="hashtagType">
            <label class="radio" :for="circleType + '-new-topic-open-2'">自由な話題</label> -->
          </div>
        </div>
      </div>
      <div class="add-wrap">
        <button class="add add-left" v-if="circleType=='public'" @click="toggleHashtagOverlay">+ 広場の話題を追加</button>
        <button class="add" @click="add">+ 話題を追加</button>              
      </div>
      <h5>話題リスト</h5>
      <draggable v-model="hashtags"
          :options="{ animation: 200 }"
          @end="dragged" >
        <div class="topics" v-for="(hashtag, index) in filterHashtags" :key="index">
          <div>
            <div class="item" v-if="!hashtag.square_flg">
              <input type="text" placeholder="話題を入力" v-model="hashtag.mst_topic_name" /><span class="focusborder"></span>
            </div>
            <div class="item" v-if="hashtag.square_flg">
              <input type="text" placeholder="話題を入力" v-model="hashtag.mst_topic_name" disabled /><span class="focusborder"></span>
            </div>
          </div>
          <div class="detail">
            <div v-if="!hashtag.square_flg">
              <toggle-button 
                v-model="hashtag.official"
                :sync="true"
                :width="90"
                :labels="{checked: '公式な話題', unchecked: '自由な話題'}"></toggle-button>
              <!-- <input type="radio" :name="circleType + '-topic-setting-open-' + index" value=true :id="circleType + '-topic-open-' + index" v-model="hashtag.official">
              <label class="radio" :for="circleType + '-topic-open-' + index">公式な話題</label>
              <input type="radio" :name="circleType + '-topic-setting-open-' + index" value=false :id="circleType + '-topic-open-' + index" v-model="hashtag.official">
              <label class="radio" :for="circleType + '-topic-open-' + index">自由な話題</label> -->
            </div><!-- <input type="text" placeholder="ソート番号を入力" size="2" v-if="!hashtag.square_flg" v-model="hashtag.sort" /> --><span>発言数{{hashtag.entries_count}}件</span><span class="delete" style="cursor:pointer" @mousedown="deleteStateHashtags(hashtag._index)">削除</span>
            
          </div>
        </div>
      </draggable>
    </div>
    <div v-if="circleType">
      <div class="action-overlay" v-show="hashtagOverlay">
        <div class="container">
          <p>ひろばの話題</p>
          <div class="item">
            
            <mentionable
              :keys="keys"
              :items="items"
              :limit="mentionLimit"
              insert-space>
              <textarea placeholder="#ハッシュタグ名を入力してください" v-model="text"></textarea>
              <template #no-result>
                <div class="dim">
                  No result
                </div>
              </template>
              <template #item-@="{ item }">
                <div class="issue">
                  {{ item.name }}
                  <span class="number">
                    {{item.subName}}
                  </span>
                </div>
              </template>
            </mentionable>
          </div>
          <div class="action">
            <button class="cancel" @click="toggleHashtagOverlay">キャンセル</button>
            <button class="confirm" @click="set">設定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapMutations} from 'vuex'
import _ from 'lodash'
import mentionUtils from '../../../../js/common/entry/MentionUtils'

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import draggable from 'vuedraggable'
// Vue.use(draggable)

export default {
  props:{
    circleId : {
      type: Number,
      required:true
    },
    circleType : {
      type: String,
      required:true
    },
    saveFlg : {
      type: Boolean,
      required:true
    }
  },
  data : function(){
    return {
      hashtags:[],
      hashtagOverlay : false,
      hashtagId      : null,
      hashtagName    : null,
      hashtagType    : true,
      squareHashtags : null,
      text           : null,
      keys           : ['#'],
      mentionLimit   : this.Global.vue_mention_limit,
      accounts       : null,
      items          : [],
    }
  },
  computed : {
    filterHashtags : function () {
      return _.filter(this.hashtags, (row) => { return !row._deleteFlg})
    }
  },
  watch:{
    circleId : function () {
      this.get()
    },
    saveFlg : function (newValue) {
      if(newValue) {
        this.save()
        this.$emit('toggle-save-flg')
      }
    },
    hashtags : function () {
      this.filterMentionItems()
    },
    squareHashtags : function () {
      this.filterMentionItems()
    },
  },
  mounted:function(){
    this.get()
    this.getSquareHashtags()
  },
  methods : {
    ...mapMutations([
      'setClientErrors',
    ]),

    toggleHashtagOverlay : function () {
      this.hashtagOverlay = !this.hashtagOverlay
      this.text = null
    },
    set : function () {
      _.map(this.squareHashtags, (row) => {
        let key = row.name + ':' + row.id
        if ( this.text.indexOf(key) != -1 ) {
          this.hashtags.unshift({
            id              : null,
            circle_id       : this.circleId,
            mst_topic_id    : row.id,
            official_circle : this.circleType == 'public' ? true : false,
            sort            : null,
            mst_topic_name  : row.name,
            official        : row.official,
            square_flg      : true,
            _index          : this.hashtags.length
          })

          row._selectedFlg = true
        }
      })
      
      this.toggleHashtagOverlay()
    },
    add : function () {
      if(!this.hashtagName) return
      let dup = _.filter(this.hashtags, (row) => row.mst_topic_name == this.hashtagName)
      if (dup.length > 0) {
        this.$toasted.error('既に使用されています')
        return
      }
      this.hashtags.unshift({
        id              : null,
        circle_id       : this.circleId,
        mst_topic_id    : null,
        official_circle : this.circleType == 'public' ? true : false,
        sort            : null,
        mst_topic_name  : this.hashtagName,
        official        : this.hashtagType,
        square_flg      : false,
        _index          : this.hashtags.length
      })

      this.hashtagName = null
      this.hashtagType = true
    },
    get : function () {
      // alert("public-hashtags : get")
      let params = {
        circle_id : this.circleId,
        official_circle : this.circleType == 'public' ? true : false

      }
      this.axios.post(this.Global.url.api_circle_topics_search, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          let i = 0
          this.hashtags = _.sortBy(_.forEach(res.data.hashtags, (row) => {
            row._index = i
            row._deleteFlg = false
            i++
          }), "sort")
          
          return
        }
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::get", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::get", params: params, errorMessage:error})
      })

    },
    deleteStateHashtags : function (index) {
      let _mst_topic_id = null
      this.hashtags = _.map(this.hashtags, (row) => {
        if (row._index === index) {
          row._deleteFlg = true
          _mst_topic_id = row.mst_topic_id
        }
        return row
      })

      this.squareHashtags = _.map(this.squareHashtags, (row) => {
        if (row.id == _mst_topic_id) {
          row._selectedFlg = false
        }
        return row
      })
    },
    save : function () {
      // alert("public-hashtags : call save")
      let _sort=1
      _.forEach(this.hashtags, (row) => {
        
        row.sort = _sort
        if (row._deleteFlg) {
          // console.log("delete")
          // console.log(row.square_flg)
          if (row.square_flg) {
            // alert("delete : square case")
            // delete circle_topic
            this.deleteCircleTopic(row)
          } else {
            this.deleteCircleTopic(row)
            this.deleteMstTopic(row)
          }
        } else if (row.id == null) {
          // console.log("insert")
          // console.log(row.id)
          // console.log(row.square_flg)
          if (row.square_flg) {
            // circle_topicへinsert
            this.insert(row)
          } else {
            // alert("insert : origin case")
            // mst_topicへinsert
            // circle_topicへinsert
            this.insert(row)
          }
          _sort++
        } else {
          // console.log("update")
          if (!row.square_flg) {
            // alert("update : origin case")
            // update mst_topic
            // update circle_topic
            this.updateMstTopic(row)
          }
          this.updateCircleTopic(row)
          _sort++
        }
      })
      this.hashtags = _.filter(this.hashtags, (row) => { return !row._deleteFlg})
    },
    deleteCircleTopic : function (row) {
      this.axios.delete(this.Global.url.api_circle_topics_delete + "/" + row.id)
      .then(res => {
        if (res.data.status != this.Global.api_status.success) {
          this.setClientErrors({func:"circle/edit/circle-hashtags.vue::deleteCircleTopic", params: {}, errorMessage:JSON.stringify(res.data)})
          return
        }
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::deleteCircleTopic", params: {}, errorMessage:error})
      })
    },
    insert : function (row) {
      let params = {
        mst_topic_id : row.mst_topic_id,
        circle_id : row.circle_id,
        official_circle : row.official_circle,
        sort : row.sort,
        name : row.mst_topic_name,
        official : row.official
      }
      this.axios.post(this.Global.url.api_circle_topics_create, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          row.id = res.data.circle_topic.id
          row.mst_topic_id = res.data.circle_topic.mst_topic_id
          return
        }
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::get", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::get", params: params, errorMessage:error})
      })
    },
    updateCircleTopic : function (row) {
      let params = row
      this.axios.post(this.Global.url.api_circle_topics_update, params)
      .then(res => {
        if (res.data.status != this.Global.api_status.success) {
          this.setClientErrors({func:"circle/edit/circle-hashtags.vue::updateCircleTopic", params: params, errorMessage:JSON.stringify(res.data)})
        }
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::updateCircleTopic", params: params, errorMessage:error})
      })
    },
    deleteMstTopic : function (row) {
      this.axios.delete(this.Global.url.api_mst_topics_delete + "/" + row.mst_topic_id)
      .then(res => {
        if (res.data.status != this.Global.api_status.success) {
          this.setClientErrors({func:"circle/edit/circle-hashtags.vue::deleteMstTopic", params: {}, errorMessage:JSON.stringify(res.data)})
        }
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::deleteMstTopic", params: {}, errorMessage:error})
      })
    },
    updateMstTopic : function (row) {
      let params = {
        id : row.mst_topic_id,
        name : row.mst_topic_name,
        official : row.official,
        sort : !row.square_flg && row.sort,
        circle_id : !row.square_flg && row.circle_id
      }
      this.axios.post(this.Global.url.api_mst_topics_update, params)
      .then(res => {
        if (res.data.status != this.Global.api_status.success) {
          this.setClientErrors({func:"circle/edit/circle-hashtags.vue::updateMstTopic", params: params, errorMessage:JSON.stringify(res.data)})
          return
        }
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::updateMstTopic", params: params, errorMessage:error})
      })
    },
    getSquareHashtags: function () {
      let params = {}
      this.axios.post(this.Global.url.api_mst_topics_search, params).then(res=>{
        if (res.data.status === this.Global.api_status.success) {
          this.squareHashtags = res.data.hashtags
          this.items = _.map(this.squareHashtags, (row) => { 
            return mentionUtils.getMentionHashtagStates(this.Global.entry_division_id.square, row)
          })
        }

        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::getSquareHashtags", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/circle-hashtags.vue::getSquareHashtags", params: params, errorMessage:error})
      })
    },
    filterMentionItems : function () {
      let _selectedHashtags = {}
      _.forEach(_.filter(this.hashtags, (row) => { return row.square_flg && !row._deleteFlg}), (row2) => { 
        _selectedHashtags[row2.mst_topic_id] = row2.mst_topic_name 
      })
      // console.dir(Object.keys(_selectedHashtags))
      // console.dir(this.squareHashtags)
      _.forEach(this.squareHashtags, (row) => { 
        row._selectedFlg = _selectedHashtags[row.id] != null ? true : false 
      })

      this.items = _.map(_.filter(this.squareHashtags, (x) => { return x._selectedFlg == false }), (y) => { 
        return mentionUtils.getMentionHashtagStates(this.Global.entry_division_id.square, y)
      })
    },
    dragged:function(){
      // console.dir(this.hashtags)
      // _.forEach(this.hashtags, (row)=>{
      //   console.log(row.mst_topic_name)
      // })
    },
  },
  components:{
    draggable
  }
}
</script>