<template>
  <div class="stamp" v-if="marks.length > 0">
    <div  @mouseover="toggleMarksTip()" @mouseout="toggleMarksTip()">
      <img v-for="(mark, index) in representiveMarks" :key="index" :src="'/assets/'+mark.path" :alt="mark.name" />
      <span v-show="totalCount>0">{{totalCount}}</span>
    </div>
    <ul v-show="marksTip">
      <li v-for="(mark, index) in summaryMarks" :key="index">
        <img :src="'/assets/' + mark.path" :alt="mark.name"><span>{{mark.count}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  props:{
    entryId:{
      type:Number,
      required:true
    },
    refreshFlg:{
      type:Boolean,
      required:true
    }
  },
  data () {
    return {
      marks : [],
      representiveMarks:[],
      summaryMarks:[],
      totalCount : null,
      marksTip : false
    }
  },
  watch:{
    account:function () {
      this.get()
    },
    marks:function () {
      let _summaryMarks = {}
      
      _.forEach(this.marks, (row) => {
        if (!_summaryMarks[row.mst_mark_id]) {
          _summaryMarks[row.mst_mark_id] = {
            path : row.mst_mark_path,
            name : row.mst_mark_name,
            count : 0
          }
        }
        let sumRow = _summaryMarks[row.mst_mark_id]
        sumRow['count'] = sumRow['count'] + 1
      })
      this.summaryMarks = _summaryMarks

      this.representiveMarks= _.take(Object.values(_summaryMarks), this.Global.mark_link_representative)

      this.totalCount = this.marks.length
    },
    refreshFlg:function(val){
      if (val) this.get()
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account'
    ]),
  },
  created: function () {
    if(this.account) this.get()
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    get : function () {
      let params = { entry_id : this.entryId }
      this.axios.post(this.Global.url.api_marks_search, params )
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.marks = res.data.marks
          this.$emit('on-reloaded')
          return
        }
        this.setClientErrors({func:"common/entry/comment-marks.vue::get", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/comment-marks.vue::get", params: params, errorMessage:error})
      })
    },
    toggleMarksTip : function () {
      if (this.marks== null || this.marks.length==0) return
      this.marksTip = !this.marksTip
    },

    createSummary : function () {
      return 
    }
  }
}
</script>