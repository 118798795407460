<template>
  <div class="dialog">
    <section>
      <!-- メンバーを追加・編集する -->
      <c-members v-if="circleId > 0" :circle-id="circleId" :save-flg="saveMemebersFlg" @toggle-save-flg="()=>{saveMemebersFlg=false}" />
      <!-- 招待 -->
      <c-invite-members v-if="circleId > 0" :circle-id="circleId" :save-flg="saveInvitesFlg" @toggle-save-flg="()=>{saveInvitesFlg=false}" />
      <!-- 承認待ちの人 -->
      <c-request-members v-if="circleId > 0" :circle-id="circleId" :save-flg="saveRequestsFlg" @toggle-save-flg="()=>{saveRequestsFlg=false}" />
      <!-- 宛先リストの管理 -->
      <c-groups v-if="circleId > 0" :circle-id="circleId" :save-flg="saveGroupsFlg" @toggle-save-flg="()=>{saveGroupsFlg=false}" />
      <button class="publish" @click.prevent="save()">保存する</button>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import InviteMembers from './sub-components/invite-members'
import RequestMembers from './sub-components/request-members'
import Members from './sub-components/members'
import Groups from './sub-components/groups'


export default {
  props:{
    circleId:{
      type : Number,
      required : true
    }
  },
  data () {
    return {
      saveMemebersFlg : false,
      saveRequestsFlg : false,
      saveInvitesFlg : false,
      saveGroupsFlg : false,
      errors : {}
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
      'circleEditCircleId',
      'circleEditCircleMembersReloadFlg'
    ]),
  },

  created () {
    if (this.circleId > 0) {
      this.getCircleMembers()
    }
  },
  watch : {
    circleEditCircleId : function (newValue, oldValue) {
      if (newValue != oldValue || newValue != this.circleId) {
        this.getCircleMembers()
        return  
      }
    },
    circleEditCircleMembersReloadFlg : function (newValue) {
      if (!newValue) return
      
      this.getCircleMembers()
      this.setCircleEditCircleMembersReloadFlg(false)
    },
    saveMemebersFlg : function(val) {
      if (val && this.saveInvitesFlg && this.saveRequestsFlg && this.saveGroupsFlg)  this.$toasted.success('completed')
    },
    saveInvitesFlg : function(val) {
      if (this.saveMemebersFlg && val && this.saveRequestsFlg && this.saveGroupsFlg)  this.$toasted.success('completed')
    },
    saveRequestsFlg : function(val) {
      if (this.saveMemebersFlg && this.saveInvitesFlg && val && this.saveGroupsFlg)  this.$toasted.success('completed')
    },
    saveGroupsFlg : function(val) {
      if (this.saveMemebersFlg && this.saveInvitesFlg && this.saveRequestsFlg && val)  this.$toasted.success('completed')
    },
  },
  methods : {
    ...mapMutations([
      'setClientErrors',
      'setCircleEditCircleMembers',
      'setCircleEditCircleMembersReloadFlg'
    ]),

    getCircleMembers : function () {
      let params = { circle_id : this.circleId }
      this.axios.post(this.Global.url.api_circle_members_search, params).then(res=>{
        if (res.data.status === this.Global.api_status.success) {
          let i = 0
          this.setCircleEditCircleMembers(_.forEach(res.data.circle_members, row => { 
            row._index = i
            i++
          }))
          return
        }
        this.setClientErrors({func:"circle/edit/members-edit.vue::get", params: params, errorMessage:JSON.parse(res.data)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/members-edit.vue::get", params: params, errorMessage:error})
      })
    },

    save : function () {
      this.saveMemebersFlg = true
      this.saveInvitesFlg  = true  
      this.saveRequestsFlg = true
      this.saveGroupsFlg   = true
    },
  },
  components : {
    'c-request-members' : RequestMembers,
    'c-members' : Members,
    'c-invite-members' : InviteMembers,
    'c-groups' : Groups,
  }
}
</script>