<template>
  <div id="notifications">
    <div id="container">
      <main>
      </main>
      <aside>
        <div class="box box-notification">
          <div class="title">
            <h3>あなたへの通知</h3><a href="" v-if="loginFlg" @click.prevent="readAll()" >既読にする</a>
          </div>
          <!-- <div class="button">
            <div class="all"><a href="">すべて</a></div>
            <div class="unread"><a href="">未読</a></div>
          </div> -->
          <ul>
            <li v-for="(notification, index) in newNotifications" :key="index" :class="[ {'notification-read' : notification.is_unread===false } ]">
              <a @click.prevent="go(notification)">
                <div class="image" v-if="notification.account_id != null">
                  <c-account-image :has-img = "notification.has_img" :account-id = "notification.account_id" :account-name = "notification.nickname" />
                </div>
                <p>{{notification.message}}</p>
                <!-- <p class="notifications-point">●</p> -->
              </a>
            </li>
            <a href="#" @click.prevent="getNotifications()"><div>続きを表示</div></a>
          </ul>
        </div>
      </aside>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  data () {
    return {
      newNotifications : [],
      circleInvites : [],
      limit: this.Global.notification_limit_per_page,
      offset:0,
    }
  },
  computed:{
    ...mapState([
      'loginFlg',
      'account',
      'temporaryAccount',
      'notifications',
    ]),
  },
  watch : {
    loginFlg : function (val) {
      if (val) {
        if (this.account) this.getCircleInvites()
        return
      }
      this.newNotifications = []
      
    },
    account : function (newValue) {
      if(newValue && newValue.id > 0) {
        this.getCircleInvites()
      }
    },
  },
  created : function () {
    this.newNotifications = this.notifications
  },
  methods : {
    ...mapMutations([
      'setNotificationContainerDisplay',
      'setNotifications',
      'setClientErrors'
    ]),
    getNotifications : function () {
      this.axios.post( this.Global.url.api_entries_notifications, {
        account_id : this.account.id,
        limit : this.limit,
        offset : this.offset
      } ).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.newNotifications = this.newNotifications.concat(_.forEach(res.data.entries, (row) => { row.url = "entry" }))
          if ( res.data.entries.length > 0 ) {
            this.offset += this.Global.notification_limit_per_page
          }
        }
      }).catch( error => { (error) } )
    },
    go : function (notification) {
      if (notification.is_unread === true) {
        if (notification.notification_division_id == this.Global.notification_division_id.entry) this.readEntry(notification)
        if (notification.notification_division_id == this.Global.notification_division_id.mark) this.readMark(notification)
      }
      this.$router.push( { path : "/" + notification.url + "/" + notification.id }, () => {} )
    },
    readEntry : function (notification) {
      let params = {
        account_id : this.account.id,
        entry_ids : [notification.id]
      }
      this.axios.post(this.Global.url.api_already_reads_bulk_insert, params).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.newNotifications = []
          this.offset = 0
          this.getNotifications()
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"notifications/index.vue::readEntry", params: params, errorMessage:error})
      })
    },
    readMark : function (notification) {
      let params = {
        account_id : this.account.id,
        entry_ids : [notification.id],
        mark_ids : [notification.mark_id]
      }
      this.axios.post(this.Global.url.api_already_marks_bulk_insert, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.newNotifications = []
          this.offset = 0
          this.getNotifications()
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"notifications/index.vue::readMark", params: params, errorMessage:error})
      })
    },

    readAll : function () {
      this.axios.post( this.Global.url.api_entries_notifications, {
        account_id : this.account.id,
        offset : 0
      } ).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.readAllEntry(res.data.entries)
          this.readAllMark(res.data.entries)
        }
      }).catch( error => { (error) } )
      .finally(()=>{
      })
    
    },
    readAllEntry : function (notifications) {
      let entries = _.filter(notifications, x => x.notification_division_id === this.Global.notification_division_id.entry && x.is_unread === true)
      let entry_ids = _.map(entries, 'id')
      let params = {
        account_id : this.account.id,
        entry_ids : entry_ids
      }
      this.axios.post(this.Global.url.api_already_reads_bulk_insert, params).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          this.newNotifications = []
          this.offset = 0
          this.getNotifications()
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"notifications/index.vue::readEntry", params: params, errorMessage:error})
      })
    },
    readAllMark : function (notifications) {
      let entries = _.filter(notifications, x => x.notification_division_id == this.Global.notification_division_id.mark && x.is_unread === true)
      let entry_ids = _.map(entries, 'id')
      let mark_ids = _.map(entries, 'mark_id')
      let params = {
        account_id : this.account.id,
        entry_ids : entry_ids,
        mark_ids : mark_ids
      }
      this.axios.post(this.Global.url.api_already_marks_bulk_insert, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          this.newNotifications = []
          this.offset = 0
          this.getNotifications()
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"notifications/index.vue::readAllMark", params: params, errorMessage:error})
      })
    },
  }
}
</script>