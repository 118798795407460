<template>
  <div class="post" 
    v-bind:class="[ {
      circle_border : entry.circle_id !== null && entry.official_circle != false,
      'post-key' : (entry.circle_id !== null && entry.official_circle == false) || (entry.entry_division_id === 4 && entry.official_range === null)
    } ]">

    <div class="Item-Content-Flg2" v-if="(entry.circle_id != null && entry.official_circle==false) || (entry.entry_division_id === 4 && entry.official_range === null)"></div>
    <font-awesome-icon icon="ellipsis-v" class="other-action" />
    <c-launcher-menu v-if="isEditable() && !isDetailMode()" :entry="entry" />
    <div class="user">
      <c-account-anchor-image v-if = "entry && entry.account_id != null && !entry.entry_as_circle" :has-img = "entry.has_img" :account-id = "entry.account_id" :account-name = "entry.account_name" />
      <c-circle-anchor-image v-else-if = "entry && entry.circle_id != null && entry.entry_as_circle " :has-img = "entry.has_circle_img" :circle-id = "entry.circle_id" :circle-name = "entry.circle_name" />
      <div class="data">

        <div class="user-name" v-show="entry.account_name != null ">
          <router-link v-show="!entry.entry_as_circle" :to = "{ name:'myspace', params:{ accountId: entry.account_id} }" style="text-decoration: none">{{entry.account_name}}</router-link>
          <!-- フォローコンポーネント -->
          <c-label-account-follow :follow-account-id="entry.account_id" v-if="loginFlg && account && (entry.account_id != account.id) && !entry.entry_as_circle" />
          <div class="tag owner">
            <span v-if="entry.entry_division_id == 2 && entry.entry_as_circle" class="name">サークルとして投稿</span>
            <span v-if="account != null && (entry.already_read_flg === false || (entry.unread_marks != null && entry.unread_marks.length > 0))" class="new_name">新着</span>
          </div>
          <span v-if="account != null && this.unreadDisplay"  v-observe-visibility="{ callback: onVisibilityNew, throttle: 0, intersection: { threshold: 0}}">
          </span>
        </div>
        <div class="posted-date">
          <router-link :to = "{ name:'entry', params:{ entryId: entry.id} }" style="text-decoration: none">{{entry.updated_at}}に投稿</router-link>

          <div class="friend-only" v-if="entry.entry_division_id == 4">
            <svg version="1.1" id="friend-only" class="day-before" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <path class="friend-only" d="M36.2,52.2C36.2,52.2,36.1,52.2,36.2,52.2c-0.1,0-0.1,0-0.2,0c-14.5,0-26.3,6.5-26.3,14.4v14.8h21.8h9.2h21.8V66.6
                  C62.5,58.7,50.7,52.2,36.2,52.2z"/>
                  <path class="friend-only" d="M36.2,52.2C36.2,52.2,36.1,52.2,36.2,52.2c-0.1,0-0.1,0-0.2,0c-14.5,0-26.3,6.5-26.3,14.4v14.8h21.8h9.2h21.8V66.6
                  C62.5,58.7,50.7,52.2,36.2,52.2z"/>
                <path class="friend-only" d="M68.2,57c-1.2,0-2.5,0.1-3.6,0.2c4,2.5,6.5,5.8,6.5,9.5v14.8h19.2V69C90.2,62.4,80.4,57,68.2,57z"/>
                <circle class="friend-only" cx="36.2" cy="31.1" r="12.5"/>
                <circle class="friend-only" cx="68.2" cy="39.3" r="10.5"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- 通常投稿 -->
    <div :class="entry.text.length > 100 ? 'post-more': 'post'" v-if="!Boolean(entry.is_rss)">
      <input v-if="entry.text.length > 100" class="more" type="checkbox" :id="'trigger-post-more-' + entry.id" />
      <label v-if="entry.text.length > 100" class="more-btn" :for="'trigger-post-more-' + entry.id"></label>
      <p v-html="entryText"></p>
      <p v-if="Boolean(entry.has_thumbnail)">
        <c-thumbnail-image :entry-id="entry.id" />
      </p>
    </div>
    <!-- rss投稿 -->
    <div class="post-more-rss" v-else>
      <!-- <input v-if="!Boolean(entry.is_rss) && entry.text.length > 100" class="more" type="checkbox" :id="'trigger-post-more-' + entry.id" /> -->
      <!-- <input class="more" type="checkbox" :id="'trigger-post-more-' + entry.id" /> -->
      <p class="rss">
        <span v-html="entry.text" /><br />
      </p>
      <p v-if="Boolean(entry.has_thumbnail)">
        <c-thumbnail-image :entry-id="entry.id" />
      </p>
      <!-- <div class="rss-link">
        <a @click="openWindow(entry.rss_link)">続きを読む</a>
      </div> -->
    </div>
    <div class="tag circle">
      <span class="name" v-show=" entry.circle_name != null ">
          <router-link :to = "{ name:'circle/time-line', params:{ circleMode: 1, circleId: entry.circle_id} }" style="text-decoration: none">{{entry.circle_name}}</router-link>
      </span>
      <!-- 話題コンポーネント-->
      <c-label-hashtag v-for="(hashtag, index) in entry.hashtags" :key="index" :hashtag="hashtag" :official-circle="entry.official_circle" :circle-id="entry.circle_id"></c-label-hashtag>
    </div>
    <div class="feedback">
      <!-- しるしをしたユーザリンク -->
      <c-marks-link :mark-count="markCount" :entry-id="entry.id" :refresh-flg="marksLinkReload" @on-reloaded="toggleMarksLinkReload" />
      <c-comment-count-anchor v-show="!isDetailMode()" :entry-id="entry.id" :count="commentCount" />
    </div>
    <div class="action">
      <c-mark-dialog :entry-id="entry.id" @on-marked="toggleMarksLinkReload"/>
      <div class="action-comment">
        <div class="action" @click="changeCommentDisplay(
          {
            id: entry.account_id,
            last_name: entry.account_last_name,
            first_name: entry.account_first_name,
            nickname: entry.account_nickname,
          })">
          <svg version="1.1" id="icon-comment" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 27.9 23.6" style="enable-background:new 0 0 27.9 23.6;" xml:space="preserve">
            <path class="icon-comment" d="M19.3,1c-0.1,0-0.2,0-0.3,0c-1.5,0-3.1,0-4.6,0c-0.4,0-2.5,0-5,0C8.7,1,8.3,1.1,8.1,1.1C3.4,2,0.3,6.7,1.2,11.4                        c0.8,4.1,4.4,7.1,8.6,7.1c1.2,0,2.4,0,3.6,0c0.3,0,0.6,0.1,0.8,0.3c1.2,1,2.4,2,3.7,2.9c0.4,0.3,0.9,0.6,1.4,0.8                        c0.7,0.3,1.6,0,1.9-0.7c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.2-0.7,0.2-1.1c0-0.7,0-1.4,0-2.1c0-0.3,0.1-0.6,0.4-0.8                        c3.3-1.6,5.3-5.1,4.9-8.8C26.5,4.8,23.3,1.5,19.3,1L19.3,1z"></path>
          </svg><span>コメント</span>
        </div>
      </div>
    </div>
    <div class="comment" v-observe-visibility="{ callback: onVisibilityComment, throttle: 0, intersection: { threshold: 0}}">
      <!-- コメント(返信)一覧コンポーネント -->
      <c-comment-entries v-for="(comment, index) in comments" 
        :key="index" 
        :parent-entry-id="entry.id" 
        :entry="comment" 
        @change-comment-display="(defaultAccounts)=>{ changeCommentDisplay(defaultAccounts, index) }" />
      <!-- コメントコンポーネント -->
      <c-comment v-show="commentDisplay" 
                 :entry = "entry"
                 :refresh-flg="commentDisplayRefresh" 
                 :default-accounts="defaultAccounts" />
      
      <div class="more-comment" v-show="!isDetailMode() && hasMoreComments()"><router-link :to = "{ name:'entry', params:{ entryId: entry.id} }">他のコメントを見る</router-link></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
moment.locale('ja')

import Common from '../../../js/common'
import emojis from '../../../js/emoji'

import MarkDialog from './mark-dialog.vue'
import LabelAccountFollow from '../label-account-follow.vue'
import LabelHashtag from './label-hashtag.vue'
import Comment from './comment.vue'
import CommentEntries from './comment-entries.vue'
import MarksLink from './marks-link.vue'
import LauncherMenu from './launcher-menu'
import CommentCountAnchor from './comment-count-anchor'
import _ from 'lodash'

export default {
  props:{
    entryDivisionId : {
      type : Number,
      required : true,
    },
    entry : {
      type : Object,
      rquired : true,
    },
    keywords : {
      type : String,
      required : false,
    },
    // 詳細画面として表示するmode
    mode: {
      type: Number,
      required: true,
    }
  },
  data : function () {
    return {
      commentDisplay: false,
      commentDisplayRefresh: false,
      commentDisplayOpeningIndex: null,
      comments: null,
      commentCount: 0,
      markCount: 0,
      marksLinkReload: false,
      defaultAccounts: [],
      unreadDisplay: false,
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
      'circles',
      'commentReloadEntryId',
    ]),
    entryText : function () {
      let text = this.entry.text

      if (this.account != null) _.forEach(this.entry.destinations, (row)=>{
        text = text.replace('@'+row.account_name + '(' + row.account_nickname + ')', Common.createMentionWithLinkTag(row.account_id, row.account_name, row.account_nickname))
      })

      _.forEach(this.entry.hashtags, (row)=>{
        text = text.replace('#' + row.topic_name + "(" + (row.official ? '公式' : '自由') + ")", '')
      })

      if (this.keywords) {
        // eslint-disable-next-line
        let _keywords = this.keywords.replace(/　/g," ");
        let keywordsAry = _keywords.split(" ")
        _.forEach(keywordsAry, (key)=>{
          text = text.replace(key, '<span class="keyword">' + key + '</span>')
        })
      }
      // テキスト内URLをリンクに置き換え
      text = text.replace(/((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g, '<a href="$1">$1</a>');

      _.forEach(emojis, (row) => {
        text = text.replace(row.pattern, '<img src="'+row.src+'" width="20px" height="20px" style="margin-bottom:-5px">')
      })
      return text.replace(/\r?\n/g, '<br>')
    },
  },
  watch : {
    marksLinkReload () {
      // console.log('MarksLinkReload changed')
    },
    commentReloadEntryId (val) {
      if (val === null) return
      if (val !== this.entry.id) return
      this.comments = null
      this.getComments()
      this.setCommentReloadEntryId(null)
    },
  },
  created : function () {
    this.entry.updated_at = moment(this.entry.updated_at, 'YYYY-MM-DD HH:mm:S').fromNow()
    this.markCount = this.entry.mark_count
  },
  mounted : function () {
    this.unreadDisplay = this.entry.already_read_flg === false || (this.entry.unread_marks != null && this.entry.unread_marks.length > 0)
    // console.log('--------------')
    // console.log(this.entry.text)
    // console.log(this.entry.circle_id)
    // console.log(this.entry.official_circle)
  },
  methods : {
    ...mapMutations([
      'setCommentReloadEntryId',
      'setClientErrors'
    ]),
    changeCommentDisplay : function (defaultAccounts, index) {
      if (this.commentDisplayOpeningIndex == index) { 
        this.commentDisplay = !this.commentDisplay
      } else {
        this.commentDisplay = true  
      }
      this.commentDisplayOpeningIndex = index
      this.commentDisplayRefresh = true

      if (defaultAccounts) {
        this.defaultAccounts = [defaultAccounts]
      }

    },
    openWindow: function (url) {
      window.open(url, '_blank')
    },
    getComments : function () {
      let params = { entry_id: this.entry.id }
      if (!this.isDetailMode()) params.limit = this.Global.limited_comment_count
      this.axios.post(this.Global.url.api_entries_replies, params)
      .then(res=>{
        if ( res.data.status === this.Global.api_status.success ) {
          this.comments = res.data.entries
          this.commentCount = res.data.all_count
          return
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/main.vue::getComments", params: params, errorMessage:error})
      })
    },
    toggleMarksLinkReload : function () {
      this.marksLinkReload = !this.marksLinkReload
      this.markCount++
    },
    isDetailMode : function () {
      return this.mode === this.Global.entry_display_mode.detail
    },
    hasMoreComments : function () {
      return this.commentCount > this.Global.limited_comment_count
    },
    isEditable : function () {
      if (this.account === null) return false
      if (this.entry.entry_as_circle && this.isCircleAdmin()) return true
      if (this.account.id === this.entry.account_id) return true 
      return false
    },
    isCircleAdmin: function () {
      if (this.entry.circle_id === null) return
      let circle = _.keyBy(this.circles, 'id')[this.entry.circle_id]
      let re = _.filter(circle.members, r => r.id === this.account.id && r.admin_flg)
      return re.length > 0
    },
    onVisibilityNew : function (isVisible) {
      if (!isVisible) return
      if (this.account == null) return
      if (!this.entry.already_read_flg) {
        let params = {
          account_id: this.account.id, 
          entry_ids: [this.entry.id],
        }

        this.axios.post(this.Global.url.api_already_reads_bulk_insert, params).then(res => {
          if (res.data.status == this.Global.api_status.success) {
            // this.$toasted.success("insert already_read success")
            return
          }
          // this.$toasted.error("insert already_read error")
        }).catch(error=>{
          this.$toasted.error('client error')
          this.setClientErrors({func:"common/etnry.vue::watch", params: params, errorMessage:error})
        })
      }

      if (this.entry.unread_marks.length > 0) {
        let entryIds = Array(this.entry.unread_marks.length)
        entryIds.fill(this.entry.id)

        let params = {
          account_id: this.account.id,
          entry_ids: entryIds,
          mark_ids: this.entry.unread_marks,
        }
        this.axios.post(this.Global.url.api_already_marks_bulk_insert, params).then(res => {
          if (res.data.status == this.Global.api_status.success) {
            // this.$toasted.success("insert already_mark success")
            return
          }
          // this.$toasted.error("insert already_mark error")
        }).catch(error=>{
          this.$toasted.error('client error')
          this.setClientErrors({func:"common/etnry.vue::watch", params: params, errorMessage:error})
        })
      }
      this.unreadDisplay = false
    },
    onVisibilityComment: function(isVisible) {
      if (!isVisible) return
      this.getComments()
    },
  },
  components : {
    'c-mark-dialog' : MarkDialog,
    'c-label-account-follow' : LabelAccountFollow,
    'c-label-hashtag' : LabelHashtag,
    'c-comment' : Comment,
    'c-comment-entries' : CommentEntries,
    'c-marks-link' : MarksLink,
    'c-launcher-menu': LauncherMenu,
    'c-comment-count-anchor': CommentCountAnchor,
  }
}
</script>
<style scoped>
.circle_border {
  border: 3px solid #ffe499;
}
</style>