<template>
  <div class="composer" v-if="account == null">
    <div class="editor">
      <div class="flexeditor">
        <div class="flexeditorarea-dummy" aria-hidden="true">コメントするためにはログインしてください</div>
      </div>
    </div>
  </div>
  <div class="composer" v-else>
    <div class="user">
      <c-account-image v-if="account != null" :has-img = "account.has_img" :account-id = "account.id" :account-name = "account.name" />
    </div>
    <div class="editor">
      <div class="flexeditor" :style="styles">
        <!-- <div class="flexeditorarea-dummy" aria-hidden="true"></div> -->
        <mentionable
          :keys="keys"
          :items="items"
          :limit="mentionLimit"
          insert-space>
          <!-- <textarea class="flexcommenteditor" placeholder="コメントを書く" v-model="text" @keydown.enter.exact="comment" @keydown.enter.shift="keyEnterShift"></textarea> -->
          <textarea class="flexcommenteditor" placeholder="コメントを書く" v-model="text" @keydown.enter.exact="comment" ref="area" :style="styles"></textarea>
          <template #no-result>
            <div class="dim">
              No result
            </div>
          </template>
          <template #item-@="{ item }">
            <div class="destination" v-if="!item.groupFlg">
              {{ item.lastName + item.firstName }}
              <span class="dim">
                {{ item.nickname }}
              </span>
            </div>
            <div class="group" v-else>
              {{ item.name }}
              <span class="dim">
                ({{ item.count }}名)
              </span>
            </div>
          </template>
        </mentionable>

      </div>
    </div>
    <font-awesome-icon :icon="['far', 'grin-alt']" size="lg" :style="{ color:'#9197A3', marginLeft:'10px', marginTop:'20px', cursor: 'pointer'}" @click="()=>{emojiDialog=!emojiDialog}" />
    <!-- <button @click="comment">click</button> -->
    <c-emoji-dialog v-show="emojiDialog" @add-emoji="(val) => { addEmoji(val) }" />
  </div>
  

</template>

<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import mentionUtils from '../../../js/common/entry/MentionUtils'
import EmojiDialog from '../emoji-dialog'

export default {
  props : {
    entry : {
      type : Object,
      required: true
    },    
    defaultAccounts : {
      type : Array,
      required : false
    },
    refreshFlg : {
      type:Boolean,
      required:true
    }
  },
  data(){
    return {
      text: null,
      destinationIds: null,
      hashtagIds: null,
      destinationGroupIds: null,
      img: null,
      keys: ['@'],
      mentionLimit: this.Global.vue_mention_limit,
      accounts: null,
      groups: null,
      hashtags: null,
      items: [],
      groupToAccounts: [],
      emojiDialog: false,
      height:'70px'
    }
  },
  watch : {
    items : function () {
      if (this.items.length < 100 && this.items.length > this.Global.vue_mention_limit) {
        this.mentionLimit = this.items.length
      }
    },
    account : function () {
      this.getDestinations()
    },
    refreshFlg : function (val) {
      if (val) {
        this.text=null
      }
    },
    defaultAccounts : function () {
      this.setMentionsText()
    },
    text : function () {
      this.resize()
    },
  },
  computed:{
    ...mapState([
      'loginFlg',
      'account',
      'circleMode',
      'circles'
    ]),
    styles() {
      return {
        "height": this.height,
      }
    },
  },
  mounted : function () {
    _.forEach(document.getElementsByClassName("mentionable"), (row)=>{row.removeAttribute('style')})
    this.setMentionsText()
    this.getDestinations()
  },
  methods : {
    ...mapMutations([
      'setCommentReloadEntryId',
      'setClientErrors'
    ]),
    getDestinations : function () {
      let url    = mentionUtils.getDestinationsUrl(this.entry.entry_division_id)
      let params = mentionUtils.getDestinationParameters(this.entry.entry_division_id, this.entry.circle_id, this.account&&this.account.id)

      this.axios.post(url, params).then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.accounts = mentionUtils.getApiResponse(res)
          this.items = _.map(this.accounts, (row) => {
            return mentionUtils.getMentionStates(row)
          })
          return
        }
      })
      .catch(error=>{
        console.log(error)
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/comment.vue::getDestinations", params: params, errorMessage:error})
      })
      .finally(()=>{
        if (this.entry.circle_id != null) {
          // TODO
          this.getGroup()
        }
      })
    },

    getGroup : function () {
      let circle = _.keyBy(this.circles, 'id')[this.entry.circle_id];
      this.groups = circle.groups
      this.items = this.items.concat(_.map(this.groups, (row) => {
        return mentionUtils.getMentionGroupsStates(row)
      }))
      this.groupToAccounts = mentionUtils.createGroupMentionToMembersMentionConverter(this.groups)
      if (this.items.length > 0) {
        this.items = this.items.concat(mentionUtils.getHereState())
      }
    },
    comment : function (event) {
      if (event.keyCode !== 13) return
      if (this.isMentionOpen())  return
      if (!this.loginFlg) {
        this.$toasted.error("please login!")
        return
      }

      if (mentionUtils.isEpty(this.text, this.accounts, this.groups, this.hashtags)) {
        this.$toasted.error("no comment")
        return
      }
      this.destinationGroupIds = mentionUtils.getGroupIdsFromText(this.text, this.groups)

      this.text = mentionUtils.changeGroupToMembers(this.text, this.groupToAccounts)
      this.destinationIds = mentionUtils.getDestinationIdsFromText(this.text, this.accounts, this.groups)

      this.text = mentionUtils.deleteAccountIdFromText(this.text, this.accounts, this.groups)
      // alert(this.text)
      // return

      var formData = new FormData()
      formData.append("insert_flg",        this.Global.ON)
      formData.append("entry_division_id", this.entry.entry_division_id)
      formData.append("text",              this.text)
      formData.append("account_id",        this.account.id)
      formData.append("reply_id",          this.entry.id)
      formData.append("all_dest_flg",      mentionUtils.getAllDestFlg(this.text))
      if (this.hashtagIds)     formData.append("hashtag_ids",     this.hashtagIds)
      if (this.destinationIds) formData.append("destination_ids", this.destinationIds)
      if (this.destinationGroupIds) formData.append("destination_group_ids", this.destinationGroupIds)
      if (this.entry.circle_id)       formData.append("circle_id",       this.entry.circle_id)
      if (this.entry.circle_id)       formData.append("official_circle", this.getOffcialCircle())
      if (this.entry.circle_id)       formData.append("official_range",  0)

      this.axios.post(this.Global.url.api_entries_tweet, formData)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.text = ''
          this.mail(res.data.entry.id)
          this.$toasted.success("success")
          this.setMentionsText()
          this.setCommentReloadEntryId(this.entry.id)
          return
        }
        this.$toasted.error("failed")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/comment.vue::comment", params: formData, errorMessage:error})
      })
    },
    getOffcialCircle : function () {
      if (this.circleMode == this.Global.circle_mode.public) return true
      if (this.circleMode == this.Global.circle_mode.private) return false
    },
    mail : function (entryId) {
      let params = { entry_id: entryId }
      this.axios.post(this.Global.url.api_entries_reply_email, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          return
        }
        // console.dir(error)
        this.setClientErrors({func:"common/entry/comment.vue::mail", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry/comment.vue::mail", params: params, errorMessage:error})
      })
    },
    setMentionsText : function () {
      if (this.entry.entry_as_circle) return
      let _mentionsText = ''

      _.forEach(this.defaultAccounts, (row)=>{
        if (_mentionsText.indexOf(mentionUtils.getMentionStates(row).value) != -1) return
        if (!row.id) return
        _mentionsText += (_mentionsText && ' ') + '@'+mentionUtils.getMentionStates(row).value
      })

      if (_mentionsText != '') this.text = _mentionsText + '\n'
    },
    isMentionOpen : function () {
      return document.querySelectorAll('.v-popover.popper.open').length > 0
    },
    addEmoji : function (val) {
      if (val) {
        this.text=this.text+val
      }
      this.emojiDialog=false
    },
    resize: function () {
      this.height = this.$refs.area.scrollHeight + 'px'
      this.height = "auto"
      this.$nextTick(() => {
        this.height = this.$refs.area.scrollHeight + 'px'
      })
    },
  },
  components:{
    'c-emoji-dialog':EmojiDialog
  }
}
</script>