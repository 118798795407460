<template>
  <div class="box box-notification" @mouseleave="onMouseleave">
    <div class="title">
      <h3>あなたへの通知</h3><a href="" v-if="loginFlg" @click.prevent="readAll()" >既読にする</a>
    </div>
    <ul>
      <li v-for="(notification, index) in newNotifications" :key="index" :class="[ {'notification-read' : notification.is_unread===false } ]">
        <a @click.prevent="go(notification)">
          <div class="image" v-if="notification.account_id != null">
            <c-account-image :has-img = "notification.has_img" :account-id = "notification.account_id" :account-name = "notification.nickname" />
          </div>
          <p>{{notification.message}}</p>
        </a>
      </li>
      <a href="#" v-if="loginFlg" >
        <div @click="toNotifications">一覧を表示</div><div @click="getNotifications()">続きを表示</div>
      </a>
    </ul>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  props : {
    account : {
      type : Object,
      required : false
    }
  },
  data () {
    return {
      newNotifications : [],
      circleInvites : [],
      limit: this.Global.notification_limit_per_page,
      offset:0,
      completeInviteFlg:false,
    }
  },
  computed:{
    ...mapState([
      'loginFlg',
      'temporaryAccount',
    ]),
  },
  watch : {
    loginFlg : function (val) {
      if (val) {
        if (this.account) this.getCircleInvites()
        return
      }
      this.newNotifications = []
      if (this.temporaryAccount) this.createTemporaryAddNotification()
    },
    temporaryAccount : function (newValue) {
      this.newNotifications = []
      if (newValue) {
        this.createTemporaryAddNotification()
        return
      }
    },
    account : function (newValue) {
      if(newValue && newValue.id > 0) {
        this.getCircleInvites()
      }
    },
    completeInviteFlg : function () {
      if (this.account) this.getNotifications()
    }
  },
  mounted : function () {
    if (this.loginFlg && this.account) this.getCircleInvites()
    if (this.temporaryAccount) this.createTemporaryAddNotification()
  },
  methods : {
    ...mapMutations([
      'setNotificationContainerDisplay',
      'setNotifications',
      'setClientErrors'
    ]),
    createTemporaryAddNotification : function () {
      if (this.temporaryAccount == null) return
      this.axios.get(this.Global.url.api_temp_accounts_show + this.temporaryAccount.id)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.newNotifications = this.newNotifications.concat([{
            url : 'sign-in',
            message : '仮登録状態です。クリックして本登録しましょう。',
            id : this.temporaryAccount.id,
            has_img : false
          }])
          return
        }
        this.setClientErrors({func:"common/notification.vue::createTemporaryAddNotification", params: {}, errorMessage:JSON.stringify(res.data.errors)})        
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/notification.vue::createTemporaryAddNotification", params: {}, errorMessage:error})
      })
    },
    getCircleInvites : function () {
      let params = {
        account_id : this.account.id,
        status : this.Global.circle_invite_status.pending
      }
      this.axios.post(this.Global.url.api_circle_invites_search_by_account, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          // this.limit = this.Global.notification_limit_per_page - res.data.circle_invites.length % this.Global.notification_limit_per_page          
          this.newNotifications = this.newNotifications.concat(_.forEach(res.data.circle_invites, (row) => {
            let message = row.circle_name + "より招待されてます"
            row.url = "circle/approval"
            row.message = message
            row.has_img = false
          }))

          this.completeInviteFlg=true
          return
        }
        this.setClientErrors({func:"common/notification.vue::getCircleInvites", params: params, errorMessage:JSON.stringify(res.data.errors)})        
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/notification.vue::getCircleInvites", params: params, errorMessage:error})
      })
    },
    getNotifications : function () {
      this.axios.post( this.Global.url.api_entries_notifications, {
        account_id : this.account.id,
        limit : this.limit,
        offset : this.offset
      } ).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.newNotifications = this.newNotifications.concat(_.forEach(res.data.entries, (row) => { row.url = "entry" }))
          if ( res.data.entries.length > 0 ) {
            this.offset += this.Global.notification_limit_per_page
          }
        }
      }).catch( error => { (error) } )
    },
    go : function (notification) {
      if (notification.is_unread === true) {
        if (notification.notification_division_id == this.Global.notification_division_id.entry) this.readEntry(notification)
        if (notification.notification_division_id == this.Global.notification_division_id.mark) this.readMark(notification)
      }
      this.$router.push( { path : "/" + notification.url + "/" + notification.id }, () => {} )
    },
    readEntry : function (notification) {
      let params = {
        account_id : this.account.id,
        entry_ids : [notification.id]
      }
      this.axios.post(this.Global.url.api_already_reads_bulk_insert, params).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.newNotifications = []
          this.offset = 0
          this.getNotifications()
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/notification.vue::readEntry", params: params, errorMessage:error})
      })
    },
    readMark : function (notification) {
      let params = {
        account_id : this.account.id,
        entry_ids : [notification.id],
        mark_ids : [notification.mark_id]
      }
      this.axios.post(this.Global.url.api_already_marks_bulk_insert, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.newNotifications = []
          this.offset = 0
          this.getNotifications()
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/notification.vue::readMark", params: params, errorMessage:error})
      })
    },

    readAll : function () {
      this.axios.post( this.Global.url.api_entries_notifications, {
        account_id : this.account.id,
        offset : 0
      } ).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.readAllEntry(res.data.entries)
          this.readAllMark(res.data.entries)
        }
      }).catch( error => { (error) } )
      .finally(()=>{
      })
    
    },
    readAllEntry : function (notifications) {
      let entries = _.filter(notifications, x => x.notification_division_id === this.Global.notification_division_id.entry && x.is_unread === true)
      let entry_ids = _.map(entries, 'id')
      let params = {
        account_id : this.account.id,
        entry_ids : entry_ids
      }
      this.axios.post(this.Global.url.api_already_reads_bulk_insert, params).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          this.newNotifications = []
          this.offset = 0
          this.getNotifications()
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/notification.vue::readEntry", params: params, errorMessage:error})
      })
    },
    readAllMark : function (notifications) {
      let entries = _.filter(notifications, x => x.notification_division_id == this.Global.notification_division_id.mark && x.is_unread === true)
      let entry_ids = _.map(entries, 'id')
      let mark_ids = _.map(entries, 'mark_id')
      let params = {
        account_id : this.account.id,
        entry_ids : entry_ids,
        mark_ids : mark_ids
      }
      this.axios.post(this.Global.url.api_already_marks_bulk_insert, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          this.newNotifications = []
          this.offset = 0
          this.getNotifications()
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/notification.vue::readAllMark", params: params, errorMessage:error})
      })
    },
    onMouseleave : function () {
      this.setNotificationContainerDisplay(false)
    },
    toNotifications : function () {
      this.setNotifications(this.newNotifications)
      this.$router.push( { path : "/notifications" }, () => {} )
    }
  }
}
</script>