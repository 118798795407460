<template>
  <div class="dialog">
    <section>
      <h2>その他</h2>
      <!-- 公開サークル話題追加 -->
      <c-circle-hashtags :circle-id="circleId" :save-flg="savePublicHashtagFlg" circle-type="public" v-if="circleEditPublish" @toggle-save-flg="()=>{savePublicHashtagFlg=false}" />
      <c-circle-hashtags :circle-id="circleId" :save-flg="savePrivateHashtagFlg" circle-type="private" @toggle-save-flg="()=>{savePrivateHashtagFlg=false}" />
      <c-rss :circle-id="circleId" :save-flg="saveRssFlg" @toggle-save-flg="()=>{saveRssFlg=false}" v-if="circleEditPublish" />
      <button class="publish" @click.prevent="save()">保存する</button>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import CircleHashtags from './sub-components/circle-hashtags'
import Rss from './sub-components/rss'

export default {
  props:{
    circleId : {
      type:Number,
      required:true
    }
  },
  data () {
    return {
      saveFlg : false,
      savePublicHashtagFlg : false,
      savePrivateHashtagFlg : false,
      saveRssFlg : false,
      errors : {}
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
      'circleEditCircleId',
      'circleEditPublish',
    ]),
    open : function () {
      return true
    },
    close : function () {
      return false
    }
  },

  created () {},
  watch : {
    savePublicHashtagFlg : function (val) {
      if ( val && this.savePrivateHashtagFlg && this.saveRssFlg ) this.$toasted.success('completed') 
    },
    savePrivateHashtagFlg : function (val) {
      if ( this.savePublicHashtagFlg && val && this.saveRssFlg ) this.$toasted.success('completed') 
    },
    saveRssFlg : function (val) {
      if ( this.savePublicHashtagFlg && this.savePrivateHashtagFlg && val ) this.$toasted.success('completed') 
    },
  },
  mounted () {

  },
  methods : {
    ...mapMutations([
      'setClientErrors',
    ]),

    save : function () {
      this.savePublicHashtagFlg = true
      this.savePrivateHashtagFlg = true
      this.saveRssFlg = true
    },
  },
  components : {
    'c-circle-hashtags' : CircleHashtags,
    'c-rss' : Rss
  }
}
</script>