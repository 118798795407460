<template>
  <div v-if="account != null && !Boolean(followFlg)" @click="follow" class="follow-action">フォロー</div>
  <div v-else-if="account != null && Boolean(loginFlg)" class="unfollow-action"><span @click="unFollow">フォロー解除</span><span>フォロー中</span></div>
  <div v-else />
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  props : {
    followCircleId : {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      followFlg : false,
      followId : null,
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
      'followCount',
      'circleFollows',
    ])
  },
  watch: {
    circleFollows (newVals) {
      let target = _.filter(newVals, r => r.follow_circle_id === this.followCircleId)
      this.followFlg = target.length > 0
      if (target.length > 0) this.followId = target[0].id
    }
  },
  created : function () {
    // this.get()
  },
  mounted: function () {
      let target = _.filter(this.circleFollows, r => r.follow_circle_id === this.followCircleId)
      this.followFlg = target.length > 0
      if (target.length > 0) this.followId = target[0].id
  },
  methods : {
    ...mapMutations([
      'setCircleFollows',
      'setFollowCount',
      'setClientErrors',
    ]),
    follow : function () {
      let params = {
        account_id : this.account.id,
        follow_circle_id : this.followCircleId,
      }
      this.axios.post(this.Global.url.api_circle_follows_create, params)
      .then(res => {
        if(res.data.status == this.Global.api_status.success){
          this.followId = res.data.circle_follow.id
          this.followFlg = true
          this.$toasted.success("success")
          let newFollows = this.circleFollows.concat([res.data.circle_follow])
          this.setCircleFollows(newFollows)
          return
        }
        console.dir(res.data.errors)
        this.$toasted.error("failed")

      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/label-circle-follow.vue::follow", params: params, errorMessage:error})
      })
    },

    unFollow : function () {
      this.axios.delete(this.Global.url.api_circle_follows_delete + '/' + this.followId).then(res => {
        if(res.data.status == this.Global.api_status.success){
          let newFollows = _.filter(this.circleFollows, r => r.id !== this.followId)
          this.setCircleFollows(newFollows)
          this.followId = null
          this.followFlg = false
          this.$toasted.success("success")
          return
        }
        console.dir(res.data.errors)
        this.$toasted.error("failed")
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/label-circle-follow.vue::unFollow", params: {id:this.followId}, errorMessage:error})
      })
    }
  }
}
</script>