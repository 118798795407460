<template>
  <div id="openspace">
    <div id="container">
      <div class="wrapper">
        <aside>
          <div class="widget">
            <div class="weather"><img src="/assets/widget-1@2x.png" alt=""></div>
            <div class="weather"><img src="/assets/widget-2@2x.png" alt=""></div>
            <div class="weather"><img src="/assets/widget-3@2x.png" alt=""></div>
          </div>
        </aside>
        <main class="timeline">
          <div class="detail-back"><a href="#" @click.prevent="back">戻る</a></div>
          <c-entry v-for="(entry, index) in entries" 
            :key="index" 
            :entry-division-id="entry.entry_division_id" 
            :entry="entry"
            :mode="entryDisplayMode"
            />
        </main>
        <c-cotolike-widget />
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import entry from '../common/entry'
import CotolikeWidget from '../common/cotolike-widget.vue'

export default {
  data () {
    return {
      entryId : null,
      entries : null,
      entryDisplayMode : this.Global.entry_display_mode.detail,
    }
  },

  computed:{
    ...mapState([
      'loginFlg',
      'account'
      ]),
  },
  watch : {
    "$route.params.entryId"(val) {
      this.get(val)
    },
  },  
  created : function () {
    this.entryId = this.$route.params.entryId
  },

  mounted : function () {
    if (this.entryId > 0) this.get(this.entryId)
  },

  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    get : function (entryId) {
      let params = { entry_id : entryId}
      this.axios.post(this.Global.url.api_entries_search, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.entries = res.data.entries
          return
        }
        // this.$toasted.error("error")
        this.setClientErrors({func:"detail/main.vue::get", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"detail/main.vue::get", params: params, errorMessage:error})
      })
    },
    back : function () {
      this.$router.back()
    }
  },

  components : {
    'c-entry' : entry,
    'c-cotolike-widget' : CotolikeWidget
  }
}
</script>