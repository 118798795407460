import Global from './constant.js'
import Url from 'url'
var url = Url.parse(location.href)

var common = {
	// ベースとなるurlを返却
	// http://xxxx.cotolike.jp or http://192.168.33.10:3000 
	getBaseUrl : function(){

		if(url.host == Global.host){
			return url.protocol + "//" + url.host + Global.context_root
		} else {
			return url.protocol + "//" + url.hostname + ":" + Global.port_rails
		}
	},
	getUrlHost : function () {
		if(url.host == Global.host){
			return url.host
		} else {
			return url.hostname
		}
	},
	createMentionWithLinkTag : function (accountId, accountName, accountNickname) {
		return '<span class="mention-color"><a href="/#/myspace/'+accountId+'" style="text-decoration: none">@' + accountName + '(' + accountNickname + ')' + '</a></span>'
	},
}

export default common
