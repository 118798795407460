var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"launcher-menu"},[_c('ul',[_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: '編集',
        autoHide:false,
        loadingContent: 'Please wait...',
        loadingClass: 'content-is-loading',
      }),expression:"{\n        content: '編集',\n        autoHide:false,\n        loadingContent: 'Please wait...',\n        loadingClass: 'content-is-loading',\n      }"}]},[_c('font-awesome-icon',{staticStyle:{"cursor":"pointer","color":"#24a7ff"},attrs:{"icon":"edit"},on:{"click":_vm.editEntry}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: '削除',
        autoHide:false,
        loadingContent: 'Please wait...',
        loadingClass: 'content-is-loading',
      }),expression:"{\n        content: '削除',\n        autoHide:false,\n        loadingContent: 'Please wait...',\n        loadingClass: 'content-is-loading',\n      }"}]},[_c('font-awesome-icon',{staticStyle:{"cursor":"pointer","color":"#24a7ff"},attrs:{"icon":"trash-alt"},on:{"click":_vm.deleteEntry}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }