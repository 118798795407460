<template>
  <div>
    <p>「{{circleName}}」サークルへ招待されてます。参加しますか？</p>
    <p>circleActivities : {{circleActivities}}</p>
    <button @click="updateInvite(1)">参加する</button>
    <button @click="updateInvite(2)">参加しない</button>
  </div>  
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      inviteId         : null,
      circleId         : null,
      invitedAccountId : null,
      inviteStatus     : null,
      circleName       : null,
      circleActivities : null
    }
  },
  watch:{
    invitedAccountId : function (val) {
      if (val === null ) return 
      if (val != this.account.id) {
        this.$toasted.error("ログインアカウント情報と招待されたアカウント情報が異なります")
        this.$router.push( { path : '/' }, () => {} )
      }
    },
    inviteStatus : function (val) {
      if (val === null) return
      if (val != this.Global.circle_invite_status.pending) {
         this.$router.push( { path : '/' }, () => {} )
         this.$toasted.error("this invite mail expired.")
      }
    },
    circleId : function (val) {
      if (val === null) return
      this.getCircle()
    },
    "$route.params.inviteId"(val) {
      this.inviteId         = val
      this.circleId         = null
      this.invitedAccountId = null
      this.inviteStatus     = null
      this.circleName       = null
      this.circleActivities = null      
      this.getCircleInvite()
    }
  },
  computed: {
    ...mapState([
        'loginFlg',
        'account'
    ]),
  },
  mounted () {
    if (!this.loginFlg) this.$router.push( { path : '/' }, () => {} )
    
    this.inviteId = this.$route.params.inviteId

    this.getCircleInvite()

  },
  methods : {
    ...mapMutations([
      'setClientErrors'
    ]),
    getCircleInvite : function () {
      let params = {
        id : this.inviteId
      }
      this.axios.post(this.Global.url.api_circle_invites_show, params).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          let circleInvite      = res.data.circle_invite
          this.invitedAccountId = circleInvite.account_id
          this.inviteStatus     = circleInvite.status
          this.circleId         = circleInvite.circle_id 

          return
        }
        this.setClientErrors({func:"circle/approval/index.vue::getCircleInvite", params: params, errorMessage:JSON.stringify(res.data)})
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/approval/index.vue::getCircleInvite", params: params, errorMessage:error})
      })
    },

    getCircle : function () {
      this.axios.get(this.Global.url.api_circles_show + this.circleId)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.circleName = res.data.circle.circle_name
          this.circleActivities = res.data.circle.activities
          return
        }
        this.setClientErrors({func:"circle/approval/index.vue::getCircle", params: {}, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/approval/index.vue::getCircle", params: {}, errorMessage:error})
      })

    },

    updateInvite : function (status) {
      let params = {
        id     : this.inviteId,
        status : status
      }
      this.axios.post(this.Global.url.api_circle_invites_update, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          if (status == this.Global.circle_invite_status.ok) {
            this.insertCircleMember()
            return
          }
          this.$toasted.success('reject completed')
          this.$router.push( { path : '/'}, () => {} )
          return
        }
        console.dir(res.data.errors)
        this.setClientErrors({func:"circle/approval/index.vue::updateInvite", params: params, errorMessage:JSON.stringify(res.data)})
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/approval/index.vue::updateInvite", params: params, errorMessage:error})
      })
    },
    insertCircleMember : function () {
      let params = {
        circle_id  : this.circleId,
        account_id : this.invitedAccountId,
        admin_flg : false,
      }
      this.axios.post(this.Global.url.api_circle_members_create, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success('approval completed')
          this.$router.push( { path : '/circle/time-line/1/' + this.circleId }, () => {} )
          return
        }
        // エラーの場合は元に戻す
        this.updateInvite(this.Global.circle_invite_status.pending)
        this.$toasted.success('エラーが発生しました。システム管理者に連絡ください')
        this.$router.push( { path : '/'}, () => {} )
        this.setClientErrors({func:"circle/approval/index.vue::insertCircleMember", params: params, errorMessage:JSON.stringify(res.data)})
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/approval/index.vue::insertCircleMember", params: params, errorMessage:error})
      })
    }
  },
}
</script>