<template>
  <div id="support">
    <div class="header">
      <h1>全体メール</h1>
    </div>
    <div class="container">
      <main>
        <section>
          <button @click="prev">戻る</button>
          <c-validation-errors :errors-hash="errors" />
          <p id="title">件名</p>
          <p>
            <input type="text" placeholder="Please input" v-model="subject" size="50"/>
          </p>
          <p id="title">本文</p>
          <p>
            <textarea cols="100" rows="15" placeholder="Please input" v-model="contents" />
          </p>
          <div class="exec" id="submitButton">
            <button @click="send">送信</button>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<style scoped>
#contents{
	margin: 0 auto;
	width:700px;
	height:500px;
	padding:40px 10px 0px 35px;
	border-radius:15px
}

#title{
	width: 93px;
	display: block;
	float: left;
	margin: 0 0 0 0;
	padding-top: 2px;
	text-align: center;
	color: #FFF;

}

#title-text{
	width:560px;
	float:left;
	margin-bottom:20px;
	border-radius:15px;
	padding-left: 10px;

}

#submitButton{
	text-align: center;
}
</style>
<script>
import{mapState, mapMutations} from 'vuex'

export default {
  data(){
    return {
      subject:null,
      contents:null,
      errors: {},
    }
  },
  computed:{
    ...mapState(['loginFlg'])
  },
  mounted : function(){
    if(!this.loginFlg) {
      this.$toasted.info("no login! please log in.")
      this.$router.push( { path : '/' }, () => {} )
    }
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    send : function () {
      let params = {
        subject:this.subject,
        text:this.contents
      }
      this.axios.post(this.Global.url.api_admin_mail, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("completed")
          return
        }
        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        } 

        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"admin/mail/index.vue::send", params: params, errorMessage:JSON.stringify(res.data)})

      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"admin/mail/index.vue::send", params: params, errorMessage:error})
      })
    },
    prev : function(){
      this.$router.push( { path : '/admin/menu' }, () => {} )
    }
  }
}
</script>
