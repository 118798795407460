<template>
  <nav class="main">
    <ul>
      <li>
        <a class="home" href="" @click.prevent="setDivisionId(1)" :class="[ {current : hashtagDivisionId == home } ]">
          <div>
            <svg version="1.1" id="icon-home" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 27 22.5" style="enable-background:new 0 0 27 22.5;" xml:space="preserve">
              <path class="icon-home" d="M23,11v11.5h-7v-6.5h-5v6.5H3.9V11H0L13.5,0L27,11L23,11z"></path>
            </svg>
          </div>
        </a>
      </li>
      <li><a href="" @click.prevent="setDivisionId(2)" :class="[ {current : hashtagDivisionId == official } ]"><div>公式な話題</div></a></li>
      <li><a href="" @click.prevent="setDivisionId(3)" :class="[ {current : hashtagDivisionId == free } ]"><div>自由な話題</div></a></li>
      <li><a href="" @click.prevent="setDivisionId(4)" :class="[ {current : hashtagDivisionId == active } ]"><div>最近の話題</div></a></li>
    </ul>
  </nav>
</template>

<script>
// 広場の公式な話題、自由な話題、最近の話題コンポーネント
import { mapState, mapMutations } from 'vuex'

export default {
    data () {
        return {}
    },
    computed : {
      // storeのstateをthis.squareで指定できるよう設定
      ...mapState([
        'hashtagDivisionId'
      ]),
      home : function () {
        return this.Global.hashtag_division_id.home
      },
      official : function () {
        return this.Global.hashtag_division_id.official
      },
      free : function () {
        return this.Global.hashtag_division_id.free
      },
      active : function () {
        return this.Global.hashtag_division_id.active
      }
    },
    methods : {
      // mutationsのメソッドをthis.xxxで使用できるように設定
    ...mapMutations([
      'setHashtagDivisionId',
      ]),

      setDivisionId : function (hashtagDivisionId) {
        this.$router.push({ path : '/square'}, () => {})
        this.setHashtagDivisionId(hashtagDivisionId)
      }
    }
}
</script>