<template>
  <nav class="main">
    <ul>
      <li>
        <a class="home" href="" @click.prevent="setDivisionId(1)" :class="[ {current : circleHashtagDivisionId == home } ]">
          <div>
            <svg version="1.1" id="icon-home" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 27 22.5" style="enable-background:new 0 0 27 22.5;" xml:space="preserve">
              <path class="icon-home" d="M23,11v11.5h-7v-6.5h-5v6.5H3.9V11H0L13.5,0L27,11L23,11z"></path>
            </svg>
          </div>
        </a>
      </li>
      <li><a href="" @click.prevent="setDivisionId(2)" :class="[ {current : circleHashtagDivisionId == official } ]"><div>公式な話題</div></a></li>
      <li><a href="" @click.prevent="setDivisionId(3)" :class="[ {current : circleHashtagDivisionId == free } ]"><div>自由な話題</div></a></li>
      <li class="action">
        <div class="hiddenside" v-show="Boolean(circle.publish) && Boolean(memberFlg) && circleModePublic">
          <a href="" @click.prevent="changeMode()"><span>内部モード</span></a>
        </div>
        <div class="hiddenside-close" v-show="Boolean(circle.publish) && Boolean(memberFlg) && !circleModePublic">
          <a href="" @click.prevent="changeMode()"><span>外部モード</span></a>
        </div>
        <div class="join"  v-show="Boolean(loginFlg) && !Boolean(memberFlg) && !Boolean(isSubscribed)"><a href="" @click.prevent="subscribe()"><span>参加する</span></a></div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props:{
    circle : {
      type: Object,
      required : true
    },
    memberFlg : {
      type : Boolean,
      required : true
    },
  },
  data () {
      return {
        isSubscribed : false,
      }
  },
  computed : {
    // storeのstateをthis.squareで指定できるよう設定
    ...mapState([
      'loginFlg',
      'account',
      'circleHashtagDivisionId',
      'circleMode'
    ]),
    home : function () {
      return this.Global.hashtag_division_id.home
    },
    official : function () {
      return this.Global.hashtag_division_id.official
    },
    free : function () {
      return this.Global.hashtag_division_id.free
    },
    circleModePublic : function () {
      return this.circleMode === this.Global.circle_mode.public
    },
    circleModePrivate : function () {
      return this.circleMode === this.Global.circle_mode.private
    },

  },
  mounted : function () {
    if (this.loginFlg) this.getCircleRequest()

    if (this.circle.publish == false) this.setCircleMode(this.Global.circle_mode.private)

  },
  watch : {
    loginFlg () {
      this.getCircleRequest()
    },    
  },
  methods : {
    // mutationsのメソッドをthis.xxxで使用できるように設定
    ...mapMutations([
      'setCircleHashtagDivisionId',
      'setCircleMode',
      'setClientErrors',
    ]),
    getCircleRequest : function () {
      this.axios.post(this.Global.url.api_circle_requests_show, {
        circle_id : this.circle.id,
        account_id : this.account.id
      }).then( res => {
        if (res.data.status == this.Global.api_status.success) {
          this.isSubscribed = res.data.circle_request != null
          return
        }
      } ).catch( error => {
        console.log(error)
      } ).finally( () => {
      })
    },
    setDivisionId : function (circleHashtagDivisionId) {
      this.$router.push({ path : '/circle/time-line/' + this.circleMode + '/' + this.circle.id}, () => {})
      this.setCircleHashtagDivisionId(circleHashtagDivisionId)
    },
    changeMode : function () {
      this.setCircleMode(this.circleMode == this.Global.circle_mode.public ? this.Global.circle_mode.private : this.Global.circle_mode.public)
      this.$router.push({ path : '/circle/time-line/' + this.circleMode + '/' + this.circle.id }, () => {})
    },
    subscribe : function () {
      let params = {
        circle_id : this.circle.id,
        account_id : this.account.id
      }
      this.axios.post(this.Global.url.api_circle_requests_create, params)
      .then(res=>{
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.info("completed")
          return 
        }
        this.$toasted.error("error occured")
        console.dir(res.data.errors)
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/time-line/section-hashtag-division.vue::subscribe", params: params, errorMessage:error})
      })
    }
  }
}
</script>