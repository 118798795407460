import Global from '../constant'
import axios from 'axios'
var account_follows = {
  show: async function() {
    alert(Global.url.api_account_follows_show)
  },
  search: async function(accountId) {
    let params = {  account_id : accountId }
    return await axios.post(Global.url.api_account_follows_search, params)
  },
  create: async function(accountId, followAccountId) {
    let params = {
      account_id : accountId,
      follow_account_id : followAccountId,
    }
    return await axios.post(Global.url.api_account_follows_create, params)
  },
  update: async function(id, blockFlg) {
    alert(Global.url.api_account_follows_update)
    let params = {
      id : id,
      block_flg : blockFlg,
    }
    return await axios.post(Global.url.api_account_follows_update, params)
  },
  delete: async function(accountId) {
    return await axios.delete(Global.url.api_account_follows_delete + "/" + accountId)
  },
  followersSearch: async function() {
    alert(Global.url.api_account_followers_search)
  },
}

export default account_follows