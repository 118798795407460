<template>
  <div class="box box-follow">
    <div class="title">
      <h3>フォローしている</h3>
      <div class="modal-wrap">
        <input id="trigger-2" type="checkbox">
        <div class="modal-overlay">
          <label class="modal-trigger" for="trigger-2"></label>
          <div class="modal-content">
            <label class="close-button" for="trigger-2">&#x2716;&#xFE0F;</label>
            <h3>あなたがフォロー中 {{accountFollows.length}}人</h3>
            <ul>
              <li v-for="(accountFollow, index) in accountFollows" :key="'account_'+index">
                <a href="">
                  <div class="image">
                    <c-account-image :has-img = "accountFollow.follow_account.has_img" :account-id = "accountFollow.follow_account.id" :account-name = "accountFollow.follow_account.last_name + accountFollow.follow_account.first_name" />
                  </div><span>{{accountFollow.follow_account.last_name + accountFollow.follow_account.first_name}}さん</span>
                </a>
                <div class="unfollow" v-if="isMyspace()"><span @mousedown="clearAccountFollow(accountFollow.id)">フォロー解除</span><span>フォロー中</span></div>
              </li>
              <li v-for="(circleFollow, index) in circleFollows" :key="'circle_'+index">
                <a href="">
                  <div class="image">
                    <c-circle-image :has-img = "circleFollow.follow_circle.has_img" :circle-id = "circleFollow.follow_circle.id" :circle-name = "circleFollow.follow_circle.circle_name" />
                  </div><span>{{circleFollow.follow_circle.circle_name}}</span>
                </a>
                <div class="unfollow" v-if="isMyspace()"><span @mousedown="clearCircleFollow(circleFollow.id)">フォロー解除</span><span>フォロー中</span></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <label class="open-button" for="trigger-2">すべて見る</label>
    </div>
    <ul>
      <li v-for="(limitedFollow, index) in limitedAccountFollows" :key="'account_'+index">
        <c-account-anchor-image :has-img = "limitedFollow.follow_account.has_img" :account-id = "limitedFollow.follow_account.id" :account-name = "limitedFollow.follow_account.last_name + limitedFollow.follow_account.first_name" v-tooltip="limitedFollow.follow_account.last_name + limitedFollow.follow_account.first_name" />
      </li>
      <li v-for="(limitedFollow, index) in limitedCircleFollows" :key="'circle_'+index">
        <c-circle-anchor-image :has-img = "limitedFollow.follow_circle.has_img" :circle-id = "limitedFollow.follow_circle.id" :circle-name = "limitedFollow.follow_circle.circle_name" v-tooltip="limitedFollow.follow_circle.circle_name" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  props : {
    targetAccount : {
      type : Object,
      required : false
    }
  },
  data () {
    return {}
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
      'accountFollows',
      'circleFollows',
    ]),
    limitedAccountFollows : function () {
      return _.take(this.accountFollows, this.Global.limited_follows_followers_count)
    },
    limitedCircleFollows : function () {
      return _.take(this.circleFollows, this.Global.limited_follows_followers_count)
    }
  },
  methods : {
    ...mapMutations([
      'setFollowCount',
      'setAccountFollows',
      'setCircleFollows',
    ]),
    clearAccountFollow : function (accountFollowId) {
      this.axios.delete(this.Global.url.api_account_follows_delete + "/" + accountFollowId).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          let newFollows = _.filter(this.accountFollows, r => r.id !== accountFollowId)
          this.setAccountFollows(newFollows)
          return
        }
        console.dir(res.data.errors)
        this.$toasted.error("failed")
        this.setClientErrors({func:"myspace/follows.vue::clearAccountFollow", params: {}, errorMessage:JSON.stringify(res.data)})
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"myspace/follows.vue::clearAccountFollow", params: {}, errorMessage:error})
      })
    },
    clearCircleFollow : function (followId) {
      this.axios.delete(this.Global.url.api_circle_follows_delete + "/" + followId).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          let newFollows = _.filter(this.circleFollows, r => r.id !== followId)
          this.setCircleFollows(newFollows)
          return
        }
        console.dir(res.data.errors)
        this.$toasted.error("failed")
        this.setClientErrors({func:"myspace/follows.vue::clearCircleFollow", params: {}, errorMessage:JSON.stringify(res.data)})
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"myspace/follows.vue::clearCircleFollow", params: {}, errorMessage:error})
      })
    },
    isMyspace : function () {
      if (!this.loginFlg) return false
      if (!this.targetAccount) return false
      if (!this.account) return false
      return this.targetAccount.id == this.account.id
    }
  }
}
</script>