<template>
  <div class="box box-profile">
    <div class="cover">
      <div class="image" vo-show="Boolean(loginFlg)">
        <c-account-image :has-img="hasImg" :account-id="accountId" :account-name="accountName" />
      </div>
    </div>
    <h1>{{accountName}}さんの<br />マイスペース</h1>
    <div class="counter">
      <div class="follow"><a href="">
          <div>フォロー</div>{{followCount}}</a></div>
      <div class="follower"><a href="">
          <div>フォロワー</div>{{followerCount}}</a></div>
    </div>
    <div class="button">
      <a href="" v-if="isAvailable()" @click.prevent="follow">フォロー!!</a>
      <router-link to="/settings" v-if="loginFlg && targetAccount.id == account.id">プロフィール編集</router-link>
    </div>
    <div class="profile" v-if="account != null">
      <input v-if="moreButtonFlg" class="more" type="checkbox" id="trigger-more">
      <label v-if="moreButtonFlg" class="more-btn" for="trigger-more"></label>
      <p>{{profile}}</p>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  props:{
    targetAccount : {
      type : Object,
      required : false
    }
  },
  data () {
    return {
      accountId : null,
      accountName : null,
      hasImg : false,
      profile : '',
      moreButtonFlg: false
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
      'followCount',
      'followerCount',
      'accountFollows',
    ]),
  },
  watch : {
    targetAccount : function (newTargetAccount) {
      this.accountId   = newTargetAccount.id
      this.accountName = newTargetAccount.last_name + newTargetAccount.first_name
      this.hasImg      = newTargetAccount.has_img
      this.profile     = newTargetAccount.profile

      this.moreButtonFlg = newTargetAccount.profile && newTargetAccount.profile.length > 120 ? true : false
    },
  },
  methods: {
    ...mapMutations([
      'setAccountFollows',
      'setClientErrors',
    ]),
    isAvailable: function() {
      if (this.account == null) return false
      if (this.targetAccount.id == this.account.id) return false
      if (_.filter(this.accountFollows, row => row.follow_account_id === this.targetAccount.id).length == 0) return true
      if (this.targetAccount.id != this.account.id) return false
      return true
    },
    follow: function() {
      let params = {
        account_id : this.account.id,
        follow_account_id : this.targetAccount.id,
      }
      this.axios.post(this.Global.url.api_account_follows_create, params)
      .then(res => {
        if(res.data.status == this.Global.api_status.success){
          this.$toasted.success("success")
          let newFollows = this.accountFollows.concat([res.data.account_follow])
          this.setAccountFollows(newFollows)
          return
        }
        this.$toasted.error("failed")

      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"myspace/profile.vue::follow", params: params, errorMessage:error})
      })
    },
  },
  components : {}
}
</script>