<template>
  <div class="box box-follower">
    <div class="title">
      <h3>フォローされている</h3>
      <div class="modal-wrap">
        <input id="trigger" type="checkbox">
        <div class="modal-overlay">
          <label class="modal-trigger" for="trigger"></label>
          <div class="modal-content">
            <label class="close-button" for="trigger">&#x2716;&#xFE0F;</label>
            <h3>あなたのフォロワー {{followers.length}}人</h3>
            <ul>
              <li v-for="(followAccount, index) in followers" :key="index">
                <a href="">
                  <div class="image">
                    <c-account-image :has-img = "followAccount.follow_account.has_img" :account-id = "followAccount.follow_account.id" :account-name = "followAccount.follow_account.last_name + followAccount.follow_account.first_name" />
                  </div><span>{{followAccount.follow_account.last_name + followAccount.follow_account.first_name}}さん</span>
                </a>


                <div class="action" v-show="isMyspace() && follows[followAccount.follow_account.id] == null"><span @mousedown="follow(followAccount.follow_account.id)">フォロー</span></div>
                <div class="unfollow" v-show="isMyspace() && follows[followAccount.follow_account.id] != null"><span @mousedown="unFollow(followAccount.follow_account.id)">フォロー解除</span><span>フォロー中</span></div>
                
                <div class="action" v-show="isMyspace() && !followAccount.block_flg"><span @mousedown="updateAccountFollow(followAccount.id, true)">ブロック</span></div>
                <div class="unfollow" v-show="isMyspace() && followAccount.block_flg"><span @mousedown="updateAccountFollow(followAccount.id, false)">ブロック解除</span><span>ブロック中</span></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <label class="open-button" for="trigger">すべて見る</label>
    </div>
    <ul>
      <li v-for="(limitedFollower, index) in limitedFollowers" :key="index">
        <c-account-anchor-image :has-img = "limitedFollower.follow_account.has_img" :account-id = "limitedFollower.follow_account.id" :account-name = "limitedFollower.follow_account.last_name + limitedFollower.follow_account.first_name" v-tooltip="limitedFollower.follow_account.last_name + limitedFollower.follow_account.first_name" />
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
import _ from 'lodash'
import account_follows from '../../js/api/account-follows'

export default {
  props : {
    circleId : {
      type : Number,
      required : false
    },
    targetAccount : {
      type : Object,
      required : false
    }
  },
  data () {
    return {
      followers : [],
      follows : [],
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account'
    ]),
    limitedFollowers : function () {
      return _.take(this.followers, this.Global.limited_follows_followers_count)
    }
  },
  watch : {
    targetAccount : function () {
      this.getFollowers()
      this.getFollows()
    }
  },
  // mounted : function () {
  //   this.loadign = false
  //   if (this.loginFlg) this.getFollowers()      
  // },
  methods : {
    ...mapMutations([
      'setFollowerCount',
      'setClientErrors'
    ]),
    getFollowers : function () {
      this.axios.post(this.getUrl(), this.getParameters())
      .then (res => {
        if (res.data.status == this.Global.api_status.success) {
          this.followers = this.followers.concat(res.data.account_follows)
        }
      })
      .catch (error => { console.log(error) })
      .finally( () => { 
        this.setFollowerCount(this.followers.length)
      } )
    },
    getParameters : function () {
      let params = {}
      if (this.circleId != null) {
        params['circle_id'] = this.circleId
      } else {
        params['account_id'] = this.targetAccount.id
      }
      return params;
    },
    getUrl : function () {
      if (this.circleId != null) return this.Global.url.api_circle_followers_search
      return this.Global.url.api_account_followers_search
    },

    getFollows : function () {
      let ret = account_follows.search(this.targetAccount.id)
      ret.then (res => {
        if (res.data.status == this.Global.api_status.success) {
          this.follows = _.mapKeys(res.data.account_follows, 'follow_account_id')          
        }
      })
      .catch (error => { console.log(error) })
    },

    follow : function (followAccountId) {
      let params = {
        account_id : this.targetAccount.id,
        follow_account_id : followAccountId,
      }
      let ret = account_follows.create(this.targetAccount.id, followAccountId)
      ret.then(res => {
        if(res.data.status == this.Global.api_status.success){
          this.$toasted.success("success")
          let row = {
            id                : res.data.account_follow.id,
            account_id        : res.data.account_follow.account_id,
            follow_account_id : res.data.account_follow.follow_account_id,
            block_flg         : res.data.account_follow.block_flg,
          }
          this.follows[followAccountId] = row
          let _followers = this.followers
          this.followers = []
          this.followers = _followers
          return
        }
        console.dir(res.data.errors)
        this.$toasted.error("failed")

      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/followers.vue::follow", params: params, errorMessage:error})
      })
    },
    unFollow : function (accountId) {
      let accountFollow = this.follows[accountId]
      let ret = account_follows.delete(accountFollow.id)
      ret.then(res => {
        if(res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          delete this.follows[accountId]
          let _followers = this.followers
          this.followers = []
          this.followers = _followers          
          return
        }
        console.dir(res.data.errors)
        this.$toasted.error("failed")
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({
          func:"common/followers.vue::unFollow",
          params: {id:accountFollow.id},
          errorMessage:error
        })
      })
    },

    updateAccountFollow:function (id, blockFlg) {
      let ret = account_follows.update(id, blockFlg)
      ret.then(res => {
        if(res.data.status == this.Global.api_status.success){
          this.$toasted.success("success")
          _.forEach(this.followers, row=>{
            if (id === row.id) row.block_flg = blockFlg
          })
          let _followers = this.followers
          this.followers = []
          this.followers = _followers          
          return
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({
          func:"common/followers.vue::unBlock",
          params: { id : id, block_flg : blockFlg},
          errorMessage:error
        })
      })
    },
    isMyspace : function () {
      if (!this.loginFlg) return false
      if (this.circleId != null) return false
      if (!this.targetAccount) return false
      if (!this.account) return false
      return this.targetAccount.id == this.account.id
    }
  }
}
</script>