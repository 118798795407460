import _ from 'lodash'
import Global from '../../constant.js'

var mentionUtils = {
  getHereState : function () {
    return [
      {
        value: "here",
        lastName: 'here',
        firstName: '',
        nickname: '',
        name: 'here',
      }
    ]
  },
  getMentionStates : function (row) {
    let _nickname = "(" + row.nickname + ")"
    return {
      // value : row.last_name+row.first_name + ":" + row.id,
      value: row.last_name + row.first_name + _nickname,
      lastName: row.last_name,
      firstName: row.first_name,
      nickname: _nickname,
      name: row.last_name + row.first_name,
      groupFlg: false
    }
  },
  getMentionGroupsStates : function (row) {
    return {
      value: row.name + ":" + row.id,
      name: row.name,
      count: row.group_members.length,
      groupFlg: true
    }
  },
  getMentionHashtagStates : function (entryDivisionId, row) {
    let _subName = "(" + (row.official ? '公式' : '自由') + ")" 
    if (entryDivisionId === Global.entry_division_id.square) {
      return {
        value: row.name + _subName,
        id: row.id,
        name: row.name,
        subName: _subName,
        official: Boolean(row.official)
      }  
    }

    if (entryDivisionId === Global.entry_division_id.circle
        || entryDivisionId === Global.entry_division_id.private_circle) {
      if (row.mst_topic_name == null && row.mst_topic_id == null) return {
            value: row.name + ":" + row.id,
            id: row.id,
            name: row.name,
            subName: _subName,
            official: Boolean(row.official)
      }
      
      if (row.mst_topic_name != null && row.mst_topic_id != null) return {
        value: row.mst_topic_name + _subName,
        id: row.mst_topic_id,
        name: row.mst_topic_name,
        subName: _subName,
        official: Boolean(row.official)
      }
    }

    if (entryDivisionId === Global.entry_division_id.myspace) {
      return {
        value: row.name + _subName,
        id: row.id,
        name: row.name,
        subName: _subName,
        official: Boolean(row.official)
      }  
    }
  },

  getDestinationsUrl : function (entryDivisionId) {
    if (entryDivisionId === Global.entry_division_id.square) return Global.url.api_accounts_all
    if (entryDivisionId === Global.entry_division_id.circle) return Global.url.api_circle_members_search
    if (entryDivisionId === Global.entry_division_id.private_circle) return Global.url.api_circle_members_search
    if (entryDivisionId === Global.entry_division_id.myspace) return Global.url.api_account_followers_search
  },

  getDestinationParameters : function (entryDivisionId, circleId, accountId) {
    if (entryDivisionId === Global.entry_division_id.square)  return {}
    if (entryDivisionId === Global.entry_division_id.circle) return { circle_id : circleId, official_circle : true }
    if (entryDivisionId === Global.entry_division_id.private_circle) return { circle_id : circleId, official_circle : false }
    if (entryDivisionId === Global.entry_division_id.myspace) return {  account_id : accountId  }
  },

  getApiResponse : function (res) {
    if (res.data.accounts) return res.data.accounts
    if (res.data.circle_members) return _.map(res.data.circle_members, (n) =>{ return { id:n.account_id, last_name : n.account_last_name, first_name: n.account_first_name, nickname : n.account_nickname, mail : n.account_mail} })
  },

  getHashtagsUrl : function (entryDivisionId) {
    if (entryDivisionId === Global.entry_division_id.square) return Global.url.api_mst_topics_search
    if (entryDivisionId === Global.entry_division_id.circle) return Global.url.api_circle_topics_search
    if (entryDivisionId === Global.entry_division_id.private_circle) return Global.url.api_circle_topics_search
    if (entryDivisionId === Global.entry_division_id.myspace) return Global.url.api_mst_topics_search
  },

  getHashtagsParameters : function (entryDivisionId, hashtagDivisionId, accountId, circleId) {
    let params = {}
    let _official = null

    if (hashtagDivisionId == Global.hashtag_division_id.official){
      _official = true
    } else if (hashtagDivisionId == Global.hashtag_division_id.free) {
      _official = false
    }


    if (entryDivisionId === Global.entry_division_id.square) {
      if (_official != null) params['official'] = _official
      return params
    }

    if (entryDivisionId === Global.entry_division_id.myspace) {
      params['account_id'] = accountId
      if (_official != null) params['official'] = _official
      return params
    } 
    
    
    if (entryDivisionId === Global.entry_division_id.circle) {
      params['circle_id'] = circleId
      params['official_circle'] = true
      if (_official != null) params['official'] = _official
      return params
    }

    if (entryDivisionId === Global.entry_division_id.private_circle) {
      params['circle_id'] = circleId
      params['official_circle'] = false
      if (_official != null) params['official'] = _official
      return params
    } 

  },


  isEpty : function (text, accounts, groups, hashtags) {
    _.map(accounts, (account) => {
      
      // let key = '@' + account.last_name + account.first_name + ":" + account.id
      let key = '@' + account.last_name + account.first_name + '(' + account.nickname + ')'
      text = text.replace(key, '')
    })
    
    groups && _.map(groups, (group) => {
      let key = '@' + group.name + ":" + group.id
      text = text.replace(key, '')
    })

    hashtags && _.map(hashtags, (row) => {
      let key = '#' + row.name + ':' + row.id
      text = text.replace(key, '')
    })

    return text.trim() ? false : true
  },
  getDestinationIdsFromText : function (text, accounts, groups) {
    let _destinationIds = {}
    let _all = this.getAllDestFlg(text)

    _.map(accounts, (account) => {
      // let key = '@'+account.last_name+account.first_name + ":" + account.id
      let key = '@' + account.last_name + account.first_name + '(' + account.nickname + ')'
      if (_all) {
        _destinationIds[account.id] = account.id
      } else if ( text.indexOf(key) != -1 ) {
        _destinationIds[account.id] = account.id
      }
    })

    _.map(groups, (group) => {
      let key = '@' + group.name + ":" + group.id
      
      if ( text.indexOf(key) != -1 ) {
        _.map(group.group_members, (member) => {
          _destinationIds[member.id] = member.id
        })
      }
    })
    return Object.keys(_destinationIds)
  },
  
  getHashtagIdsFromText : function (entryDivisionId, text, hashtags) {
    let _hashtagIds = {}
    _.map(hashtags, (row) => {
      let states = this.getMentionHashtagStates(entryDivisionId, row)
      if ( text.indexOf(states.value) != -1 ) {
        _hashtagIds[states.id] = states.id
      }
    })
    return Object.keys(_hashtagIds)
  },

  getGroupIdsFromText : function (text, groups) {
    let _groupIds = {}
    _.map(groups, (group) => {
      let key = '@'+ group.name + ":" + group.id
      
      if ( text.indexOf(key) != -1 ) {
        _groupIds[group.id] = group.id
      }
    })
    return Object.keys(_groupIds)
  },

  deleteAccountIdFromText : function (text, destinations, groups) {
    // _.forEach(destinations, (row) => {
    //   text = text.replaceAll('@'+row.last_name + row.first_name + ':' + row.id, '@' + row.last_name + row.first_name)
    // })

    _.forEach(groups, (row) => {
      text = text.replaceAll('@' + row.name + ':' + row.id, '@' + row.name)
    })

    return text
  },
  // deleteHashtagIdFromText : function (entryDivisionId, text, hashtags) {
  //   _.forEach(hashtags, (row) => {
  //     let states = this.getMentionHashtagStates(entryDivisionId, row)
  //     text = text.replace('#' + states.value, '#' + states.name)
  //   })
  //   return text
  // },
  // setDestinationIdsToText : function (text) {
  //   // _.map(destinations, destination => {
  //   //   let key = '@' + destination.last_name + destination.first_name
  //   //   let replaceKey = key + ":" + destination.id
  //   //   text = text.replace(key, replaceKey)
  //   // })
  //   return text
  // },
  // setHashtagIdsToText: function (entryDivisionId, text, hashtags) {
  //   _.forEach(hashtags, (row) => {
  //     let states = this.getMentionHashtagStates(entryDivisionId, row)
  //     console.log(states)
  //     text = text.replace('#' + states.name, '#' + states.value)
  //   })
  //   return text
  // },
  setGroupIdsToText : function (text, destinationGroups) {
    _.map(destinationGroups, group => {
      let key = '@' + group.name
      let replaceKey = key + ":" + group.id
      text = text.replace(key, replaceKey)
    })
    return text
  },
  getAllDestFlg : function (text) {
    return text.indexOf('@here') != -1 
  },
  createGroupMentionToMembersMentionConverter: function (groups) {
    let res = {}
    _.forEach(groups, row => {
      res["@" + row.name + ":" + row.id] = _.map(row.group_members, member => '@' + this.getMentionStates(member).value).join(" ")
    })
    return res
  },
  changeGroupToMembers : function (text, groupToAccounts) {
    let res = text
    _.forEach(Object.keys(groupToAccounts), key => {
      res = res.replaceAll(key, groupToAccounts[key])
    })
    return res
  },
}

export default mentionUtils
