<template>
  <div id="circle-add">
    <div id="container">
      <div class="tabs">
        <ul>
          <li @click="setTabId(4)" :class="[{ current : tabId == circle }]" v-show="circleId > 0">基本設定</li>
          <li @click="setTabId(5)" :class="[{ current : tabId == members }]" v-show="circleId > 0">メンバー</li>
          <li @click="setTabId(6)" :class="[{ current : tabId == hashtags }]" v-show="circleId > 0">その他</li>
        </ul>
      </div>
      <c-circle-edit   v-show="tabId == circle"   :circle-id="circleId" :save-flg="saveFlg" />
      <c-members-edit  v-show="tabId == members"  :circle-id="circleId" :save-flg="saveFlg" />
      <c-hashtags-edit v-show="tabId == hashtags" :circle-id="circleId" :save-flg="saveFlg" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import CircleEdit from './circle-edit'
import MembersEdit from './members-edit'
import HashtagsEdit from './hashtags-edit'

export default {
  data () {
    return {
      saveFlg : false,
      circleId : null,
      tabId : null,
      errors : {}
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
      'circleEditCircleId',
      'circleEditTabId'
    ]),
    circle : function () {
      return this.Global.settings_tab_id.circle
    },
    members : function () {
      return this.Global.settings_tab_id.members
    },
    hashtags : function () {
      return this.Global.settings_tab_id.hashtags
    },
  },

  created () {
    this.circleId = Number(this.$route.params.circleId)
  },
  watch : {
    circleEditCircleId : function(newValue) {
      // circle-editで保存されサークルIDがセットされた場合にmainのステートを更新（新規作成モード→更新モードに切替える）
      this.circleId = newValue
    },
    circleEditTabId : function (newValue) {
      this.setTabId(newValue)
    }
  },
  mounted () {
    this.tabId = this.circleEditTabId
  },
  methods : {
    ...mapMutations([
      'setClientErrors',
      'setCircleEditTabId'
    ]),
    setTabId : function (tabId) {
      this.tabId = tabId
      this.setCircleEditTabId(tabId)
    },
  },
  components : {
    'c-circle-edit' : CircleEdit,
    'c-members-edit' : MembersEdit,
    'c-hashtags-edit' : HashtagsEdit,
  }
}
</script>