<template>
  <nav class="tab">
    <ul>
      <li>
        <a class="home" href="" @click.prevent="setDivision(1)" :class="[ {current : myspaceDivisionId == home } ]">
          <div>
            <svg version="1.1" id="icon-home" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 27 22.5" style="enable-background:new 0 0 27 22.5;" xml:space="preserve">
              <path class="icon-home" d="M23,11v11.5h-7v-6.5h-5v6.5H3.9V11H0L13.5,0L27,11L23,11z"></path>
            </svg>
          </div>
        </a>
      </li>
      <li>
        <a href="" @click.prevent="setDivision(2)" :class="[ {current : myspaceDivisionId == my } ]"><div>自分の発言</div></a>
      </li>
      <li>
        <a href="" @click.prevent="setDivision(3)" :class="[ {current : myspaceDivisionId == reply } ]"><div>あなたへの返信</div></a>
      </li>
      <li>
        <a href="" @click.prevent="setDivision(4)" :class="[ {current : myspaceDivisionId == mark } ]"><div>しるし</div></a>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed : {
    ...mapState([
      'myspaceDivisionId',
      'account'
    ]),
    home : function () { return this.Global.myspace_division_id.home },
    my : function () { return this.Global.myspace_division_id.my },
    reply : function () { return this.Global.myspace_division_id.reply },
    mark : function () { return this.Global.myspace_division_id.mark },
  },
  methods : {
    ...mapMutations([
      'setMyspaceDivisionId',
      'setMarkId'
    ]),
    setDivision : function (divisionId) {
      this.setMyspaceDivisionId(divisionId)
      this.setMarkId(null)
    }
  }
}
</script>