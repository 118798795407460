<template>
  <div class="setting-group">
    <h4>メンバーを招待</h4>
    <h5>相手が承認した場合のみサークルメンバーとなります</h5>
    <div class="item">
      <div class="member-add">
        <div class="search">
          <button class="member-select" @click="toggleMembersOverlay">メンバーをさがす</button>
          <ul>
            <li v-for="(inviteMember, index) in inviteMembers" :key="index" @mousedown="inviteCancel(inviteMember)">{{inviteMember.account_name}}</li>
          </ul>
          <div class="focusborder"></div>
        </div>
        <div class="action-overlay" v-show="membersOverlay">
          <div class="container">
            <div class="item">
              <!-- <input type="text" placeholder="名前"><span class="focusborder"></span> -->
              <mentionable
                :keys="keys"
                :items="items"
                :limit="mentionLimit"
                insert-space>
                <textarea placeholder="@名前を入力してください" v-model="text"></textarea>
                <template #no-result>
                  <div class="dim">
                    No result
                  </div>
                </template>
                <template #item-@="{ item }">
                  <div class="destination">
                    {{ item.lastName + item.firstName }}
                    <span class="dim">
                      {{ item.nickname }}
                    </span>
                  </div>
                </template>
              </mentionable>
            </div>
            <div class="action">
              <button class="cancel" @click="toggleMembersOverlay">キャンセル</button>
              <button class="confirm" @click="set">招待</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations} from 'vuex'
import _ from 'lodash'
import mentionUtils from '../../../../js/common/entry/MentionUtils'

export default {
  props:{
    circleId : {
      type: Number,
      required:true
    },
    saveFlg : {
      type: Boolean,
      required:true
    }
  },
  data : function(){
    return {
      circleInvites : [],
      membersOverlay : false,
      text           : null,
      keys           : ['@'],
      mentionLimit   : this.Global.vue_mention_limit,
      accounts       : null,
      items          : [],
    }
  },
  computed : {
    inviteMembers : function () {
      //pendings
      return _.filter(this.circleInvites, (row) => { return !row.deleteFlg})
    },
  },
  watch:{
    // circleId : function () {
    //   this.getCircleInvites()
    // },
    saveFlg : function (newValue) {
      if (newValue) {
        this.save()
        this.$emit('toggle-save-flg')
      }
    },
    circleInvites : function () {
      let _filteredAccounts = this.filterAccounts()
      this.items = _.map(_filteredAccounts, (row) => { return mentionUtils.getMentionStates(row) })
    },
    accounts : function () {
      let _filteredAccounts = this.filterAccounts()
      this.items = _.map(_filteredAccounts, (row) => { return mentionUtils.getMentionStates(row) }) 
    }
  },
  created: function () {
    this.getCircleInvites()
    this.getAccounts()
  },
  mounted : function () {
      _.forEach(document.getElementsByClassName("mentionable"), (row)=>{row.removeAttribute('style')})
      this.getAccounts()
  },
  methods : {
    ...mapMutations([
      'setClientErrors'
    ]),
    toggleMembersOverlay : function () {
      this.membersOverlay = !this.membersOverlay
    },
    getCircleInvites : function () {
      let params = { circle_id : this.circleId }

      this.axios.post(this.Global.url.api_circle_invites_search, params).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.circleInvites = res.data.circle_invites
          return
        }
        this.$toasted.error("error occured!")
        this.setClientErrors({func:"circle/edit/invite-members.vue::getCircleInvites", params: params, errorMessage:"circle_inviteデータ取得失敗 : " + JSON.stringify(res.data.errors)})
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/invite-members.vue::get", params: params, errorMessage:error})
      })
    },
    inviteCancel : function (inviteMember) {
      // circleInvitesにdelete_flgを立てる
      this.circleInvites = _.map(this.circleInvites, (row) => {
        if (row.account_id == inviteMember.account_id) {
          row.deleteFlg = true
        }
        return row
      })
      // itemsに追加する
      this.accounts = _.map(this.accounts, (row) => {
        if (row.id == inviteMember.account_id) {
          row.invitedFlg = false
        }
        return row
      })
    },
    save : function () {
      _.forEach (this.circleInvites, (row)=>{
        if(row.id == null && row.deleteFlg == true){
          // console.log("do nothing")
        } else if (row.id == null) {
          this.insert(row)
        } else if (row.deleteFlg == true) {
          this.delete(row)
        }
      })
    },
    insert : function (row) {
      this.axios.post(this.Global.url.api_circle_invites_create, row)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.circleInvites = _.map(this.circleInvites, (invites) => {
            if (invites.account_id == row.account_id) {
              invites.id = res.data.circle_invite.id
            }
            return invites
          })
          return
        }
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/invite-members.vue::insert", params: row, errorMessage:"circle_invite登録失敗 : " + JSON.stringify(res.data.errors)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/invite-members.vue::insert", params: row, errorMessage:error})

      })
    },
    delete : function (row) {
      let params = {}
      this.axios.delete(this.Global.url.api_circle_invites_delete+"/"+row.id)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.circleInvites = _.filter(this.circleInvites, (circleInvite) => { return !circleInvite.deleteFlg })
          return
        }
        console.dir(res.data.errors)
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/invite-members.vue::delete", params: params, errorMessage:"circle_invite削除失敗 : " + JSON.stringify(res.data.errors)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/invite-members.vue::delete", params: params, errorMessage:error})
      })
    },
    getAccounts : function () {
      let params = {delete_flg : false}
      this.axios.post(this.Global.url.api_accounts_all, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          this.accounts = this.setDispFlg(res.data.accounts)
          let _filteredAccounts = this.filterAccounts()
          this.items = _.map(_filteredAccounts, (row) => { return mentionUtils.getMentionStates(row) })
          return
        }
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/invite-members.vue::getAccounts", params: params, errorMessage:error})
      })
    },
    setDispFlg : function (accounts) {
      let inviteAccountIds = {}
      _.map(this.circleInvites, (row) => {
        return inviteAccountIds[row.account_id] = row.account_id
      })
      return _.map(accounts, (row) => {
        row.invitedFlg = inviteAccountIds[row.id] ? true : false
        return row
      })
    },
    filterAccounts: function () {
      return _.filter(this.accounts, (row) => { return !row.invitedFlg})
    },
    set : function () {
      this.circleInvites = this.circleInvites.concat(this.getAccountsFromText(this.text, this.accounts))
      this.text = null
      this.toggleMembersOverlay()
    },
    getAccountsFromText : function (text, accounts) {
      let _invites = []
      _.map(accounts, (account) => {
        let key = '@'+account.last_name + account.first_name
        if ( text.indexOf(key) != -1 ) {
          account.invitedFlg = true

          let _filterdCircleInvite = _.filter(this.circleInvites, (row) => { return row.account_id == account.id  })

          if (_filterdCircleInvite.length == 0) {
            _invites.push({
              id : null,
              circle_id : this.circleId,
              account_id : account.id,
              status : this.Global.circle_invite_status.pending,
              account_name : account.last_name+account.first_name,
              account_nickname : account.nickname
            })
          } else {
            this.circleInvites = _.map(this.circleInvites, (row) => {
              if (row.account_id == account.id) {
                row.deleteFlg = false
              }
              return row
            })
          }
        }
      })
      return _invites
    },
  },
}
</script>