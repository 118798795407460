<template>
  <div class="data">
    <c-label-circle-follow :follow-circle-id="circle.id" class="follow-position"/>
    <h3>サークル情報</h3>
    <p>{{activities}}</p>
    <div class="" v-if="isEdittable()">
      <router-link :to= "{ name:'circle/edit', params:{circleId: circle.id} }">編集する</router-link>
    </div>
    <h4>発足日</h4>
    <p>{{createdAt}}</p>
    <!--<h4>連絡先</h4>
    <p>{{address}}</p>
    <h4>ウェブサイト</h4><a :href="web">{{web}}</a>
    <h4>ソーシャルネットワーク</h4><a v-show="facebook != null" :href="facebook">facebook</a><a v-show="twitter" :href="twitter">Twitter</a><a v-show="line" :href="line">LINE</a></a>-->
    <div v-show="account != null && !addFlg"><a href="#" @click.prevent="addSidebar">サイドバーに追加</a></div>
    <div v-show="account != null && addFlg"><a href="#" @click.prevent="deleteSidebar">サイドバーから削除</a></div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'
import LabelCircleFollow from '../label-circle-follow'

export default {
  props : {
    circle : {
      type : Object,
      required:true
    },
    circleMembers:{
      type : Array,
      required : false
    }
  },
  data () {
    return {
      activities : null,
      createdAt : null,
      addFlg: false,
    }
  },
  computed:{
    ...mapState([
      'loginFlg',
      'account',
      'referCircles'
    ])
  },
  watch : {
    referCircles (newReferCircles) {
      let referCircles = _.filter(newReferCircles, c => c.id === this.circle.id)
      this.addFlg = referCircles.length > 0 
    },
  },

  created : function () {
    this.activities = this.circle.activities
    this.createdAt = this.circle.createdAt
  },
  mounted: function () {
      let referCircles = _.filter(this.referCircles, c => c.id === this.circle.id)
      this.addFlg = referCircles.length > 0
  },
  methods : {
    ...mapActions([
      'getReferCircles',
    ]),
    isEdittable : function () {
      if (!this.loginFlg) return false
      if (!this.account) return false
      if (this.circleMembers == null) return false
      return _.filter(this.circleMembers, (row)=>{  return this.account.id == row.account_id && row.admin_flg }).length > 0
    },
    addSidebar: function () {
      this.axios.post(this.Global.url.api_refer_circles_create, {
        account_id: this.account.id,
        circle_id: this.circle.id,
      }).then( res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          this.addFlg = true
          this.getReferCircles()
          return
        }
      } ).catch( error => {
        console.log(error)
      } )
    },
    deleteSidebar: function () {
      this.axios.post(this.Global.url.api_refer_circles_delete, {
        account_id: this.account.id,
        circle_id: this.circle.id,
      }).then( res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("success")
          this.addFlg = false
          this.getReferCircles()
          return
        }
      } ).catch( error => {
        console.log(error)
      } )
    },
  },
  components : {
    'c-label-circle-follow' : LabelCircleFollow,
  }
}
</script>