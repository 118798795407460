<template>
  <div id="register">
    <div id="container">
      <div class="dialog">
        <section><span>ステップ2/3</span>
          <h2>仮登録完了!</h2>
          <p>{{mail}}宛に確認メールを送信しました。<br>メール記載のURLから本登録を完了すると、全機能を使用できます。</p><router-link to="/square">>広場へ戻る</router-link><a href="">メールが届きませんか？</a>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
export default {
  data () {
    return {
      mail : null
    }
  },
  // computed: {
  //   ...mapState([// storeのステートであるloginFlgをthis.loginFlgで呼べるようにマッピング
  //     'temporaryAccount'
  //   ])
  // },
  mounted : function () {
    this.mail = this.$route.params.mail
  }
}
</script>