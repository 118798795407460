<template>
  <div class="action-overlay">
    <div class="container">
      <div class="item">
        <span v-for="(emoji, index) in emojis" :key="index"><img :src="emoji.src" class="emoji" @click="setResponse(emoji.pattern)" /></span>
      </div>
      <div class="action">
        <button class="cancel" @click="close">キャンセル</button>
        <!-- <button class="confirm" @click="set">選択</button> -->
      </div>
    </div>
  </div>  
</template>
<script>
import emojis from '../../js/emoji'

export default {
  data () {
    return {
      emojis:emojis
    }
  },
  methods:{
    setResponse: function (val) {
      this.$emit('add-emoji', val)
    },
    close: function () {
      this.$emit('add-emoji')
    }
  }
}
</script>