// サーバーからのメッセージキーを画面側のメッセージに変換する
var messages = {
   // validation error 系エラー => xxxxx_CONSTRAINT_FAILED
  'REQUIRED_CONSTRAINT_FAILED' : '必須入力です',
  'LENGTH_CONSTRAINT_FAILED' : '桁数オーバーです',
  'EMAIL_CONSTRAINT_FAILED' : '不正なmail形式です',
  'NUMERIC_CONSTRAINT_FAILED' : '不正な数値です',
  // 業務エラー => xxxxx_ERROR
  'AUTHENTICATE_ERROR' : '認証に失敗しました',
  'ALREADY_EXIST_ERROR' : '同一の情報が存在します',
  'AUTO_LOGIN_FAILED_ERROR' : 'オートログインに失敗しました',
  'NOT_EXIST_ERROR' : '存在しません',
  'RSS_READ_ERROR' : 'RSS読み込みに失敗しました。URLを確認してください',
  'ACCOUNT_ALREADY_DELETED' : 'すでに削除されてます',

}

export default messages