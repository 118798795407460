<template>
  <span class="topic tabaction" @mousedown="set">#{{hashtag.topic_name}}</span>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  props : {
    hashtag : {
      type : Object,
      required : true,
    },
    circleId : {
      type : Number,
      required : false,
    },
    officialCircle : {
      type: Boolean,
      required: false
    }
  },
  methods:{
    ...mapMutations([
      'setCircleMode',
      'setHashtagId',
      'setCircleHashtagId',
    ]),
    set: function() {
      if (this.circleId) {
        console.dir(this.hashtag)
        let circleMode = this.officialCircle === true ? this.Global.circle_mode.public : this.Global.circle_mode.private
        this.setCircleMode(circleMode)
        this.setCircleHashtagId(this.hashtag.mst_topic_id)
        this.$router.push({ path : '/circle/time-line/' + circleMode + '/' + this.circleId + '/' + this.hashtag.mst_topic_id })
        return
      }
      if (!this.circleId) {
        this.setHashtagId(this.hashtag.mst_topic_id)
        this.$router.push( { path : '/square/hashtag/' + this.hashtag.mst_topic_id }, () => {} )
        return
      }
    }
  }
}
</script>
<style>
  .tabaction {
    cursor: pointer;
  }
</style>