<template>
  <div id="register">
    <div id="container">
      <div class="dialog">
        <section><span>ステップ3/3</span>
          <h2>本登録</h2>
          <p>{{last_name+first_name}}さん最後のステップです!<br>本登録を完了して、ともつくネットを楽しみましょう!</p>
          
          <div class="item">
            <input type="password" placeholder="パスワード" v-model = "password"><span class="focusborder"></span>
          </div>
          <div class="item">
            <input class="button-blue" type="submit" value="パスワードを設定" @click="accoutCreate()">
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      password : null,
      errors : {},
      temp_account_id : null,
      temporaryAccount : null,
      last_name : null,
      first_name : null,
    }
  },

  computed: {
    // ...mapState([
    //   'temporaryAccount'
    // ])
  },
  mounted : function () {
    this.temp_account_id = this.$route.params.id
    this.temporaryAccountsShow()
  },
  methods : {
    ...mapMutations([
      'setLoginFlg',
      'setAccount',
      'setTemporaryAccount',
      'setClientErrors',
    ]),
    ...mapActions([
      'save',
    ]),
    temporaryAccountsShow : function () {
      this.axios.get(this.Global.url.api_temp_accounts_show + this.temp_account_id)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.temporaryAccount = res.data.temp_account
          this.last_name = this.temporaryAccount.last_name
          this.first_name = this.temporaryAccount.first_name
          this.$toasted.success('Hi!' + this.temporaryAccount.nickname)
          return
        }
        this.$toasted.error("再度仮登録を行ってください")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"registration/main.vue::temporaryAccountsShow", params: {}, errorMessage:error})
      })
    },
    accoutCreate : function () {
      if (!this.password) return
      // console.dir(this.temporaryAccount)
      let params = {
        last_name : this.temporaryAccount.last_name,
        first_name : this.temporaryAccount.first_name,
        nickname : this.temporaryAccount.nickname,
        mail : this.temporaryAccount.mail,
        password : this.password,
      }
      this.axios.post(this.Global.url.api_accounts_sign_in, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.success("completed")
          this.setLoginFlg(true)
          this.setAccount(res.data.account)
          this.setTemporaryAccount(null)
          this.save()

          this.$router.push( { path : '/square' }, () => {} )
          return
        }

        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        } 
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"registration/main.vue::accoutCreate", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"registration/main.vue::accoutCreate", params: params, errorMessage:error})
      })
    },
  },
  components : {}
}
</script>