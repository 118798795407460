<template>
  <div id="circle">
    <div id="container">
      <div class="header">
        <h1>サークル</h1>
        <div>Circle</div>
      </div>
      <nav class="main" v-show="Boolean(loginFlg)">
        <ul>
          <li><a href="" @click.prevent="setDivisionId(1)" :class="[ {current : divisionId == all } ]">
              <div>すべて</div></a></li>
          <li><a href="" @click.prevent="setDivisionId(2)" :class="[ {current : divisionId == privateCircle } ]">
              <div>非公開サークル</div></a></li>
          <li v-show="Boolean(loginFlg)"><a href="" @click.prevent="setDivisionId(3)" :class="[ {current : divisionId == manage } ]">
              <div>あなたが管理人</div></a></li>
        </ul>
      </nav>
      <div class="group">
        <div class="item create" v-show="account != null">
          <div>
            <div class="button">
              <router-link :to= "{ name:'circle/edit', params:{circleId: 0} }"><img src="/assets/icon-add.svg" alt="サークルを作成"></router-link>
            </div>
            <div>サークルを作成</div>
          </div>
        </div>


        <div class="item" v-for="(circle, index) in circles" :key="index" style="position:relative">
          <router-link :to= "{ name:'circle/edit', params:{circleId: circle.id} }" v-if="isEdittable(circle)" class="edit">
            <font-awesome-icon icon="edit" v-tooltip="'編集する'" />
          </router-link>
          <div class="cover"><c-circle-image :has-img="circle.has_circle_img" :circle-id="circle.id" :circle-name="circle.circle_name" /></div>
          <div class="title">
            <h3>{{circle.circle_name}}</h3>
            <p>{{circle.activities}}</p>
          </div>

          <c-circle-members :circle-id="circle.id" />

          <div class="button">
            <router-link :to= "{ name:'circle/time-line', params:{circleMode:1, circleId: circle.id} }">見に行く</router-link>
          </div>
          <!-- <div class="button button-edit" v-if="isEdittable(circle)">
            <router-link :to= "{ name:'circle/edit', params:{circleId: circle.id} }">編集する</router-link>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 広場の公式な話題、自由な話題、最近の話題コンポーネント
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import CircleMembers from '../circle-members'

export default {
  data () {
      return {
        divisionId:1,
        circles:[]
      }
  },
  created : function() {
    if (this.$route.params.circlesDivisionId) {
      this.divisionId = this.$route.params.circlesDivisionId
    }
    
  },
  computed : {
    // storeのstateをthis.squareで指定できるよう設定
    ...mapState([
      'loginFlg',
      'account',
      'hashtagDivisionId'
    ]),
    all : function () {
      return this.Global.circles_division_id.all
    },
    privateCircle : function () {
      return this.Global.circles_division_id.private_circle
    },
    manage : function () {
      return this.Global.circles_division_id.manage
    },
  },
  watch : {
    divisionId:function () {
      this.circles = null
      this.getCircles()
    }
  },
  mounted: function() {
    this.getCircles()
  },
  methods : {
    ...mapMutations([
      'setClientErrors'
    ]),
    setDivisionId : function (divisionId) {
      this.divisionId = divisionId
    },
    getUrl : function () {
      if (this.divisionId == this.Global.circles_division_id.all)            return this.Global.url.api_all_circles
      if (this.divisionId == this.Global.circles_division_id.private_circle) return this.Global.url.api_private_circles
      if (this.divisionId == this.Global.circles_division_id.manage)         return this.Global.url.api_manage_circles
    },
    getCircles : function () {
      let url = this.getUrl()
      let params = {
        account_id : this.account && this.account.id
      }
      this.axios.post( url, params).then(res=>{
        if (res.data.status == this.Global.api_status.success) {
          this.circles = res.data.circles
          return
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/top/index.vue::getCircles", params: params, errorMessage:error})
      })
    },
    isEdittable : function (circle) {
      if (!this.loginFlg) return false
      if (!this.account) return false
      if (this.divisionId == this.Global.circles_division_id.manage) return true
      return _.filter(circle.members, (row)=>{  return this.account.id == row.id && row.admin_flg }).length > 0
    }
  },
  components : {
    'c-circle-members':CircleMembers
  }
}
</script>