<template>
  <nav class="sub">
    <ul>
      <div class="add-topic" v-show="hashtagDivisionId == free">
        <c-new-hashtag v-show="loginFlg" />
      </div>
      <li v-for="hashtag in hashtags" :key="hashtag.mst_topic_id" v-bind:class="[ {current : hashtag.id == hashtagId } ]">
        <router-link :to = "{ name:'square/hashtag', params:{ hashtagId: hashtag.id} }"><div>{{hashtag.name}}</div></router-link>
      </li>          
    </ul>
  </nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import NewHashtag from '../common/new-hashtag.vue'

export default {
  data () {
    return {
      hashtags : [],
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'hashtagDivisionId',
      'hashtagId',
      'sectionHashtagsReloadFlg'
    ]),
    free : function() {
      return this.Global.hashtag_division_id.free
    }
  },
  created () {
    this.getHashtags()
  },
  watch : {
    hashtagDivisionId () {
      this.hashtags=[]
      this.getHashtags()
    },
    sectionHashtagsReloadFlg (newVal) {
      if (newVal==false) return
      this.hashtags=[]
      this.getHashtags()
    },
  },
  mounted () {
  },
  methods : {
    ...mapMutations([
      'setHashtagId',
      'setHashtagIds',
      'setSectionHashtagsReloadFlg',
      'setClientErrors'
      ]),
    // _setHashtagId : function (hashtagId) {
    //   this.setHashtagId(hashtagId)
    // },
    getHashtags : function () {
      let params = { division_id : this.$store.state.hashtagDivisionId, }
      this.axios.post(this.Global.url.api_topics_search_by_division, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.hashtags = res.data.hashtags
          if (this.$store.state.hashtagDivisionId == this.Global.hashtag_division_id.active) {
            let hashtagIds = _.map(res.data.hashtags, 'id')
            this.setHashtagIds(hashtagIds)
          }
          return
        }
        this.$toasted.error("データ取得に失敗しました")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"square/section-hashtag.vue::getHashtags", params: params, errorMessage:error})
      })
    },
  },
  components : {
    'c-new-hashtag' : NewHashtag
  }
}
</script>