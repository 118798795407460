import Global from '../constant'
import axios from 'axios'
var entries = {

  feed: async function() {
    alert(Global.url.app_entries_feed)
  },

  feedEntries: async function() {
    alert(Global.url.api_feed_entries)
    alert(axios)
  },

  search: async function() {
    alert(Global.url.api_entries_search)
  },

  replies: async function() {
    alert(Global.url.api_entries_replies)
  },

  tweet: async function(formData) {
    return await axios.post(Global.url.api_entries_tweet, formData)
  },

  itsNew: async function(accountId) {
    let params = {account_id : accountId}
    return await axios.post(Global.url.api_entries_its_new, params)
  },

  notifications: async function(accountId, limit, offset) {
    let params = {
      account_id : accountId,
    }
    if(limit != null) params['limit'] = limit
    if(offset != null) params['offset'] = offset
    return await axios.post(Global.url.api_entries_notifications, params)
  },

  email: async function(entryId) {
    // alert(Global.url.api_entries_email)
    let params = { entry_id: entryId }
    return await axios.post(Global.url.api_entries_email, params)
  },

  replyEmail: async function() {
    alert(Global.url.api_entries_reply_email)
  },

  delete: async function() {
    alert(Global.url.api_entries_delete)
  },

  sideMenu: async function(account) {
    let cond = {}
    if (account != null) cond['account_id'] = account.id
    return await axios.post(Global.url.api_entries_side_menu, cond)
  },

  keywordSearch: async function() {
    alert(Global.url.api_keyword_search)
  },
}

export default entries