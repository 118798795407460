<template>
  <div class="comment-link">
    <router-link :to = "{ name:'entry', params:{ entryId: entryId} }" v-show="count > 0">
      コメント{{count}}件
    </router-link>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    entryId: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: false
    }
  },
  computed : {
    ...mapState([
      'account',
    ]),
  },
  // watch : {
  //   account(val) {
  //     if (!val) return
  //     if (val.id === this.authorAccountId) {
  //       this.isEditable = true
  //     }
  //   }
  // },
  // mounted : function () {
  //   if (this.account.id === this.authorAccountId) {
  //     this.isEditable = true
  //   }
  // },
  methods : {
    ...mapMutations([
      'setEntryReloadFlg',
      'setClientErrors'
    ]),
  },
}
</script>