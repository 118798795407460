<template>
  <div id="register">
    <div id="container">
      <div class="dialog">
        <section>
          <h2>パスワードを忘れた方</h2>
          <p>本名とメールアドレスを入力すると、<br>新しいパスワードをメールでお知らせします。</p>
          <c-validation-errors :errors-hash="errors" />
          <div class="group">
            <div class="item">
              <input type="text" placeholder="姓" v-model="lastName"><span class="focusborder"></span>
            </div>
            <div class="item">
              <input type="text" placeholder="名" v-model="firstName"><span class="focusborder"></span>
            </div>
          </div>
          <div class="item">
            <input type="text" placeholder="メールアドレス" v-model="mail"><span class="focusborder"></span>
          </div>
          <div class="item">
            <input class="button-blue" type="submit" value="パスワードをリセット" @click="reissuePassword()">
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      errors : {},
      lastName : null,
      firstName : null,
      mail : null,
    }
  },
  methods : {
    ...mapMutations([
      'setLoginFlg',
      'setAutoId',
      'setAccount',
      'setClientErrors',
    ]),
    ...mapActions([
      'save',
    ]),
    reissuePassword : function () {
      let params = {
        last_name : this.lastName,
        first_name : this.firstName,
        mail : this.mail,
      }
      this.axios.post(this.Global.url.api_accounts_reissue_password, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.setLoginFlg(false)
          this.setAutoId(null)
          this.setAccount(res.data.account)
          this.save()

          this.$router.push( { path : '/reissue-password-done/'+this.mail }, () => {} )
          return
        }

        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        } 
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"password/reissue-password.vue::reissuePassword", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"password/reissue-password.vue::reissuePassword", params: params, errorMessage:error})
      })
    },
  },
  components : {}
}  
</script>