<template>
  <div class="setting-waiting">
    <h4>承認待ちの人</h4>
    <div class="item">
      <div v-for="(requester, index) in filteredRequesters" :key="index">
        <p>{{requester.account_last_name+requester.account_first_name}}</p>
        <button v-show="requester.approval_status == pending" @click="onApprove(requester)">承認する</button>
        <button v-show="requester.approval_status == pending" @click="onDisapprove(requester)" class="dont">承認しない</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  props:{
    circleId : {
      type: Number,
      required:true
    },
    saveFlg : {
      type: Boolean,
      required:true
    }
  },
  data : function(){
    return {
      requesters : null
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
      'circleEditCircleMembers',
    ]),
    filteredRequesters : function () {
      return _.filter(this.requesters, (row)=>{ return row.approval_status == this.Global.circle_request_approval_status.pending })
    },
    pending : function () {
      return this.Global.circle_request_approval_status.pending
    },
    approve : function () {
      return this.Global.circle_request_approval_status.approve
    },
    disapprove : function () {
      return this.Global.circle_request_approval_status.disapprove
    }
  },
  watch : {
    saveFlg : function () {
      if (!this.saveFlg) return
      for (let i in this.requesters) {
        if (this.requesters[i].approval_status == this.Global.circle_request_approval_status.pending) continue
        this.circleRequestUpdate(this.requesters[i])
      }
      this.$emit('toggle-save-flg')
    }
  },
  mounted : function () {
    if (this.circleId) this.get()
  },
  methods:{
    ...mapMutations([
      'setClientErrors',
      'concatCircleEditCircleMembers',
      'deleteCircleEditCircleMembersByRequestId'
    ]),
    get : function () {
      let params = { circle_id : this.circleId }
      this.axios.post(this.Global.url.api_circle_requests_search_by_pending, params).then(res=>{
        if (res.data.status == this.Global.api_status.success) {
          this.requesters = res.data.circle_requests
          return
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/request-members.vue::get", params: params, errorMessage:error})
      })
    },
    onApprove : function (requester) {
      this.requesters = _.forEach(this.requesters, (row) => {
        if (row.id == requester.id) row.approval_status = this.Global.circle_request_approval_status.approve
      })
      this.createNewCircleEditCircleMembers(requester)
    },
    onDisapprove : function (requester) {
      this.requesters = _.forEach(this.requesters, (row) => {
        if (row.id == requester.id) row.approval_status = this.Global.circle_request_approval_status.disapprove
      })
      this.deleteCircleEditCircleMembersByRequestId(requester.id)
    },
    circleRequestUpdate : function (requester) {
      let params = {
        id              : requester.id,
        approval_status : requester.approval_status,
        version_no      : requester.version_no
      }
      this.axios.post(this.Global.url.api_circle_requests_update, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          // this.$toasted.success('circle_request更新に成功')  
          return
        }
        // console.log("------ circleRequestUpdate")
        // console.dir(res.data.errors)
        // this.$toasted.error('circle_request更新に失敗')
        this.setClientErrors({func:"circle/edit/request-members.vue::circleRequestUpdate", params: params, errorMessage:'circle_request更新に失敗 : ' + JSON.stringify(res.data.errors)})
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"circle/edit/request-members.vue::circleRequestUpdate", params: params, errorMessage:error})
      })
    },
    createNewCircleEditCircleMembers : function (requester) {
      let member = {
        id                 : null,
        circle_request_id  : requester.id,
        circle_id          : this.circleId,
        account_id         : requester.account_id,
        admin_flg          : false,
        account_last_name  : requester.account_last_name,
        account_first_name : requester.account_first_name,
        account_nickname   : requester.account_nickname,
        newMemberFlg       : true,
        _index             : this.circleEditCircleMembers.length,
      }
      this.concatCircleEditCircleMembers([member])
    }
  }
}
</script>