var emojis = [
   { pattern:'(´∀｀*)1', src : '/assets/emoji/smile_1.png'},
   { pattern:'(´∀｀*)2', src : '/assets/emoji/smile_2.png'},
   { pattern:'(´∀｀*)3', src : '/assets/emoji/smile_3.png'},
   { pattern:'(´∀｀*)4', src : '/assets/emoji/smile_4.png'},
   { pattern:'(´∀｀*)5', src : '/assets/emoji/smile_5.png'},
   { pattern:'(´∀｀*);1', src : '/assets/emoji/sweat_1.png'},
   { pattern:'(；-；*)ﾌﾌ♪1', src : '/assets/emoji/cry_smile_1.png'},
   { pattern:'(；-；*)ﾌﾌ♪2', src : '/assets/emoji/cry_smile_2.png'},
   { pattern:'(´∀｀*)6', src : '/assets/emoji/smile_6.png'},
   { pattern:'(^ -)1', src : '/assets/emoji/smile_wink_1.png'},
   { pattern:'(´∀｀*)7', src : '/assets/emoji/smile_7.png'},
   { pattern:'(´∀｀*)8', src : '/assets/emoji/smile_8.png'},
   { pattern:'(´∀｀*)9', src : '/assets/emoji/smile_9.png'},
   { pattern:'(´∀｀*)10', src : '/assets/emoji/smile_10.png'},   
   { pattern:'(^∪^)1', src : '/assets/emoji/smile_tongue_1.png'},
   { pattern:'(-o-)1', src : '/assets/emoji/relief_1.png'},
   { pattern:'(♡♡)1', src : '/assets/emoji/smile_heart_1.png'},
   { pattern:'(♡♡)2', src : '/assets/emoji/smile_heart_2.png'},
   { pattern:'￣３￣　チュ♪1', src : '/assets/emoji/kiss_heart_1.png'},
   { pattern:'￣３￣　チュ♪2', src : '/assets/emoji/people_kiss_1.png'},
   { pattern:'(・∪・)1', src : '/assets/emoji/people_tongue_1.png'},
   { pattern:'(・∪・)2', src : '/assets/emoji/people_tongue_2.png'},
   { pattern:'(・∪・)3',src  : '/assets/emoji/people_tongue_3.png'},
   { pattern:'(・∪・)4',src  : '/assets/emoji/people_tongue_4.png'},
   { pattern:'(・∪・)5',src  : '/assets/emoji/people_tongue_5.png'},
   { pattern:'(▼ ▼)1', src : '/assets/emoji/people_sunglass_1.png'},
   { pattern:'(▽ ▽)1', src : '/assets/emoji/people_glasses_1.png'},
   { pattern:'(▽ ▽)2', src : '/assets/emoji/people_glasses_2.png'},
   { pattern:'(・∪・)6', src : '/assets/emoji/people_hat_1.png'},
   { pattern:'(･ω･)ノparty1', src : '/assets/emoji/people_party_1.png'},
   { pattern:'(･ω･)hug1', src  : '/assets/emoji/people_hug_1.png'},
   { pattern:'(･ω･)clown1', src  : '/assets/emoji/clown_1.png'},
   { pattern:'(´∀｀*)11', src : '/assets/emoji/smile_11.png'},
   { pattern:'(･ω･)1', src  : '/assets/emoji/people_1.png'},
   { pattern:'(･ω･)2', src  : '/assets/emoji/people_2.png'},
   { pattern:'(･ω･)3', src  : '/assets/emoji/people_3.png'},
   { pattern:'(･ω･)4', src  : '/assets/emoji/people_4.png'},
   { pattern:'(･ω･)5', src  : '/assets/emoji/people_5.png'},
   { pattern:'(･ω･)6', src  : '/assets/emoji/people_6.png'},
   { pattern:'(･ω･)7', src  : '/assets/emoji/people_7.png'},
   { pattern:'(･ω･)8', src  : '/assets/emoji/people_8.png'},
   { pattern:'(･ω･)9', src  : '/assets/emoji/people_9.png'},
   { pattern:'(･ω･)10', src  : '/assets/emoji/people_10.png'},
   { pattern:'(･ω･)11', src  : '/assets/emoji/people_11.png'},
   { pattern:'(･ω･)12', src  : '/assets/emoji/people_12.png'},
   { pattern:'(･ω･)13', src  : '/assets/emoji/people_13.png'},
   { pattern:'o(｀ω´*)oﾌﾟﾝｽｶﾌﾟﾝｽｶ!!1', src  : '/assets/emoji/people_angry_1.png'},
   { pattern:'o(｀ω´*)oﾌﾟﾝｽｶﾌﾟﾝｽｶ!!2', src  : '/assets/emoji/people_angry_2.png'},
   { pattern:'o(｀ω´*)oﾌﾟﾝｽｶﾌﾟﾝｽｶ!!3', src  : '/assets/emoji/people_angry_3.png'},
   { pattern:'(-?-)1', src  : '/assets/emoji/people_thinking_1.png'},
   { pattern:'(? ☆)1', src  : '/assets/emoji/people_confuse_1.png'},
   { pattern:'(`b´)1', src  : '/assets/emoji/people_badmood_1.png'},
   { pattern:'(`f´)1', src  : '/assets/emoji/people_frow_1.png'},
   { pattern:'(`f´)2', src  : '/assets/emoji/people_frow_2.png'},
   { pattern:'(･ω･)14', src  : '/assets/emoji/people_14.png'},
   { pattern:'(>m<)patience1', src  : '/assets/emoji/people_patience_1.png'},
   { pattern:'(>〜<)sad1', src  : '/assets/emoji/people_sad_1.png'},
   { pattern:'(+ +;)tired1', src  : '/assets/emoji/people_tired_1.png'},
   { pattern:'(･ω･)fedup1', src  : '/assets/emoji/people_fedup_1.png'},
   { pattern:'(･ω･)win1', src  : '/assets/emoji/people_win_1.png'},
   { pattern:'(･ω･)15', src  : '/assets/emoji/people_15.png'},
   { pattern:'(0o0)1', src  : '/assets/emoji/people_scream_1.png'},   
   { pattern:'(0o0)blue1', src  : '/assets/emoji/people_blue_1.png'},
   { pattern:'(0o0)blue2', src  : '/assets/emoji/people_blue_2.png'},
   { pattern:'(･ω･)16', src  : '/assets/emoji/people_16.png'},
   { pattern:'(;ﾟдﾟ)ｱｯ….1', src  : '/assets/emoji/people_surprise_1.png'},
   { pattern:'(･ω･)distress', src  : '/assets/emoji/people_distress_1.png'},
   { pattern:'(T T)1', src  : '/assets/emoji/people_cry_1.png'},
   { pattern:'(・_・;)worried1', src  : '/assets/emoji/people_worried_1.png'},
   { pattern:'(・o・)yawn1', src  : '/assets/emoji/people_yawn_1.png'},
   { pattern:'(・q・)drool', src  : '/assets/emoji/people_drool_1.png'},
   { pattern:'(・・;)1', src  : '/assets/emoji/people_sweat_1.png'},
   { pattern:'(T T)2', src  : '/assets/emoji/people_cry_2.png'},
   { pattern:'(☆ ☆)1', src  : '/assets/emoji/people_star_1.png'},
   { pattern:'(@ @)dizzy1', src  : '/assets/emoji/people_dizzy_1.png'},
   { pattern:'(・q・)vaguely1', src  : '/assets/emoji/people_vaguely_1.png'},
   { pattern:'(;ﾟдﾟ)ｱｯ….2', src  : '/assets/emoji/people_surprise_2.png'},
   { pattern:'(･ω･)explosion1', src  : '/assets/emoji/people_explosion_1.png'},
   { pattern:'(・x・)1', src  : '/assets/emoji/people_chuck_1.png'},
   { pattern:'⊂□⊃ヽ(･д｀･。) はい、マスク1', src  : '/assets/emoji/people_mask_1.png'},
   { pattern:'☆(#+。+)痛い1', src  : '/assets/emoji/people_injury_1.png'},
   { pattern:'☆(;+_/+)1', src  : '/assets/emoji/people_thermometer_1.png'},
   { pattern:"|電柱|ヽ(　|||)ｵｴｪ...1", src  : '/assets/emoji/people_vomiting_1.png'},
   { pattern:"|電柱|ヽ(　|||)ｵｴｪ...2", src  : '/assets/emoji/people_vomiting_2.png'},
   { pattern:"(￣ii￣)ｽﾞﾙｽﾞﾙ1", src  : '/assets/emoji/people_sneeze_1.png'},
   { pattern:"(≡-_-≡:)1", src  : '/assets/emoji/people_feel_hot_1.png'},
   { pattern:"{{{{(+ω+)}}}}寒ううぅ～1", src  : '/assets/emoji/people_pale_1.png'},
   { pattern:"(★´-ω-).。oOＯ グゥグゥ1", src  : '/assets/emoji/people_sleep_1.png'},
   { pattern:"(★´-ω-).。oOＯ グゥグゥ2", src  : '/assets/emoji/sleep_1.png'},
   { pattern:"←~∋(｡Ψ▼ｰ▼)∈゛゛バｻﾊﾞｻ1", src  : '/assets/emoji/devil_1.png'},
   { pattern:"←~∋(｡Ψ▼ｰ▼)∈゛゛バｻﾊﾞｻ2", src  : '/assets/emoji/devil_2.png'},
   { pattern:"←~∋(｡Ψ▼ｰ▼)∈゛゛バｻﾊﾞｻ2", src  : '/assets/emoji/namahage_1.png'},
   { pattern:"(*・ゝ・)1", src  : '/assets/emoji/tengu_1.png'},
   { pattern:"（ﾉ･∀･）ﾉ = ●ｳﾝｺｰ!!1", src  : '/assets/emoji/shit_1.png'},
   { pattern:"†┏┛墓┗┓†*｀Д´)m ｳﾗﾒｼﾔ~1", src  : '/assets/emoji/haunted_1.png'},
   { pattern:"（●..●）1", src  : '/assets/emoji/skull_1.png'},
   { pattern:"（●..●）2", src  : '/assets/emoji/skull_2.png'},
   { pattern:"(◆.◆)alien1", src  : '/assets/emoji/alien_1.png'},
   { pattern:"|(｡：o)|　ロボット兵1", src  : '/assets/emoji/robot_1.png'},
   { pattern:"（（（◆▼◆）））1", src  : "/assets/emoji/jack-o'-lantern_1.png"},
   { pattern:"艸1", src  : "/assets/emoji/hand_1.png"},
   { pattern:"艸2", src  : "/assets/emoji/hand_2.png"},
   { pattern:"艸3", src  : "/assets/emoji/hand_3.png"},
   { pattern:"艸4", src  : "/assets/emoji/hand_4.png"},
   { pattern:"艸5", src  : "/assets/emoji/hand_5.png"},
   { pattern:"艸6", src  : "/assets/emoji/hand_6.png"},
   { pattern:"艸7", src  : "/assets/emoji/hand_7.png"},
   { pattern:"艸8", src  : "/assets/emoji/hand_8.png"},
   { pattern:"艸9", src  : "/assets/emoji/hand_9.png"},
   { pattern:"艸10", src  : "/assets/emoji/hand_10.png"},
   { pattern:"艸11", src  : "/assets/emoji/hand_11.png"},
   { pattern:"艸12", src  : "/assets/emoji/hand_12.png"},
   { pattern:"艸13", src  : "/assets/emoji/hand_13.png"},
   { pattern:"艸14", src  : "/assets/emoji/hand_14.png"},

]

export default emojis