<template>
  <div id="register">
    <div id="container">
      <div class="dialog">
        <section>
          <h2>パスワードを送信しました</h2>
          <p>{{mail}}へ新しいパスワードを送信しました。<br>メールの内容を確認ください。</p><router-link to="/square">>広場へ戻る</router-link><a href="">メールが届きませんか？</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      mail : null
    }
  },
  mounted : function () {
    this.mail = this.$route.params.mail
  }
}
</script>