<template>
  <div id="openspace">
    <div id="container">
      <div class="wrapper">
        <aside>
          <div class="widget">
            <div class="weather"><img src="/assets/widget-1@2x.png" alt=""></div>
            <div class="weather"><img src="/assets/widget-2@2x.png" alt=""></div>
            <div class="weather"><img src="/assets/widget-3@2x.png" alt=""></div>
          </div>
        </aside>
        <main class="timeline">
          <div class="detail-back"><a href="#" @click.prevent="back">戻る</a></div>
          <c-entry v-for="(entry, index) in entries" 
            :key="index" 
            :entry-division-id="entry.entry_division_id" 
            :entry="entry"
            :mode="entryDisplayMode"
            />
        </main>
        <c-cotolike-widget />
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import entry from '../common/entry'
import CotolikeWidget from '../common/cotolike-widget.vue'
import _ from 'lodash'

export default {
  data () {
    return {
      entryId : null,
      entries : null,
      entryDisplayMode : this.Global.entry_display_mode.detail,
    }
  },

  computed:{
    ...mapState([
      'loginFlg',
      'account'
      ]),
  },
  watch : {
    "$route.params.entryId"(val) {
      this.get(val)
    },
  },  
  created : function () {
    this.entryId = this.$route.params.entryId

    // if (this.account == null) {
    //   this.$toasted.info('ログインしてないので広場へ')
    //   this.$router.push( { path : '/' }, () => {} )
    // }
  },

  mounted : function () {
    if (this.entryId > 0) this.get(this.entryId)
  },

  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    get : async function (entryId) {
      let params = { entry_id : entryId}
      this.axios.post(this.Global.url.api_entries_search, params)
      .then(res => {
        if (res.data.status === this.Global.api_status.success) {
          if (res.data.entries[0].entry_division_id == this.Global.entry_division_id.private_circle) {
            let ret = this.getCircleMembers(res.data.entries[0].circle_id)
            ret
              .then(result => {
                if ( result.data.status == this.Global.api_status.success ) {
                  const circleMembers = result.data.circle_members
                  if (_.filter(circleMembers, row => row.account_id == this.account.id).length > 0) {
                    this.entries = res.data.entries
                    return
                  } else {
                    this.$toasted.error('不正なアクセスなため広場に遷移します')
                  }
                }
                this.$router.push( { path : '/' }, () => {} )
              })
              .catch(error => console.log(error))
          } else if(res.data.entries[0].entry_division_id == this.Global.entry_division_id.myspace && res.data.entries[0].official_range == null) {
            if (this.account == null) {
              this.$toasted.info('ログインしてないので広場へ')
              this.$router.push( { path : '/' }, () => {} )
            }
            let accountId = res.data.entries[0].account_id
            let followerAccountId = this.account.id
            let ret = this.getAccountFollower(accountId, followerAccountId)

            Promise.all([ret])
              .then(result => {
                if (result[0].data.status === this.Global.api_status.success) {
                  let followers = result[0].data.account_follows

                  if(_.filter(followers, row => row.account_id == followerAccountId).length > 0){
                    this.entries = res.data.entries
                    return
                  } else {
                    this.$toasted.error('不正なアクセスなため広場に遷移します')
                  }
                }
                this.$router.push( { path : '/' }, () => {} )
              })
          } else {
            this.entries = res.data.entries
            console.log('詳細でつぶやきの中身確認')
            console.log(res.data.entries)
          }
          return
        }
        this.setClientErrors({func:"detail/main.vue::get", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"detail/main.vue::get", params: params, errorMessage:error})
      })
    },
    back : function () {
      this.$router.back()
    },
    getAccountFollower: async function(accountId) {      
      let params = { account_id : accountId}
      return await this.axios.post(this.Global.url.api_account_followers_search, params)      
    },
    getCircleMembers: async function(circleId) {      
      let params = {circle_id : circleId}
      return await this.axios.post(this.Global.url.api_circle_members_search, params)
    }
  },

  components : {
    'c-entry' : entry,
    'c-cotolike-widget' : CotolikeWidget
  }
}
</script>