<template>
  <div id="openspace">
    <div class="message"><a class="close" href=""><img src="/assets/icon-close-min.svg" alt="閉じる">閉じる</a>
      <div class="container"><img src="/assets/logo.svg" alt="ともつくネット">
        <p>ともつくネットは、さとのば大学に集う方達のための回覧板です。<br>講義についてはもちろん、日々の学びについての質問やオフ会などの話題も大歓迎です。</p><a href="">発言またはサークルに参加するには、メンバー登録</a>
      </div>
    </div>
    <div id="container">
      <div class="header">
        <div class="wrapper">
          <h1>ひろば</h1>
          <div>Open Space</div>
        </div>
      </div>
      <!-- ホーム、公式な話題、自由な話題、最近の話題 -->
      <c-section-hashtag-division />
      <!-- 話題リスト -->
      <c-section-hashtag />
      <div class="wrapper">
        <aside>
          <div class="widget">
            <c-side-menu />
          </div>
        </aside>
        <main class="timeline">
          <!-- つぶやきコンポーネント -->
          <c-tweet :entry-division-id="this.entryDivisionId" />
          <!-- つぶやき一覧 -->
          <c-entry v-for="(entry, index) in entries" :key="index" :entry-division-id="entryDivisionId" :entry="entry" :mode="entry_display_mode"></c-entry>
          <div v-show="entries.length == 0 ">投稿されていません</div>
          <button @click="()=>{ this.getEntries() }">さらに見る</button>
        </main>
        <c-cotolike-widget />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Tweet from '../common/entry/tweet.vue'
import Entry from '../common/entry'
import SectionHashtagDivision from './section-hashtag-division.vue'
import SectionHashtag from './section-hashtag.vue'
import CotolikeWidget from '../common/cotolike-widget.vue'
import SideMenu from '../common/side-menu'

export default {
  data () {
      return {
        entryDivisionId : this.Global.entry_division_id.square,
        entries: [],
        limit: this.Global.entry_limit_per_page,
        offset: 0,
        entry_display_mode: this.Global.entry_display_mode.time_line,
      }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account',
      'hashtagDivisionId',
      'hashtagId',
      'hashtagIds',
      'entryReloadFlg',
    ])
  },
  mounted () {
    if (this.$route.params.hashtagId!=null) {
      this.setHashtagId(this.$route.params.hashtagId)
      return
    }
    this.getEntries()
  },

  watch : {
    loginFlg : function () {
    },    
    // storeのhashtagDivisionIdを監視
    hashtagDivisionId (newValue) {
      if ( newValue != this.Global.hashtag_division_id.active ) {
        this.entries = []
        this.offset = 0
        this.getEntries()
      }
    },
    // storeのhashtagIdを監視
    hashtagId () {
      this.entries = []
      this.offset = 0
      this.getEntries()
    },
    hashtagIds () {
      // alert("watch state!!! hashtagIds")
      this.entries = []
      this.offset = 0
      this.getEntries()
    },
    entryReloadFlg (newValue) {
      // this.$toasted.info("watch state!!! entryReloadFlg")
      if ( !newValue ) return
      this.entries = []
      this.offset = 0
      this.getEntries()
      this.setEntryReloadFlg(false)
    },
    $route() {
      this.setHashtagDivisionId(this.hashtagDivisionId)
      this.setHashtagId(this.$route.params.hashtagId)
    }
  },
  methods : {
    ...mapMutations([
      'setHashtagDivisionId',
      'setHashtagId',
      'setHashtagIds',
      'setEntryReloadFlg',
      'setClientErrors'
    ]),

    getEntries : function() {
      let params = {
        entry_division_id : this.Global.entry_division_id.square,
        hashtag_id : this.hashtagId,
        hashtag_ids : this.hashtagIds,
        limit : this.limit,
        offset : this.offset
      }

      if (this.account) {
        params['account_id'] = this.account.id
      }
      if ( this.hashtagDivisionId == this.Global.hashtag_division_id.official ) {
        params['hashtag_official'] = true
      } else if (this.hashtagDivisionId == this.Global.hashtag_division_id.free) {
        params['hashtag_official'] = false
      }

      this.axios.post(this.Global.url.api_entries_search, params)
      .then(res => {
        if (res.data.status == this.Global.api_status.success) {
          if (this.entries.length > 0 && res.data.entries.length == 0) {
            this.$toasted.info("これ以上取得できません")
            return
          } 

          this.entries = this.entries.concat(res.data.entries)
          this.offset += this.limit
          return
        }
        this.$toasted.error("データ取得に失敗しました")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"square/index.vue::getEntries", params: params, errorMessage:error})
      })
    },
  },
  components : {
    'c-tweet' : Tweet,
    'c-entry' : Entry,
    'c-section-hashtag-division' : SectionHashtagDivision,
    'c-section-hashtag' : SectionHashtag,
    'c-cotolike-widget' : CotolikeWidget,
    'c-side-menu': SideMenu,
  }
}
</script>