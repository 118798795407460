<template>
  <div id="support">
    <div class="header">
      <h1>管理者メール</h1>
    </div>
    <div class="container">
      <ul>
        <li><router-link to="/admin/accounts">アカウント一覧</router-link></li>
        <li><router-link to="/admin/hashtags">ハッシュタグ一覧</router-link></li>
        <li><router-link to="/admin/mail">管理者メール</router-link></li>
      </ul>
      <main>
        <section>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import{mapState} from 'vuex'
export default {
  computed:{
    ...mapState(['loginFlg', 'account'])
  },
  mounted : function(){
    if(!this.loginFlg) {
      this.$toasted.info("no login! please log in.")
      this.$router.push( { path : '/' }, () => {} )
    }
  }
}
</script>


