<template>
  <aside>
    <div class="widget">
      <div class="photo-slider"><img src="/assets/widget-4@2x.png" alt=""></div>
    </div>
    <div class="information"><img src="/assets/widget-photo@2x.png" alt="">
      <h4>ともつくネットについて</h4>
      <p>ともつくネットは、さとのば大学に集う方達のための回覧板です。講義についてはもちろん、日々の学びについての質問やオフ会などの話題も大歓迎です。</p>
    </div>
    <div class="menu">
      <!-- <router-link to = "/help">ヘルプ</router-link> -->
      <router-link to = "/terms-service">利用規約</router-link>
      <router-link to = "/privacy-policy">プライバシーポリシー</router-link><br>
      <!-- <router-link to = "/contact-us">お問い合わせ</router-link> -->
      <p>Except where otherwise noted, content on this site is licensed under a Creative Commons Attribution 4.0 International license.</p>
    </div>
  </aside>
</template>
<script>
export default {
  data () {
    return {

    }
  }
}
</script>