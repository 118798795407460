<template>
  <div id="support">
    <div class="header">
      <h1>ハッシュタグ登録・更新</h1>
    </div>
    <div class="container">
      <main>
        <section>
          <c-validation-errors :errors-hash="errors" />
          <table>
            <tbody>
              <tr>
                <td>ハッシュタグ名</td>
                <td><input type="text" v-model="name" /></td>
              </tr>
              <tr>
                <td>公式・自由</td>
                <td>
                  <!-- <input type="radio" style="-webkit-appearance:auto" v-model="official" value="true" />
                  <input type="radio" style="-webkit-appearance:auto" v-model="official" value="false" /> -->
                  <toggle-button 
                    v-model="official"
                    :sync="true"
                    :labels="{checked: '公式', unchecked: '自由'}"></toggle-button>
                </td>
              </tr>
              <tr>
                <td>ソート</td>
                <td><input type="text" v-model="sort" /></td>
              </tr>
            </tbody>
          </table>
          <button @click="modify">{{id > 0 ? '更新' : '登録'}}</button><button @click="prev">戻る</button>  
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapState, mapMutations} from 'vuex'
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

export default {
  data(){
    return {
      id:null,
      name : null,
      sort : null,
      official : true,
      errors:{},

    }
  },
  computed:{
    ...mapState(['loginFlg']),
  },
  mounted:function(){
    if(!this.loginFlg) {
      this.$toasted.info("no login! please log in.")
      this.$router.push( { path : '/' }, () => {} )
      return
    }
    this.id = Number(this.$route.params.hashtagId)
    if (this.id > 0) {
      this.get()
    }

    // _.forEach(document.getElementsByClassName('public_radio'), (el) => { el.removeAttribute('style')})
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),    
    get : function () {
      this.axios.get(this.Global.url.api_mst_topics_show + this.id).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.name = res.data.hashtag.name
          this.official = res.data.hashtag.official
          this.sort = res.data.hashtag.sort
          return
        }
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"admin/hashtag/edit.vue::get", params: {}, errorMessage:error})
      })
    },
    prev : function (){
      this.$router.push( { path : '/admin/hashtags' }, () => {} )
    },
    modify : function () {
      let params = {
        name:this.name,
        sort:this.sort,
        official:this.official,
      }

      if (this.id > 0) params['id'] = this.id

      let url = this.id > 0 ? this.Global.url.api_mst_topics_update : this.Global.url.api_mst_topics_create

      this.axios.post(url, params).then(res=>{
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.info("success")
          this.$router.push( { path : '/admin/hashtags' }, () => {} )
          return
        }
        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        } 
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"admin/hashtag/edit.vue::modify", params: params, errorMessage:JSON.stringify(res.data)})

      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"admin/hashtag/edit.vue::modify", params: {}, errorMessage:error})
      })
    }
  }
}
</script>